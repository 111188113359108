import States from "../../shared/utils/states.json";
export const CenterCityAddEditForm = [
  {
    name: "city_code",
    label: "City Code",
    placeholder: "City Code",
    type: "Input",
  },
  {
    name: "city_name",
    label: "City Name",
    placeholder: "City Name",
    type: "Input",
  },
  {
    name: "state",
    label: "State",
    placeholder: "State",
    type: "Input",
  },
];

export const CenterAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "city_name",
    schemaKey: "center-city",
    name: "center_city_ref",
    label: "Center City Name",
    placeholder: "Center City Name",
    type: "Select",
    options: [],
  },
  {
    name: "center_code",
    label: "Center Code",
    placeholder: "Center Code",
    type: "Input",
  },
  {
    name: "center_name",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Input",
  },
  {
    name: "center_address",
    label: "Center Address",
    placeholder: "Center Address",
    type: "Input",
  },
  {
    name: "center_person",
    label: "Contact Person",
    placeholder: "Contact Person",
    type: "Input",
  },
  {
    name: "phone",
    label: "Phone Number",
    placeholder: "Phone Number",
    type: "Input",
  },
  {
    name: "mobile",
    label: "Mobile Number",
    placeholder: "Mobile Number",
    type: "Input",
  },
  {
    name: "email",
    label: "Email ID",
    placeholder: "Email ID",
    type: "Input",
  },
  {
    name: "ss_facility",
    label: "Provide SS Facility",
    placeholder: "Provide SS Facility",
    type: "Checkbox",
  },
];
export const CourseAddEditForm = [
  {
    name: "course_code",
    label: "Course Code",
    placeholder: "Course Code",
    type: "Input",
  },
  {
    name: "course_name",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Input",
  },
  {
    name: "course_details",
    label: "Course Details",
    placeholder: "Course Details",
    type: "Input",
  },
];
export const BatchTypeAddEditForm = [
  {
    name: "batch_type_code",
    label: "Batch Code",
    placeholder: "Batch Code",
    type: "Input",
  },
  {
    name: "batch_type_name",
    label: "Batch Name",
    placeholder: "Batch Name",
    type: "Input",
  },
  {
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Input",
  },
];
export const ExamAddEditForm = [
  {
    name: "exam_code",
    label: "Exam Code",
    placeholder: "Exam Code",
    type: "Input",
  },
  {
    name: "exam_name",
    label: "Exam Name",
    placeholder: "Exam Name",
    type: "Input",
  },
  {
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Input",
  },
];
export const StreamAddEditForm = [
  {
    name: "stream_code",
    label: "Stream Code",
    placeholder: "Stream Code",
    type: "Input",
  },
  {
    name: "stream_name",
    label: "Stream Name",
    placeholder: "Stream Name",
    type: "Input",
  },
  {
    name: "description",
    label: "Stream Description",
    placeholder: "Stream Description",
    type: "Input",
  },
];
export const SessionAddEditForm = [
  {
    name: "session",
    label: "Session",
    placeholder: "Session",
    type: "Input",
    colSpan: 8,
  },
  {
    name: "reg_number_prefix",
    label: "Reg Number Prefix",
    placeholder: "Reg Number Prefix",
    type: "Input",
    colSpan: 8,
  },
  {
    name: "reg_number_suffix",
    label: "Reg Number Suffix",
    placeholder: "Reg Number Suffix",
    type: "Input",
    colSpan: 8,
  },
  {
    name: "ss_receipt_p",
    label: "SS Receipt Prefix",
    placeholder: "SS Receipt Prefix",
    type: "Input",
  },
  {
    name: "ss_receipt_s",
    label: "SS Receipt Suffix",
    placeholder: "SS Receipt Suffix",
    type: "Input",
  },
  {
    name: "ss_debit_receipt_p",
    label: "SS Debit Receipt Prefix",
    placeholder: "SS Debit Receipt Prefix",
    type: "Input",
  },
  {
    name: "ss_debit_receipt_s",
    label: "SS Debit Receipt Suffix",
    placeholder: "SS Debit Receipt Suffix",
    type: "Input",
  },

  {
    name: "ss_book_receipt_p",
    label: "SS Book Receipt Prefix",
    placeholder: "SS Book Receipt Prefix",
    type: "Input",
    colSpan: 8,
  },
  {
    name: "ss_book_receipt_s",
    label: "Suffix",
    placeholder: "SS Book Receipt Suffix",
    type: "Input",
    colSpan: 8,
  },
  {
    name: "ss_book_receipt_voucher_s",
    label: "Voucher Suffix",
    placeholder: "SS Book Receipt Voucher Suffix",
    type: "Input",
    colSpan: 8,
  },
  {
    name: "ss_book_debit_receipt_p",
    label: "SS Book Debit Receipt Prefix",
    placeholder: "SS Book Debit Receipt Prefix",
    type: "Input",
    colSpan: 12,
  },
  {
    name: "ss_book_debit_receipt_s",
    label: "Suffix",
    placeholder: "SS Book Debit Receipt Suffix",
    type: "Input",
    colSpan: 6,
  },

  {
    name: "ss_book_debit_receipt_voucher_s",
    label: "Voucher Suffix",
    placeholder: "SS Book Debit Receipt Voucher Suffix",
    type: "Input",
    colSpan: 6,
  },
];
export const BatchCodeAddEditForm = [
  {
    name: "batch_code",
    label: "Batch Code",
    placeholder: "Batch Code",
    type: "Input",
  },
  {
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Input",
  },
];
export const BatchTimingsAddEditForm = [
  {
    name: "batch_time_description",
    label: "Batch Time Description",
    placeholder: "Batch Time Description",
    type: "Input",
  },
  {
    name: "batch_time_from",
    label: "Batch Time From",
    placeholder: "Batch Time From",
    type: "TimePicker",
  },
  {
    name: "is_definite",
    label: "Timings Type",
    type: "Radio",
    options: [
      { name: 'Definite', value: true },
      { name: 'Indefinite', value: false },
    ]
  },
  {
    name: "batch_time_to",
    label: "Batch Time To",
    placeholder: "Batch Time To",
    type: "TimePicker",
  },

];
export const VenueAddEditForm = [
  {
    name: "venue_code",
    label: "Venue Code",
    placeholder: "Venue Code",
    type: "Input",
  },
  {
    name: "venue_name",
    label: "Venue Name",
    placeholder: "Venue Name",
    type: "Input",
  },
  {
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Input",
  },
];

export const CompanyAddEditForm = [
  {
    name: "company_code",
    label: "Company Code",
    placeholder: "Company Code",
    type: "Input",
  },
  {
    name: "company_name",
    label: "Company Name",
    placeholder: "Company Name",
    type: "Input",
  },
  {
    name: "description",
    label: "Description",
    placeholder: "Description",
    type: "Input",
  },
  {
    name: "service_tax_number",
    label: "Service Tax Number",
    placeholder: "Service Tax Number",
    type: "Input",
  },
  {
    name: "gstin",
    label: "GSTIN",
    placeholder: "GSTIN",
    type: "Input",
  },
  {
    name: "state",
    label: "State Name",
    placeholder: "State Name",
    type: "Select",
    options: States.map((val: any) => {
      return { label: val.name, value: val.name }
    }),
  },
  {
    name: "hsn",
    label: "HSN/SAC",
    placeholder: "HSN/SAC",
    type: "Input",
  },
];
export const BankAddEditForm = [
  {
    name: "bank_code",
    label: "Bank Code",
    placeholder: "Bank Code",
    type: "Input",
  },
  {
    name: "bank_name",
    label: "Bank Name",
    placeholder: "Bank Name",
    type: "Input",
  },
];
export const BankBranchAddEditForm = [
  {
    name: "bank_name",
    label: "Bank Name",
    placeholder: "Bank Name",
    type: "Input",
  },
  {
    name: "branch_code",
    label: "Branch Name",
    placeholder: "Branch Name",
    type: "Input",
  },
  {
    name: "branch_name",
    label: "Branch Name",
    placeholder: "Branch Name",
    type: "Input",
  },
  {
    name: "branch_address",
    label: "Branch Address",
    placeholder: "Branch Address",
    type: "Input",
  },
];
export const CourseRelationshipAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center",
    schemaKey: "course-relationship",
    name: "course_relationship_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course",
    schemaKey: "course-relationship",
    name: "course_relationship_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "admission_and_tuition_company",
    schemaKey: "course-relationship",
    name: "course_relationship_ref",
    label: "Admission and Tuition Company Name",
    placeholder: "Admission and Tuition Company Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "book_company",
    schemaKey: "course-relationship",
    name: "course_relationship_ref",
    label: "Book Company Name",
    placeholder: "Book Company Name",
    type: "Select",
    options: [],
  },
  {
    name: "Admission_and_",
    label: "Center Code",
    placeholder: "Center Code",
    type: "Input",
  },
];
export const BatchTypeRelationshipAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "batch-type-relationship",
    name: "batch_type_relationship_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course_name",
    schemaKey: "batch-type-relationship",
    name: "batch_type_relationship_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_type",
    schemaKey: "batch-type-relationship",
    name: "batch_type_relationship_ref",
    label: "Batch Type Name",
    placeholder: "Batch Type Name",
    type: "Select",
    options: [],
  },
];
export const ExamTypeRelationshipAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center",
    schemaKey: "exam-type-relationship",
    name: "exam_type_relationship_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course",
    schemaKey: "exam-type-relationship",
    name: "exam_type_relationship_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_type",
    schemaKey: "exam-type-relationship",
    name: "exam_type_relationship_ref",
    label: "Batch Type Name",
    placeholder: "Batch Type Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "exam",
    schemaKey: "exam-type-relationship",
    name: "exam_type_relationship_ref",
    label: "Exam Name",
    placeholder: "Exam Name",
    type: "Select",
    options: [],
  },
];
export const StreamTypeRelationshipAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center",
    schemaKey: "stream-type-relationship",
    name: "stream_type_relationship_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course",
    schemaKey: "stream-type-relationship",
    name: "stream_type_relationship_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_type",
    schemaKey: "stream-type-relationship",
    name: "stream_type_relationship_ref",
    label: "Batch Type Name",
    placeholder: "Batch Type Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "exam",
    schemaKey: "stream-type-relationship",
    name: "stream_type_relationship_ref",
    label: "Exam Name",
    placeholder: "Exam Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "stream",
    schemaKey: "stream-type-relationship",
    name: "stream_type_relationship_ref",
    label: "Stream Name",
    placeholder: "Stream Name",
    type: "Select",
    options: [],
  },
];
export const VenueTypeRelationshipAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "venue-type-relationship",
    name: "venue_type_relationship_ref",
    label: "Venue Name",
    placeholder: "Venue Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "venue_name",
    schemaKey: "venue-type-relationship",
    name: "venue_type_relationship_ref",
    label: "Venue Name",
    placeholder: "Venue Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_type",
    schemaKey: "stream-type-relationship",
    name: "stream_type_relationship_ref",
    label: "Batch Type Name",
    placeholder: "Batch Type Name",
    type: "Select",
    options: [],
  },
];
export const BatchDetailRelationshipAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course_name",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_type",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Batch Type Name",
    placeholder: "Batch Type Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "exam_name",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Exam Name",
    placeholder: "Exam Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "stream_name",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Stream Name",
    placeholder: "Stream Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "venue",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Venue",
    placeholder: "Venue",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "session",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Session",
    placeholder: "Session",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_code",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Batch Code",
    placeholder: "Batch Code",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_time",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "Batch Time",
    placeholder: "Batch Time",
    type: "Select",
    options: [],
  },
  {
    name: "permission_for_other_file_uploaded",
    label: "Permission For Other File Uploaded",
    placeholder: "Permission For Other File Uploaded",
    type: "CheckBox",
  },
  {
    name: "roll_number_prefix",
    label: "Roll Number Prefix",
    placeholder: "Roll Number Prefix",
    type: "Input",
  },
  {
    name: "roll_number_suffix_online",
    label: "Roll Number Suffix Online",
    placeholder: "Roll Number Suffix Online",
    type: "Input",
  },
  {
    name: "roll_number_suffix_fs",
    label: "Roll Number Suffix FS",
    placeholder: "Roll Number Suffix FS",
    type: "Input",
  },
  {
    name: "roll_number_suffix_ss",
    label: "Roll Number Suffix SS",
    placeholder: "Roll Number Suffix SS",
    type: "Input",
  },
  {
    name: "start_date",
    label: "Start Date",
    placeholder: "Start Date",
    type: "DatePicker",
  },
  {
    name: "end_date",
    label: "End Date",
    placeholder: "End Date",
    type: "DatePicker",
  },
  {
    name: "online_seat",
    label: "Online Seat",
    placeholder: "Online Seat",
    type: "Input",
  },
  {
    name: "offline_seat_fs",
    label: "Offline Seat FS",
    placeholder: "Offline Seat FS",
    type: "Input",
  },
  {
    name: "online_seat_ss",
    label: "Online Seat SS",
    placeholder: "Online Seat SS",
    type: "Input",
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "i_card_template",
    schemaKey: "batch-detail-relationship",
    name: "batch_detail_relationship_ref",
    label: "I Card Template",
    placeholder: "I Card Template",
    type: "Select",
    options: [],
  },
  {
    name: "pay online on/off",
    label: "Pay Online ON/OFF",
    placeholder: "Pay Online ON/OFF",
    type: "CheckBox",
  },
];
export const ReceiptStaticDataAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "session",
    schemaKey: "receipt-static-data",
    name: "receipt_static_data_ref",
    label: "Session Name",
    placeholder: "Session Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "receipt-static-data",
    name: "receipt_static_data_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course_name",
    schemaKey: "receipt-static-data",
    name: "receipt_static_data_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
  },
  {
    name: "service_tax_no",
    label: "Service Tax Number",
    placeholder: "Service Tax Number",
    type: "Input",
  },
  {
    name: "header_company_name",
    label: "Header Comapny Name",
    placeholder: "Header Comapny Name",
    type: "Input",
  },
  {
    name: "free_note",
    label: "Free Note",
    placeholder: "Free Note",
    type: "Input",
  },
  {
    name: "receipt_voucher",
    label: "Receipt Voucher",
    placeholder: "Receipt Voucher",
    type: "Input",
  },
  {
    name: "batch_transfer_note",
    label: "Batch transfer note",
    placeholder: "Batch Transfer Note",
    type: "Input",
  },
  {
    name: "batch_transfer_note_tc",
    label: "Batch transfer note TC",
    placeholder: "Batch Transfer Note TC",
    type: "CheckBox",
  },
  {
    name: "center_transfer_note",
    label: "Center transfer note",
    placeholder: "Batch Transfer Note",
    type: "Input",
  },
  {
    name: "center_transfer_note_tc",
    label: "Center transfer note TC",
    placeholder: "Center Transfer Note TC",
    type: "CheckBox",
  },
  {
    name: "refund_note",
    label: "Refund note",
    placeholder: "Refund Note",
    type: "Input",
  },
  {
    name: "refund_note_tc",
    label: "Refund note TC",
    placeholder: "Refund Note TC",
    type: "Input",
  },
  {
    name: "partial_refund_note",
    label: "Partial Refund note",
    placeholder: "Partial Refund Note",
    type: "Input",
  },
  {
    name: "partial_refund_note_tc",
    label: "Partial Refund note TC",
    placeholder: "Partial Refund Note TC",
    type: "Input",
  },
  {
    name: "replacement_note",
    label: "Partial Refund note",
    placeholder: "Partial Refund Note",
    type: "Input",
  },
  {
    name: "replacement_note_tc",
    label: "Partial Refund note_tc",
    placeholder: "Partial Refund Note_tc",
    type: "Input",
  },
  {
    name: "i_card_note",
    label: "I card note",
    placeholder: "I Card Note",
    type: "Input",
  },
  {
    name: "i_card_note_tc",
    label: "I card note_tc",
    placeholder: "I Card Note TC",
    type: "Input",
  },
  {
    name: "course_transfer_note_tc",
    label: "Course Transfer Note Tc",
    placeholder: "Course Transfer Note TC",
    type: "Input",
  },
  {
    name: "Terms and Condition",
    label: "Terms and Condition",
    placeholder: "Terms and Condition",
    type: "Input",
  },
];
export const BankDepositDataAddEditForm = [
  {
    name: "center_name",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Input",
  },
  {
    name: "comapny_name",
    label: "Company Name",
    placeholder: "Comapny Name",
    type: "Input",
  },
  {
    name: "bank_name",
    label: "Bank Name",
    placeholder: "Bank Name",
    type: "Input",
  },
  {
    name: "account_number",
    label: "Account Number",
    placeholder: "Account Number",
    type: "Input",
  },
  {
    name: "branch_name",
    label: "Branch Name",
    placeholder: "Branch Name",
    type: "Input",
  },
];
export const ICardStaticDataAddEditForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center-name",
    schemaKey: "i_card_static_data",
    name: "i_card_static_data_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "session",
    schemaKey: "i_card_static_data",
    name: "i_card_static_data_ref",
    label: "Session",
    placeholder: "Session",
    type: "Select",
    options: [],
  },
  {
    name: "Terms_and_condition",
    label: "Terms and Condition",
    placeholder: "Terms and Condition",
    type: "Input",
  },
  {
    name: "center_address",
    label: "Center Address",
    placeholder: "Center Address",
    type: "Input",
  },
];
