import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Slider,
  Switch,
  TreeSelect
} from "antd";
import moment from "moment";
import ImageUpload from "./image-upload.component";
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export type GenericFormProps = {
  name: string;
  heading?: string;
  subHeading?: any;
  onFinish: any;
  onFinishFailed: any;
  formElements: any;
  initialValues: any;
  setValues?: any;
  extraButtons?: any;
  submitButtonText?: string;
  submitButtonType?: any;
  loading?: boolean;
  onValuesChange?: any;
  enableEnterSubmit?: boolean;
};

const GenericFormComponent: React.FC<GenericFormProps> = ({
  name,
  heading,
  onFinish,
  onFinishFailed,
  formElements,
  extraButtons,
  submitButtonText,
  initialValues,
  submitButtonType = "primary",
  loading = false,
  subHeading,
  enableEnterSubmit = false,
  onValuesChange = () => {
    return;
  },
  setValues = null,
}: GenericFormProps) => {
  const [form] = Form.useForm();
  form.setFieldsValue(setValues);

  return (
    <>
      {/* {heading && (
        <Row className="bg-secondary">
          <Col>
            <h3 className="form-heading">{heading}</h3>
          </Col>
        </Row>
      )}
      {subHeading && (
        <Row>
          <Col>
            <p className={"sub-title"}>{subHeading}</p>
          </Col>
        </Row>
      )} */}
      <Form
        form={form}
        initialValues={initialValues}
        name={name}
        className="paddingx20 app-form full-width"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        {...(enableEnterSubmit
          ? {
            onKeyPress: (e) => {
              if (e.key === "Enter") {
                form.submit();
              }
            },
          }
          : {})}
      >
        <Row gutter={20} align="bottom" className="cover">
          {formElements.map((ele: any, index: number) => {
            return ele.type === "assetProfileSubSection" ? (
              <>
                <Col span="24">
                  <Row gutter={20} align="top">
                    <Col span={12}>
                      <Row>
                        {SubSectionElements(1, ele.formElements[0])}
                        {SubSectionElements(2, ele.formElements[1])}
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>{SubSectionElements(3, ele.formElements[2])}</Row>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : ele.type === "GridData" ? (
              <>
                <GridData ele={ele} />
              </>
            ) : (
              SubSectionElements(index, ele)
            );
          })}
        </Row>
        {submitButtonText && submitButtonText !== "" && (
          <Row className="button-row">
            <Col span={24}>
              <Form.Item>
                <Button
                  loading={loading}
                  // size="large"
                  className={"mt20"}
                  type={submitButtonType}
                  htmlType="submit"
                  block
                >
                  {submitButtonText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
        {extraButtons && (
          <Row className="button-row" justify="end" gutter={16}>
            {extraButtons.map((btn: any, index: number) => {
              return (
                <Col
                  {...btn?.colSpanResponsive}
                  span={btn.colSpan}
                  key={index}
                  className="gutter-row"
                >
                  <Form.Item>
                    <Button
                      loading={btn.loading}
                      // size="large"
                      {...(btn.onClick ? { onClick: btn.onClick } : {})}
                      {...(btn.type === "clearForm"
                        ? {
                          onClick: () => {
                            form.resetFields();
                            form.submit();
                          },
                        }
                        : {})}
                      className={"mt20"}
                      type={btn.submitButtonType}
                      htmlType={
                        btn.type === "clearForm" ? "button" : btn.htmlType
                      }
                      block
                    >
                      {btn.submitButtonText}
                    </Button>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        )}
      </Form>
    </>
  );
};
export default GenericFormComponent;

export const SubSectionElements = (index: number, ele: any) => {
  return (
    <Col
      key={index}
      {...ele?.colSpanResponsive}
      span={ele?.colSpan ? ele?.colSpan : 24}
    >
      {(ele.visible === undefined || ele.visible === true) && (
        <Form.Item
          name={ele.name}
          key={index}
          label={ele.label}
          rules={ele.rules ? ele.rules : []}
          validateStatus={ele.validateStatus ? ele.validateStatus : undefined}
          help={ele.help ? ele.help : undefined}
          {...(ele.valuePropName ? { valuePropName: ele.valuePropName } : {})}
          {...(ele.type === 'CheckBox' ? { valuePropName: 'checked' } : {})}
          {...(ele.type === 'Checkbox' ? { valuePropName: 'checked' } : {})}
          style={ele.type === "TextLabel" ? { marginBottom: 0 } : {}}
        >
          {ele.type === "TextLabel" ? (
            <div className="ant-form-item-label"><label style={{ display: 'unset' }}>{ele.textlabel}</label></div>
          ) : ele.type === "Switch" ? (
            <Switch size="small" />
          ) : ele.type === "Select" ? (
            ele.mode === "multiple" ? (
              <TreeSelect
                treeCheckable
                showArrow={true}
                maxTagCount={0}
                maxTagPlaceholder={(omittedValues) => {
                  return `${omittedValues.length} Selected`;
                }}
                // size="large"
                treeDefaultExpandAll={true}
                className="capitalize"
                dropdownClassName={
                  ele?.dropdownClassName ? ele.dropdownClassName : null
                }
                disabled={ele.disabled}
                treeData={ele.options}
                onChange={(e) => ele.onChange?.(e)}
                placeholder={ele.placeholder ? ele.placeholder : ""}
              />
            ) : (
              <Select
                // size="large"
                disabled={ele.disabled ? true : false}
                mode={ele.mode ? ele.mode : ""}
                showSearch={ele.showSearch ? ele.showSearch : false}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={
                  ele.onChange
                    ? (e) => ele.onChange(e)
                    : () => {
                      return;
                    }
                }
                placeholder={ele.placeholder ? ele.placeholder : ""}
                allowClear={ele.allowClear ? ele.allowClear : false}
              >
                {ele.options?.map((option: any, seli: number) => {
                  return (
                    <Option
                      key={seli}
                      value={option.value != null && option.value != undefined ? option.value : option.id}
                    // className={"capitalize"}
                    >
                      {option.label || option.name}
                    </Option>
                  );
                })}
              </Select>
            )
          ) : ele.type === "DatePicker" ? (
            <DatePicker
              // size="large"
              style={{ width: "100%" }}
              format="MMM DD, Y"
              {...(ele.placeholder ? { placeholder: ele.placeholder } : {})}
              disabledDate={ele.disabledDate ? ele.disabledDate : (_d) => false}
              placeholder={ele.placeholder}
            />
          ) : ele.type === "TimePicker" ? (
            <DatePicker.TimePicker
              // size="large"
              style={{ width: "100%" }}
              format="hh:mm A"
              {...(ele.placeholder ? { placeholder: ele.placeholder } : {})}
              disabledDate={ele.disabledDate ? ele.disabledDate : (_d) => false}
              placeholder={ele.placeholder}
            />
          ) : ele.type === "DateTimePicker" ? (
            <DatePicker
              // size="large"
              showNow={false}
              showTime={{ format: "hh:mm A" }}
              disabled={ele.disabled}
              format="MMM DD, Y hh:mm A"
              style={{ width: "100%" }}
              {...(ele.placeholder ? { placeholder: ele.placeholder } : {})}
              disabledDate={ele.disabledDate ? ele.disabledDate : (_d) => false}
            />
          ) : ele.type === "RangePicker" ? (
            <RangePicker
              // size="large"
              style={{ width: "100%" }}
              format="MMM DD, Y"
              separator={<span style={{ color: "#999999" }}>to</span>}
              {...(ele.placeholder
                ? {
                  placeholder: [ele.placeholder[0], ele.placeholder[1]],
                }
                : {})}
              disabledDate={
                ele.disabledDate
                  ? ele.disabledDate
                  : (d: any) => {
                    return d > moment();
                  }
              }
            />
          ) : ele.type === "SliderRange" ? (
            <Slider range min={ele.min} max={ele.max} />
          ) : ele.type === "Divider" ? (
            <Divider />
          ) : ele.type === "TextArea" ? (
            <TextArea
              {...(ele.showCount ? { showCount: ele.showCount } : {})}
              {...(ele.maxLength ? { maxLength: ele.maxLength } : {})}
              // size="large"
              placeholder={ele.placeholder}
              rows={ele.rows}
              allowClear
            />
          ) : ele.type === "CheckBox" ? (
            <Checkbox disabled={ele.disabled}> {ele.text}</Checkbox>
          ) : ele.type === "Input" ? (
            <Input
              allowClear
              // size="large"
              type={ele.inputType ? ele.inputType : "text"}
              disabled={ele.disabled ? true : false}
              placeholder={ele.placeholder ? ele.placeholder : ""}
              prefix={ele.prefix ? ele.prefix : null}
            />
          ) : ele.type === "Button" ? (
            <Button
              // size="large"
              onClick={ele.onClick}
            >
              {ele.name}
            </Button>
          ) : ele.type === "InputNumber" ? (
            <InputNumber
              type={"number"}
              // size="large"
              placeholder={ele.placeholder}
              controls={ele.controls}
              style={{ width: "100%" }}
              min={ele.min ? ele.min : null}
              max={ele.max ? ele.max : null}
              disabled={ele.disabled ? true : false}
              keyboard={false}
              stringMode={true}
              {...(ele.onChange ? { onChange: ele.onChange } : {})}
            />
          ) : ele.type === "Cascader" ? (
            <Cascader
              // size="large"
              className={"capitalize"}
              options={ele.data}
              placeholder={"Please Select"}
            />
          ) : ele.type === "Hidden" ? (
            <></>
          ) : ele.type === "Radio" ? (
            <Radio.Group
              // size="large"
              buttonStyle="solid"
              optionType={ele.optionType}
            >
              {ele.options?.map((val: any, i: any) => {
                return (
                  <Radio.Button
                    key={i}
                    className={val.name.toLowerCase()}
                    value={val.value}
                  >
                    {val.name}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          ) : ele.type === "AutoComplete" ? (
            <AutoComplete
              // size="large"
              dataSource={ele.AutoCompleteData}
              onSearch={ele.onSearch}
              onSelect={ele.onSelect}
              placeholder={ele.placeholder}
            />
          ) : ele.type === "Checkbox" ? (
            <Checkbox disabled={ele.disabled}>{ele.inputLabel}</Checkbox>
          ) : ele.type === "Image" ? (
            <ImageUpload name={ele.name} maxSize={ele.maxSize} />
          ) : (
            <></>
          )}
        </Form.Item>
      )}
    </Col>
  );
};

export function GridData({ ele }: any) {
  return <>
    <table style={{ width: '100%' }} className="input-grid-table" >
      <thead className="ant-table-thead">
        <tr>
          {ele.titles.map((val: any) => {
            return (
              <th>{val}</th>
            )
          })}
        </tr>
      </thead>
      <tbody className="ant-table-body">
        {Array(ele.rows).fill(1).map((val: any, index: number) => {
          return <tr>
            <td>Instalment {index + 1}</td>
            <td><Form.Item name={`${ele.elementPrefix}admission_amt_${index}`}
              rules={[
                { required: true, message: "Required" },
                () => ({
                  validator(_: any, value: any) {
                    if (parseInt(value) < 0) {
                      return Promise.reject(new Error('Min 0 Allowed'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            ><InputNumber disabled={ele.rows == index + 1 ? true : false} style={{ width: '100%' }} /></Form.Item></td>
            <td><Form.Item name={`${ele.elementPrefix}tuition_amt_${index}`}
              rules={[
                { required: true, message: "Required" },
                () => ({
                  validator(_: any, value: any) {
                    if (parseInt(value) < 0) {
                      return Promise.reject(new Error('Min 0 Allowed'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}><InputNumber disabled={ele.rows == index + 1 ? true : false} style={{ width: '100%' }} /></Form.Item></td>
            <td><Form.Item name={`${ele.elementPrefix}book_amt_${index}`}
              rules={[
                { required: true, message: "Required" },
                () => ({
                  validator(_: any, value: any) {
                    if (parseInt(value) < 0) {
                      return Promise.reject(new Error('Min 0 Allowed'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}><InputNumber disabled={ele.rows == index + 1 ? true : false} style={{ width: '100%' }} /></Form.Item></td>
            <td><Form.Item name={`${ele.elementPrefix}net_amt_${index}`}
              rules={[
                { required: true, message: "Required" },
                () => ({
                  validator(_: any, value: any) {
                    if (parseInt(value) < 0) {
                      return Promise.reject(new Error('Min 0 Allowed'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}><InputNumber disabled={true} style={{ width: '100%' }} /></Form.Item></td>
            <td><Form.Item name={`${ele.elementPrefix}ins_sub_after_day_${index}`}
              rules={[
                { required: true, message: "Required" },
                () => ({
                  validator(_: any, value: any) {
                    if (parseInt(value) < 0) {
                      return Promise.reject(new Error('Min 0 Allowed'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}><InputNumber style={{ width: '100%' }} /></Form.Item></td>
          </tr>
        })}
      </tbody>
    </table>
  </>
}