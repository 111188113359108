import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    DatePicker,
    Descriptions,
    Form,
    Image,
    Input,
    message,
    notification,
    Radio,
    Row, Space, Table, Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Upload, { RcFile } from "antd/lib/upload";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { completeRefund, initiateRefund, requestPaymentsByEnrol } from "../../../core/store/reducers/admission.slice";
import { RootState } from "../../../shared/types/common";

import { formatDate, getBase64 } from "../../../shared/utils/utils";

export function RefundComponent({ courseDetails, refundData = {}, setRefund, accountsView = false }: any) {
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootState) => state.auth);
    const candidateData = accountsView ? refundData?.candidate_id : courseDetails?.candidate_id;
    const batchDetails = courseDetails?.batch_id;
    const [paymentFor, setPaymentFor] = useState(1);
    const [mode, setMode] = useState("refund");
    const [loadingApplication, setLoadingApplication] = useState(false as any);
    const [loadingCheque, setLoadingCheque] = useState(false);
    const [applicationUrl, setApplicationUrl] = useState("" as any);
    const [chequeUrl, setChequeUrl] = useState("" as any);
    const [paymentMode, setPaymentMode] = useState("" as any);
    const [admin_cheque, setAdminCheque] = useState("" as any);
    const [admin_remarks, setAdminRemarks] = useState("" as any);
    const [account_remarks, setAccountRemarks] = useState("" as any);
    const [allPayments, setAllPayments] = useState([] as any);
    const [paymentEntries, setPaymentEntries] = useState([] as any);
    const [accountFormData, setAccountFormData] = useState([] as any);
    const [amountsData, setAmountsData] = useState({
        admission_fee: 0,
        book_fee: 0,
        tuition_fee: 0,
        ins_charges: 0,
        total_submitted: 0,
        admission_deduction_amount: 0,
        book_deduction_amount: 0,
        tuition_deduction_amount: 0,
        ins_charges_deduction_amount: 0,
        total_deduction: 0,
        admission_refund_amount: 0,
        book_refund_amount: 0,
        tuition_refund_amount: 0,
        ins_charges_refund_amount: 0,
        total_refund: 0,
    } as any);
    useEffect(() => {
        if (refundData?.status) {
            setAmountsData({
                admission_fee: refundData.admission_submitted_amount,
                book_fee: refundData.books_submitted_amount,
                tuition_fee: refundData.tuition_submitted_amount,
                ins_charges: 0,
                total_submitted: refundData.admission_submitted_amount + refundData.books_submitted_amount + refundData.tuition_submitted_amount,
                admission_deduction_amount: refundData.admission_deduction_amount,
                book_deduction_amount: refundData.book_deduction_amount,
                tuition_deduction_amount: refundData.tution_deduction_amount,
                ins_charges_deduction_amount: refundData.ins_charges_deduction_amount,
                total_deduction: refundData.total_deduction_amount,
                admission_refund_amount: refundData.admission_refund_amount,
                book_refund_amount: refundData.book_refund_amount,
                tuition_refund_amount: refundData.tuition_refund_amount,
                ins_charges_refund_amount: refundData.ins_charges_refund_amount,
                total_refund: refundData.total_refund_amount
            })
            setMode(refundData.refund_type)
            setAdminRemarks(refundData.enter_remarks)
            setChequeUrl(refundData.cancelled_cheque_image)
            setApplicationUrl(refundData.application_image)
            setAdminCheque(refundData.cheque_and_dd_in_favor_of)
            setAdminCheque(refundData.cheque_and_dd_in_favor_of)
        }
    }, [refundData])
    const initAmounts = () => {
        setAmountsData({
            admission_fee: 0,
            book_fee: 0,
            tuition_fee: 0,
            ins_charges: 0,
            total_submitted: 0,
            admission_deduction_amount: 0,
            book_deduction_amount: 0,
            tuition_deduction_amount: 0,
            ins_charges_deduction_amount: 0,
            total_deduction: 0,
            admission_refund_amount: 0,
            book_refund_amount: 0,
            tuition_refund_amount: 0,
            ins_charges_refund_amount: 0,
            total_refund: 0,
        });
    };
    useEffect(() => {
        if (courseDetails?._id) {
            dispatch(requestPaymentsByEnrol(courseDetails?._id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setAllPayments(val?.payload?.data?.data?.map((data: any, index: number) => {
                        return {
                            ...data
                        }
                    }))
                }
            })
        }
    }, [courseDetails])
    useEffect(() => {
        if (allPayments?.length > 0 && accountsView == false) {
            console.log(allPayments)
            let admission_fee = 0
            let book_fee = 0
            let tuition_fee = 0
            let ins_charges = 0
            let total_submitted = 0
            allPayments?.forEach((pay: any) => {
                if (pay?.is_book_fee) {
                    book_fee += pay?.instalment_id?.books_amount
                } else {
                    admission_fee += pay?.instalment_id?.admission_amount
                    tuition_fee += pay?.instalment_id?.tuition_amount
                }
                total_submitted += pay?.amount
            })
            processAmt({
                admission_fee: admission_fee,
                book_fee: book_fee,
                tuition_fee: tuition_fee,
                ins_charges: 0,
                total_submitted: total_submitted,
                admission_deduction_amount: 0,
                book_deduction_amount: 0,
                tuition_deduction_amount: 0,
                ins_charges_deduction_amount: 0,
                total_deduction: 0,
                admission_refund_amount: 0,
                book_refund_amount: 0,
                tuition_refund_amount: 0,
                ins_charges_refund_amount: 0,
                total_refund: 0,
            });
        }

    }, [allPayments])
    // useEffect(() => {
    //     // processAmt()
    // }, [amountsData])
    const processAmt = (amountsData: any) => {
        if (mode == "part-refund") {
            setAmountsData({
                ...amountsData,
                admission_deduction_amount:
                    amountsData.admission_fee - amountsData.admission_refund_amount,
                book_deduction_amount:
                    amountsData.book_fee - amountsData.book_refund_amount,
                tuition_deduction_amount:
                    amountsData.tuition_fee - amountsData.tuition_refund_amount,
                ins_charges_deduction_amount:
                    amountsData.ins_charges - amountsData.ins_charges_refund_amount,
                total_deduction: amountsData.total_submitted - amountsData.total_refund,
            });
        }
        if (mode == "refund") {
            setAmountsData({
                ...amountsData,
                admission_refund_amount:
                    amountsData.admission_fee - amountsData.admission_deduction_amount,
                book_refund_amount:
                    amountsData.book_fee - amountsData.book_deduction_amount,
                tuition_refund_amount:
                    amountsData.tuition_fee - amountsData.tuition_deduction_amount,
                ins_charges_refund_amount:
                    amountsData.ins_charges - amountsData.ins_charges_deduction_amount,
                total_refund: amountsData.total_submitted - amountsData.total_deduction,
            });
        }
        if (mode == "cancel") {
            initAmounts();
        }
    };
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            setLoadingApplication(false);
            setLoadingCheque(false);
            return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
            setLoadingApplication(false);
            setLoadingCheque(false);
            return;
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeApplication = (info: any) => {
        if (info.file.status === "uploading") {
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingApplication(false);
                setApplicationUrl(url);
            });
            return;
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingApplication(false);
                setApplicationUrl(url);
            });
            // return;
        }
    };

    const handleChangeCheque = (info: any) => {
        if (info.file.status === "uploading") {
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingCheque(false);
                setChequeUrl(url);
            });
            return;
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingCheque(false);
                setChequeUrl(url);
            });
        }
    };

    const adminSubmit = () => {
        const payload = {
            candidate_id: candidateData?._id,
            course_enrolment_id: courseDetails?._id,
            batch_id: courseDetails.batch_id,
            batch_type_id: courseDetails.batch_type_id,
            center_id: courseDetails.center_id,
            course_id: courseDetails.course_id,
            exam_id: courseDetails.exam_id,
            session_id: courseDetails.session_id,
            stream_id: courseDetails.stream_id,
            refund_type: mode,
            status: 'initiated',
            admission_submitted_amount: amountsData.admission_fee,
            tuition_submitted_amount: amountsData.tuition_fee,
            books_submitted_amount: amountsData.book_fee,
            admission_deduction_amount: amountsData.admission_deduction_amount,
            tution_deduction_amount: amountsData.tuition_deduction_amount,
            book_deduction_amount: amountsData.book_deduction_amount,
            ins_charges_deduction_amount: amountsData.ins_charges_deduction_amount,
            total_deduction_amount: amountsData.total_deduction,
            admission_refund_amount: amountsData.admission_refund_amount,
            tuition_refund_amount: amountsData.tuition_refund_amount,
            book_refund_amount: amountsData.book_refund_amount,
            ins_charges_refund_amount: amountsData.ins_charges_refund_amount,
            total_refund_amount: amountsData.total_refund,

            application_image: applicationUrl,
            cancelled_cheque_image: chequeUrl,
            cheque_and_dd_in_favor_of: admin_cheque,
            enter_remarks: admin_remarks,

            created_by: user?._id,
            modified_by: user?._id
        }
        dispatch(initiateRefund(payload)).then((val: any) => {
            if (val?.payload?.data?.success) {
                notification.success({ message: "Refund/Cancellation initiated successfully" });
                setRefund(false)
            }
        })
    }

    const payColumns = [
        {
            title: "S. No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: "Payment Mode",
            dataIndex: "payment_mode",
            key: "payment_mode",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Cheque/DD/Pay Date",
            dataIndex: "actual_payment_date",
            key: "actual_payment_date",
        },
        {
            title: "In Favor Of",
            dataIndex: "in_favor_of",
            key: "in_favor_of",
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "bank_name",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
            key: "branch_name",
        },
        {
            title: "Trans / DD / Cheque No",
            dataIndex: "ref_no",
            key: "ref_no",
        },
        {
            title: "Is Book Fee",
            dataIndex: "is_book_fee",
            key: "is_book_fee",
            render: (a: any) => {
                return a ? "Yes" : "No";
            },
        },
        {
            title: "Action",
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<DeleteOutlined />}
                                onClick={() => removePaymentEntry(a.sno)}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    function addPayment() {
        let paymentEntries1 = [
            ...paymentEntries,
            {
                candidate_id: candidateData._id,
                course_enrolment_id: courseDetails?._id,
                batch_id: courseDetails.batch_id,
                batch_type_id: courseDetails.batch_type_id,
                center_id: courseDetails.center_id,
                course_id: courseDetails.course_id,
                exam_id: courseDetails.exam_id,
                session_id: courseDetails.session_id,
                stream_id: courseDetails.stream_id,
                sno: paymentEntries.length + 1,
                payment_mode: paymentMode,
                ...accountFormData,
                actual_payment_date: accountFormData.date ? moment(accountFormData.date).format('DD MMM, YY') : moment().format('DD MMM, YY'),
                is_book_fee: paymentFor == 2 ? true : false,
                created_by: user._id,
                modified_by: user._id,
            },
        ];
        paymentEntries1 = paymentEntries1.map((val: any, index: number) => {
            return { ...val, sno: index + 1 };
        });
        setPaymentEntries(paymentEntries1);
    }
    function removePaymentEntry(sno: any) {
        setPaymentEntries(
            paymentEntries
                .filter((val: any) => {
                    return val.sno != sno;
                })
                .map((val: any, index: number) => {
                    return { ...val, sno: index + 1 };
                })
        );
    }
    const accountsSubmit = () => {
        const payload = paymentEntries.map((val: any) => { return { ...val, remarks: account_remarks, actual_payment_date: val.date } });
        const refundId = refundData._id;
        dispatch(completeRefund({ payload: payload, id: refundId })).then((val: any) => {
            if (val?.payload?.data?.success) {
                notification.success({ message: "Refund/Cancellation completed successfully" });
                setRefund(false)
            }
        })
    }
    console.log(courseDetails);
    return (
        <Row>
            <Col span={24}>
                <Descriptions
                    title="Student Registration Details"
                    className="table-description"
                >
                    <Descriptions.Item label="Registration Number">
                        {candidateData?.registration_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="Roll No">xxxxxxxxxx</Descriptions.Item>
                    <Descriptions.Item label="Candidate Name">
                        {candidateData?.candidate_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date of Birth">
                        {candidateData?.dob}
                    </Descriptions.Item>
                    <Descriptions.Item label="Gender" className="capitalize">
                        {candidateData?.gender}
                    </Descriptions.Item>
                    <Descriptions.Item label="Category">
                        {candidateData?.category}
                    </Descriptions.Item>
                    <Descriptions.Item label="Is Handicapped">
                        {candidateData?.handicapped ? "Yes" : "No"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Center">
                        {batchDetails?.center_id?.session}
                    </Descriptions.Item>
                    <Descriptions.Item label="Session">
                        {batchDetails?.session_id?.session}
                    </Descriptions.Item>
                    <Descriptions.Item label="Course">
                        {batchDetails?.session_id?.session}
                    </Descriptions.Item>
                    <Descriptions.Item label="Batch Type">
                        {batchDetails?.session_id?.session}
                    </Descriptions.Item>
                    <Descriptions.Item label="Exam">
                        {batchDetails?.session_id?.session}
                    </Descriptions.Item>
                    <Descriptions.Item label="Stream">
                        {batchDetails?.stream_id?.stream_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Batch Details" span={24}>
                        {batchDetails?.batch_code_id?.batch_code}:{" "}
                        {formatDate(batchDetails?.start_date)} -{" "}
                        {formatDate(batchDetails?.end_date)} (
                        {batchDetails?.batch_time_id?.batch_time_from} -{" "}
                        {batchDetails?.batch_time_id?.batch_time_to})
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            {moment(batchDetails?.start_date).isAfter(moment().add(5, "d")) && (
                <Col span={24} className={"mt-2 marquee"}>
                    <p>Student cross the 5 day limit [Batch Starting]</p>
                </Col>
            )}
            <Col span={24} className="mt-3">
                <Descriptions title="Course Fee Details" className="table-description">
                    <Descriptions.Item label="Payment Criteria">
                        {courseDetails?.n_instalments > 0 ? "Instalments" : "Full Payment."}
                    </Descriptions.Item>
                    <Descriptions.Item label="Submitted Amount">
                        {amountsData?.total_submitted}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Fee">
                        {courseDetails?.admission_fee -
                            courseDetails?.admission_fee_discount +
                            courseDetails?.tution_fee - courseDetails?.tuition_discount +
                            courseDetails?.book_fee - courseDetails?.books_fee_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admission Fee">
                        {courseDetails?.admission_fee -
                            courseDetails?.admission_fee_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tution Fee">
                        {courseDetails?.tution_fee - courseDetails?.tuition_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Book Fee">
                        {courseDetails?.book_fee - courseDetails?.books_fee_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Old Student Discount">
                        {courseDetails?.old_student_discount}
                    </Descriptions.Item>

                    <Descriptions.Item label="Handicapped Discount">
                        {courseDetails?.handicapped_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Special Discount">
                        {courseDetails?.tuition_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Installment Charges">
                        {courseDetails?.instalment_fee - courseDetails?.instalment_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Extra Submission Amount">
                        -
                    </Descriptions.Item>
                    <Descriptions.Item label="" className="capitalize">
                        <Radio.Group
                            value={mode}
                            disabled={accountsView}
                            onChange={(evt: any) => setMode(evt.target.value)}
                        >
                            <Radio value={"part-refund"}>Part-Refund</Radio>
                            <Radio value={"refund"}>Refund</Radio>
                            <Radio value={"cancel"}>Cancellation</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Admission Submitted Amount">
                        {amountsData?.admission_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tuition Submitted Amount">
                        {amountsData?.tuition_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Books Submitted Amount">
                        {amountsData?.book_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admission Deduction Amount">
                        <Input
                            disabled={mode !== "refund" || accountsView}
                            value={amountsData?.admission_deduction_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    admission_deduction_amount: parseFloat(evt.target.value),
                                    total_deduction:
                                        parseFloat(evt.target.value) +
                                        amountsData.tuition_deduction_amount +
                                        amountsData.book_deduction_amount +
                                        amountsData.ins_charges_deduction_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tution Deduction Amount">
                        <Input
                            disabled={mode !== "refund" || accountsView}
                            value={amountsData?.tuition_deduction_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    tuition_deduction_amount: parseFloat(evt.target.value),
                                    total_deduction:
                                        parseFloat(evt.target.value) +
                                        amountsData.admission_deduction_amount +
                                        amountsData.book_deduction_amount +
                                        amountsData.ins_charges_deduction_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Book Deduction Amount">
                        <Input
                            disabled={mode !== "refund" || accountsView}
                            value={amountsData?.book_deduction_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    book_deduction_amount: parseFloat(evt.target.value),
                                    total_deduction:
                                        parseFloat(evt.target.value) +
                                        amountsData.admission_deduction_amount +
                                        amountsData.tuition_deduction_amount +
                                        amountsData.ins_charges_deduction_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Ins. Charges Deduction Amount">
                        <Input
                            disabled={mode !== "refund" || accountsView}
                            value={amountsData?.ins_charges_deduction_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    ins_charges_deduction_amount: parseFloat(evt.target.value),
                                    total_deduction:
                                        parseFloat(evt.target.value) +
                                        amountsData.admission_deduction_amount +
                                        amountsData.tuition_deduction_amount +
                                        amountsData.book_deduction_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Deduction Amount">
                        {amountsData?.total_deduction}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admission Refund Amount">
                        <Input
                            disabled={mode !== "part-refund" || accountsView}
                            value={amountsData?.admission_refund_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    admission_refund_amount: parseFloat(evt.target.value),
                                    total_refund:
                                        parseFloat(evt.target.value) +
                                        amountsData.tuition_refund_amount +
                                        amountsData.book_refund_amount +
                                        amountsData.ins_charges_refund_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tution Refund Amount">
                        <Input
                            disabled={mode !== "part-refund" || accountsView}
                            value={amountsData?.tuition_refund_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    tuition_refund_amount: parseFloat(evt.target.value),
                                    total_refund:
                                        parseFloat(evt.target.value) +
                                        amountsData.admission_refund_amount +
                                        amountsData.book_refund_amount +
                                        amountsData.ins_charges_refund_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Books Refund Amount">
                        <Input
                            disabled={mode !== "part-refund" || accountsView}
                            value={amountsData?.book_refund_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    book_refund_amount: parseFloat(evt.target.value),
                                    total_refund:
                                        parseFloat(evt.target.value) +
                                        amountsData.admission_refund_amount +
                                        amountsData.tuition_refund_amount +
                                        amountsData.ins_charges_refund_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Ins. Charges Refund Amount">
                        <Input
                            disabled={mode !== "part-refund"}
                            value={amountsData?.ins_charges_refund_amount}
                            onChange={(evt: any) => {
                                processAmt({
                                    ...amountsData,
                                    ins_charges_refund_amount: parseFloat(evt.target.value),
                                    total_refund:
                                        parseFloat(evt.target.value) +
                                        amountsData.admission_refund_amount +
                                        amountsData.book_refund_amount +
                                        amountsData.tuition_refund_amount,
                                });
                            }}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Adm./Tuition Refund Amount">
                        {amountsData?.admission_refund_amount + amountsData?.tuition_refund_amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Books Refund Amount">
                        {amountsData?.book_refund_amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Refund Amount">
                        {amountsData?.total_refund}
                    </Descriptions.Item>
                </Descriptions>
            </Col>

            {!accountsView && <Col span={24} className={"mt-5"}><Descriptions
                title="Fee Collection Payment Details"
                className="table-description"
            >
                <Descriptions.Item label="Application Image ">
                    <Upload
                        beforeUpload={beforeUpload}
                        accept={".png,.jpeg,.jpg"}
                        onChange={handleChangeApplication}
                        showUploadList={true}
                        multiple={false}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Descriptions.Item>
                <Descriptions.Item label="Cancelled Cheque Image">
                    <Upload
                        beforeUpload={beforeUpload}
                        accept={".png,.jpeg,.jpg"}
                        onChange={handleChangeCheque}
                        showUploadList={true}
                        multiple={false}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Descriptions.Item>
                <Descriptions.Item label="Cheque/DD (In Favour of) *">
                    <Input value={admin_cheque} onChange={(evt) => setAdminCheque(evt.target.value)}></Input>
                </Descriptions.Item>
                <Descriptions.Item label="Enter Remarks" span={24}>
                    <TextArea value={admin_remarks} onChange={(evt) => setAdminRemarks(evt.target.value)}></TextArea>
                </Descriptions.Item>
            </Descriptions></Col>}
            {accountsView && <Col span={24} className={"mt-5"}><Descriptions
                title="Fee Collection Payment Details"
                className="table-description"
            >
                <Descriptions.Item label="Application Image ">
                    <Image src={applicationUrl} />
                </Descriptions.Item>
                <Descriptions.Item label="Cancelled Cheque Image">
                    <Image src={chequeUrl} />
                </Descriptions.Item>
                <Descriptions.Item label="Cheque/DD (In Favour of) *">
                    <Input value={admin_cheque} disabled></Input>
                </Descriptions.Item>
                <Descriptions.Item label="Enter Remarks" span={24}>
                    <TextArea value={admin_remarks} disabled></TextArea>
                </Descriptions.Item>
            </Descriptions></Col>}
            {/* <Table
                    loading={false}
                    dataSource={[]}
                    columns={payColumns}
                    size="small"
                    pagination={false}
                /> */}

            {
                accountsView && <><Col span={24}>
                    <Descriptions
                        title="Fee Collection Payment Details"
                        style={{ marginTop: "20px" }}
                    >
                        <Descriptions.Item label="Payment Mode">
                            <Radio.Group
                                value={paymentMode}
                                onChange={(evt) => setPaymentMode(evt.target.value)}
                            >
                                <Radio value={"Cash"}>Cash</Radio>
                                <Radio value={"EDC"}>
                                    EDC
                                </Radio>
                                <Radio value={"DD"}>
                                    DD
                                </Radio>
                                <Radio value={"Cheque"}>
                                    Cheque
                                </Radio>
                            </Radio.Group>
                        </Descriptions.Item>
                        <Descriptions.Item label="Fee Submission for">
                            <Radio.Group
                                value={paymentFor}
                                onChange={(evt) => setPaymentFor(evt.target.value)}
                            >
                                <Radio value={1}>
                                    Adm & Tuition
                                </Radio>
                                <Radio value={2}>
                                    Book
                                </Radio>
                            </Radio.Group>
                        </Descriptions.Item>
                    </Descriptions>

                    <Form onFinish={addPayment} onValuesChange={(_: any, allValues: any) => {
                        setAccountFormData(allValues)
                    }}>
                        {
                            paymentMode === "Cash" ? <Descriptions title="Cash" style={{ marginTop: "20px" }}>
                                <Descriptions.Item>
                                    <Form.Item name="amount" label={"Amount"}>
                                        <Input
                                            placeholder={"Enter Amount"}
                                        ></Input>
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item>
                                    {/* <Button onClick={() => addPayment()}>Add Payment</Button> */}
                                    <Button type="primary" htmlType="submit">Add Payment</Button>
                                </Descriptions.Item>
                            </Descriptions>
                                : paymentMode === "DD" ?
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <Form.Item name={"amount"} label={"Amount"}>
                                                <Input></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name={"date"} label={"DD Date"}>
                                                <DatePicker style={{ width: "100%" }}></DatePicker>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name={"in_favor_of"} label={"In Favor of"}>
                                                <Input></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name={"ref_no"} label={"DD No"}>
                                                <Input></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name={"bank_name"} label={"Bank Name"}>
                                                <Input></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name={"branch_name"} label={"Branch"}>
                                                <Input></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} offset={16} className={"text-right mt-5"}>
                                            <Button type="primary" htmlType="submit">Add Payment</Button>
                                        </Col>
                                    </Row>
                                    : paymentMode === "Cheque" ?
                                        <Row gutter={20}>
                                            <Col span={8}>
                                                <Form.Item name={"amount"} label={"Amount"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"date"} label={"Cheque Date "}>
                                                    <DatePicker style={{ width: "100%" }}></DatePicker>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"in_favor_of"} label={"In Favor of"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"ref_no"} label={"Cheque No"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"bank_name"} label={"Bank Name"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"branch_name"} label={"Branch"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8} className={"text-right mt-5"}>
                                                <Button type="primary" htmlType="submit">Add Payment</Button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row gutter={20}>
                                            <Col span={8}>
                                                <Form.Item name={"amount"} label={"Amount"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"date"} label={"Date "}>
                                                    <DatePicker style={{ width: "100%" }}></DatePicker>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"in_favor_of"} label={"In Favor of"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"ref_no"} label={"Transaction Number"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"bank_name"} label={"Bank Name"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"branch_name"} label={"Branch"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8} className={"text-right mt-5"}>
                                                <Button type="primary" htmlType="submit">Add Payment</Button>
                                            </Col>
                                        </Row>
                        }
                    </Form>


                    <Table
                        loading={false}
                        dataSource={paymentEntries}
                        columns={payColumns}
                        size="small"
                        pagination={false}
                    />
                    <p></p>
                    <Descriptions title="Remarks" style={{ marginTop: "20px" }}>
                        <Descriptions.Item>
                            <TextArea
                                value={account_remarks}
                                onChange={(evt: any) => setAccountRemarks(evt.target.value)}
                                placeholder={"Enter Fee Remark"}
                            ></TextArea>
                        </Descriptions.Item>
                    </Descriptions>

                </Col>
                    <Col span={24} className={"text-right mt-10"}>
                        <Space>
                            <Button type="primary" onClick={accountsSubmit}>Refund/Cancellation</Button>
                            <Button type="default" onClick={() => setRefund(false)}>
                                Cancel
                            </Button>
                        </Space>
                    </Col>
                </>}
            {
                !accountsView && <Col span={24} className={"text-right mt-10"}>
                    <Space>
                        <Button type="primary" onClick={adminSubmit}>Refund/Cancellation Initiate</Button>
                        <Button type="default" onClick={() => setRefund(false)}>
                            Cancel
                        </Button>
                    </Space>
                </Col>
            }
        </Row>
    );
}
