import { Col, Descriptions, Image, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/types/common";
var _ = require("lodash")


function CandidateCard({ candidateData, courseData, withPic = false }: any) {
    const { masterData, streams, batch } = useSelector((state: RootState) => state.masters);
    const batchDetails = _.find(batch, _.find(batch, function (n: any) {
        if (n?.batch_code_id?._id === courseData?.batch_id) {
            return n;
        }
    }))
    return <Row gutter={10}>
        <Col span={withPic ? 20 : 24}>
            <Descriptions title="Student Registration Details" className="table-description">
                <Descriptions.Item label="Registration Number">{candidateData?.registration_number ? candidateData?.registration_number : 'xxxxxxxxxx'}</Descriptions.Item>
                <Descriptions.Item label="Roll No">xxxxxxxxxx</Descriptions.Item>
                <Descriptions.Item label="Candidate Name">{candidateData?.candidate_name}</Descriptions.Item>
                <Descriptions.Item label="Date of Birth">{candidateData?.dob}</Descriptions.Item>
                <Descriptions.Item label="Gender" className="capitalize">{candidateData?.gender}</Descriptions.Item>
                <Descriptions.Item label="Category">{candidateData?.category}</Descriptions.Item>
                <Descriptions.Item label="Session">{_.find(masterData, { "_id": courseData?.session_id })?.session}</Descriptions.Item>
                <Descriptions.Item label="Stream">{_.find(streams, function (n: any) {
                    if (n?.stream_id?._id === courseData?.stream_id) {
                        return n;
                    }
                })?.stream_id?.stream_name}</Descriptions.Item>
                <Descriptions.Item label="Is Old Student">{courseData?.is_old_student ? "Yes" : "No"}</Descriptions.Item>
                <Descriptions.Item label="Is Handicapped">{candidateData?.handicapped ? "Yes" : "No"}</Descriptions.Item>
                <Descriptions.Item label="Batch Details" span={24}>
                    {batchDetails?.batch_code_id?.batch_code} (Total : {batchDetails?.total_seat} {" "}
                    Online : {batchDetails?.online_seat} {" "}
                    Online Filled : 0 {" "}
                    Online Remaining : 0 {" "}
                    Offline: {batchDetails?.offline_seat_fs} {" "}
                    Offline Filled : {batchDetails?.offline_seat_ss} {" "}
                    Offline Remaining : 0)
                </Descriptions.Item>
            </Descriptions>
        </Col>
        <Col span={withPic ? 4 : 0} className={"mt-9"}>
            <Image
                width={200}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
        </Col>
    </Row>
}
export default CandidateCard;


