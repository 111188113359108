// App
export const ROUTE_CANDIDATES_LIST = `/candidates/list`;
export const ROUTE_ENROLMENTS_LIST = `/enrolments/list`;
export const ROUTE_INSTALMENTS_LIST = `/instalments/list`;
export const ROUTE_PAYMENTS_LIST = `/payments/list`;
export const ROUTE_REFUNDS_LIST = `/refunds/list`;
export const ROUTE_FEE_CLOSING_APPROVAL = `/fee-closing-approval/list`;
export const ROUTE_PRINT_RECEIPT = (id: any) => `/print-receipt/${id}`;
export const ROUTE_NEW_ADMISSION = "/online-registration";

// API
export const API_CANDIDATE_REGISTRATION =
  "api/admission/candidate-registration";
export const API_CANDIDATE_REGISTRATION_DETAILS = (query: any) =>
  `api/admission/candidate-registration/${query}`;
export const API_CANDIDATE_LIST = "api/admission/candidate-registration/all";

export const API_COURSE_ENROLMENT = "api/admission/course-enrolment";
export const API_COURSE_ENROLMENT_PUBLIC =
  "api/admission/course-enrolment-public";
export const API_COURSE_ENROLMENT_LIST = "api/admission/course-enrolment/all";

export const API_COURSE_INSTALMENT_PAYMENT = "api/admission/course-payment";
export const API_COURSE_PAYMENT_LIST = "api/admission/course-payment/all";
export const API_COURSE_PAYMENT_DETAIL_ID = (id: any) =>
  `api/admission/course-payment/byid/${id}`;

export const API_COURSE_INSTALMENTS_LIST =
  "api/admission/course-instalments/all";
export const API_COURSE_INSTALMENTS_BY_EN_ID = (id: string) =>
  "api/admission/course-instalments-by-enrolment-id/" + id;
export const API_COURSE_PAYMENTS_BY_EN_ID = (id: string) =>
  "api/admission/course-payments-by-enrolment-id/" + id;
export const API_REALIZE_INSTALMENT = (id: string) =>
  "api/admission/realize-instalment/" + id;
export const API_COMPLETE_REFUND = (id: string) =>
  "api/admission/complete-refund/" + id;
export const API_INITIATE_REFUND = "api/admission/initiate-refund";
export const API_REFUND_LIST = "api/admission/refunds";
export const API_FEE_CLOSING_APPROVAL = "api/admission/fee-closing-approval";
export const API_CREATE_PAYMENT_ORDER = "api/admission/create-payment-order";
export const API_CHECK_PAYMENT_STATUS = "api/admission/check-payment-status";
