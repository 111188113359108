import { Button, Col, Drawer, Form, Image, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CKEditor } from 'ckeditor4-react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { genericWebsiteCreate, genericWebsiteUpdate } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

declare var CKFinder: any;
const Options = Select;
const CreateEditProductsComponent = ({ onClose, visible, onSubmit, selectedEntity }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [bannerUrl, setBannerUrl] = useState(selectedEntity ? selectedEntity?.banner_url : '' as string)
    const [featuredCardImage, setFeaturedCardImage] = useState(selectedEntity ? selectedEntity?.featured_card_image : '' as string)
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);
    const onFinish = (values: any) => {
        if (selectedEntity) {
            const payload = {
                ...values,
                _id: selectedEntity._id,
                updated_by: user._id,
            };
            dispatch(genericWebsiteUpdate({ payload: payload, module: 'products' })).then((val: any) => {
                if (val?.payload?.data) {
                    showNotification("Products Updated Successfully", "success");
                    onSubmit();
                }
            });
        } else {
            const payload = {
                ...values,
                created_by: user._id,
                updated_by: user._id,
            }
            dispatch(genericWebsiteCreate({ payload: payload, module: 'products' })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification("Products Saved Successfully", "success");
                    onSubmit();
                }
            })
        }
    }
    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    const openPopup = (ref: string, setData: any) => {
        CKFinder.popup({
            chooseFiles: true,
            onInit: (finder: any) => {
                finder.on('files:choose', function (evt: any) {
                    var file = evt.data.files.first();
                    form.setFieldValue(ref, file.getUrl())
                    setData(file.getUrl())
                });
                finder.on('file:choose:resizedImage', function (evt: any) {
                    form.setFieldValue(ref, evt.data.resizedUrl())
                    setData(evt.data.resizedUrl())
                });
            }
        });
    }
    return <>
        <Drawer
            maskClosable={false}
            title="Manage Products"
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : '80%'}
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={{ ...selectedEntity }}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
            >
                <Row >
                    <Col span={12} className="gutter-row pr-2">
                        <Form.Item name="seo_url" label="SEO URL">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row pl-2">
                        <Form.Item name="product_name" label="Product Name">
                            <Input onChange={(e) => {
                                let data = e.target.value;
                                data = data.replace(/[^a-zA-Z0-9 -]/g, '').split("  ").join(" ").split(" ").join("-").toLowerCase()
                                form.setFieldValue("seo_url", data);
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item name="product_sub_title" label="Product Sub Title">
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        {bannerUrl != '' && <Image src={bannerUrl} />}
                    </Col>
                    <Col span={12} className="gutter-row pl-4">
                        <Form.Item name="banner_url" label="Banner">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('banner_url', setBannerUrl)}>
                            Select/Upload Image
                        </Button>
                    </Col>
                    <Col span={12} className="gutter-row">
                        {featuredCardImage != '' && <Image src={featuredCardImage} />}
                    </Col>
                    <Col span={12} className="gutter-row pl-4">
                        <Form.Item name="featured_card_image" label="Featured Card Image">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('featured_card_image', setFeaturedCardImage)}>
                            Select/Upload Image
                        </Button>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item name="content">
                            <Input disabled hidden />
                        </Form.Item>
                        <CKEditor
                            name={'content'}
                            config={{
                                // extraPlugins: 'ckfinder',
                                filebrowserBrowseUrl: 'http://panel.vanikonline.in/ckfinder/ckfinder.html',
                                filebrowserUploadUrl: 'http://panel.vanikonline.in/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files'
                            }}
                            initData={selectedEntity ? selectedEntity?.content : <p>Add content</p>}
                            onChange={(event) => {
                                console.log(event.editor.getData());
                                form.setFieldValue('content', event.editor.getData())
                            }}
                        />
                    </Col>
                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer>
    </>
}

export default CreateEditProductsComponent;