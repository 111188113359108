import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Col, Pagination, Row, Table } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TABLE_ROWS_COUNT } from "../../shared/constants";

type Props = {
  loading: boolean;
  dataSource: any[];
  columns: any[];
  maxWidth?: string;
  title: string;
  actions?: Array<{ icon: JSX.Element; action: () => void; tooltip: string }>;
  counts?: Array<{ title: string; count: JSX.Element }>;
  defaultPage: number;
  currentPage: number;
  totalRows: number;
  rowSelection?: any;
  onChange?: any;
  onReorder?: any;
  onPageChange: (e: any) => void;
};
const MastersDataTableSortable = ({
  loading,
  dataSource,
  columns,
  title,
  maxWidth = "1000px",
  counts,
  actions,
  defaultPage,
  totalRows,
  onPageChange,
  currentPage,
  rowSelection,
  onChange,
  onReorder,
}: Props) => {
  const { app } = useParams();
  const dispatch = useDispatch();

  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
  }
  const TableRow = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      width: "100%",
      ...(isDragging
        ? {
          position: "relative",
          //   zIndex: 9999
        }
        : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      onReorder(active, over);
      // send event back
      // setDataSource((previous: any) => {
      //     const activeIndex = previous.findIndex((i: any) => i.key === active.id);
      //     const overIndex = previous.findIndex((i: any) => i.key === over?.id);
      //     return arrayMove(previous, activeIndex, overIndex);
      // });
    }
  };
  return (
    <>
      <div className="mx-6 bg-primary-white-blueish border-primary-gray-02">
        <Row>
          <Col span={24}>
            <DndContext onDragEnd={onDragEnd}>
              <SortableContext
                items={dataSource.map((i: any) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  className="app-table"
                  tableLayout="auto"
                  pagination={false}
                  loading={loading}
                  scroll={{ x: maxWidth }}
                  dataSource={dataSource}
                  columns={columns}
                  rowSelection={rowSelection}
                  onChange={onChange}
                  components={{
                    body: {
                      row: TableRow,
                    },
                  }}
                  rowKey="key"
                ></Table>
              </SortableContext>
            </DndContext>
          </Col>
        </Row>
        <Row justify="end">
          <Col className="bg-primary-white-bluish pt-3 pb-3 pr-6">
            <Pagination
              showTotal={(total, range) => {
                return total > 0
                  ? `${range[0]}-${range[1]} of ${total} Total`
                  : "";
              }}
              responsive={true}
              showSizeChanger={false}
              defaultCurrent={defaultPage ? defaultPage : currentPage}
              current={currentPage}
              total={totalRows}
              onChange={onPageChange}
              defaultPageSize={TABLE_ROWS_COUNT}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MastersDataTableSortable;
