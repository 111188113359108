import { Col, Row } from "antd";
import { Header } from "antd/lib/layout/layout";
import Logo from "../../../assets/graphics/images/white-logo.png";
import "../../../assets/styles/header.less";
import MenuComponent from "./menu.component";
import ProfileMenuComponent from "./profile-menu.component";
type Props = {};
const HeaderComponent: React.FC<Props> = () => {
  return (
    <>
      <Header className="panel-header">
        <Row gutter={16} align="middle" className="header-row">
          <Col xs={16} sm={16} md={3}>
            <div className="sidebar-logo-wrapper">
              <img alt="logo" src={Logo} />
            </div>
          </Col>
          <Col xs={4} sm={4} md={19}>
            <MenuComponent />
          </Col>
          <Col xs={4} sm={4} md={2} className="text-right">
            <ProfileMenuComponent />
          </Col>
          {/* <Col flex="auto">
            <BreadcrumbsComponent />
          </Col>
          <Col flex="none">
            <NotificationMenuComponent />
          </Col> */}
        </Row>
      </Header>
    </>
  );
};
export default HeaderComponent;
