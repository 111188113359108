import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import HeaderComponent from "../components/header/header.component";

const MainLayout: React.FC<any> = ({ children }: any) => {
  return (
    <>
      <Layout hasSider>
        {/* <SidebarComponent /> */}
        <Layout className="site-layout">
          <HeaderComponent />
          <Content className="main-content-area">{children}</Content>
        </Layout>
      </Layout>
    </>
  );
};
export default MainLayout;
