import { Button, Card, Col, Form, Image, Input, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HideIcon } from "../../assets/graphics/icons/other/eye-off-icon.svg";
import { ReactComponent as ShowIcon } from "../../assets/graphics/icons/other/eye-on-icon.svg";
import Back from "../../assets/graphics/images/white-logo.png";
import "../../assets/styles/login.less";
import { requestLogin } from "../../core/store/reducers/auth.slice";
import LoginLayout from "../../shared/layouts/login.layout";
import { ROUTE_FORGET_PASSWORD } from "../../shared/routes/auth.routes";

const LoginPage: React.FC = () => {
  return <LoginLayout loginForm={<LoginForm />}></LoginLayout>;
};
export default LoginPage;

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onValuesChange = (e: any) => {};
  const onFinish = async (values: any) => {
    setLoading(true);
    const data = {
      user_name: values?.username,
      password: values?.password,
    };
    await dispatch(requestLogin(data));
    // await dispatch(mockLogin());
    setLoading(false);
  };
  return (
    <>
      <Row align="middle" className="h-100 login-bg">
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 11, offset: 1 }}
          lg={{ span: 10, offset: 1 }}
          xl={{ span: 7, offset: 3 }}
        >
          <Card className="login-card">
            <div className="text-center">
              <Image src={Back} preview={false} className="logo" />
            </div>
            <Form
              name="normal_login"
              className="login-form"
              layout="vertical"
              onFinish={onFinish}
              initialValues={{}}
              onValuesChange={onValuesChange}
            >
              <Form.Item
                name="username"
                label={"Email ID"}
                rules={[
                  {
                    required: true,
                    message: "Email ID is required!",
                  },
                  {
                    type: "email",
                    message: "Email ID is invalid!",
                  },
                ]}
              >
                <Input size="large" placeholder={"Email ID"} />
              </Form.Item>
              <Form.Item
                name="password"
                label={"PASSWORD"}
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder={"Password"}
                  iconRender={(visible) =>
                    visible ? <ShowIcon /> : <HideIcon />
                  }
                />
              </Form.Item>
              <div className="text-right">
                <Button
                  type="link"
                  onClick={() => navigate(ROUTE_FORGET_PASSWORD)}
                >
                  FORGOT PASSWORD
                </Button>
              </div>
              <br />
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  block
                  htmlType="submit"
                  size="large"
                >
                  SIGN IN
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type={"link"} block size="large">
                  REGISTER
                </Button>
              </Form.Item>
            </Form>
            <div className="text-center">
              &copy; {moment().format("YYYY")} Vanik Education Pvt. Ltd.
            </div>
          </Card>
        </Col>
        {/* <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 11, offset: 1 }}
          lg={{ span: 10, offset: 1 }}
          xl={{ span: 8, offset: 1 }}
        ></Col> */}
      </Row>
    </>
  );
};
