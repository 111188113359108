import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import ContentHeaderComponent from "../../shared/components/content-header/content-header.component";
import { masterModules } from "./master.utils";
type TabPosition = 'left' | 'right' | 'top' | 'bottom';

const MastersForm: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Enquiries"
                        subtitle=""

                    />
                </Col>
            </Row>
            <Row align="middle" className={"padding20"}>
                {masterModules?.length > 0 && masterModules.map((module: any) => {
                    return (
                        <Col span={6}>
                            <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2 mb-2 pointer" onClick={() => navigate('/master/' + module.key)}>
                                <Row align="middle">
                                    <Col span={6}>
                                        <img
                                            src={require('../../assets/graphics/icons/masters/' + module.key + '.png')}
                                            className="img-fluid p-2"
                                        />
                                    </Col>
                                    <Col span={18} className="pl-3">
                                        <h3 className="m-0">{module.label}</h3>
                                        {/* <p className="mb-0 weight-700">LEADS</p> */}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                })
                }
            </Row>
        </>
    );
};

export default MastersForm;