import { UserOutlined } from "@ant-design/icons";
import { ManageUsersComponent } from "../../modules";
import CandidatesList from "../../modules/admission/candidates";
import ForgotPasswordPage from "../../modules/auth/forgot-password.page";
import LoginPage from "../../modules/auth/login.page";
import ResetPasswordPage from "../../modules/auth/reset-password.page";
import CounsellorDashboardPage from "../../modules/lms/enquiry/counselor-dashboard.page";
import EnquiryDetailsPage from "../../modules/lms/enquiry/enquiry-details.page";
import ManageEnquiriesPage from "../../modules/lms/enquiry/manage-enquiries.page";
import MastersForm from "../../modules/master";
import ManageMasterModulePage from "../../modules/master/manage-master-module.page";
import {
  ROUTE_BANK_DEPOSIT,
  ROUTE_DAILY_EXPENSES,
  ROUTE_EDUCATION_DEBIT_TALLY,
  ROUTE_EDUCATION_INCOME_TALLY,
  ROUTE_FEE_CLEARANCE,
  ROUTE_PUBLICATION_DEBIT_TALLY,
  ROUTE_PUBLICATION_INCOME_TALLY
} from "./accounts.routes";
import {
  ROUTE_CANDIDATES_LIST, ROUTE_ENROLMENTS_LIST,
  ROUTE_FEE_CLOSING_APPROVAL,
  ROUTE_INSTALMENTS_LIST, ROUTE_PAYMENTS_LIST,
  ROUTE_PRINT_RECEIPT, ROUTE_REFUNDS_LIST
} from "./admission.routes";


import EnrolmentsList from "../../modules/admission/enrolments";
import FeeClosingApprovalList from "../../modules/admission/fee-closing-approval";
import InstalmentList from "../../modules/admission/instalments";
import PaymentList from "../../modules/admission/payments";
import PrintReceipt from "../../modules/admission/payments/print-reciept.component";
import RefundList from "../../modules/admission/refund";
import ManageBannersPage from "../../modules/website/banners/manage-banners.page";
import ManageCourseExamsPage from "../../modules/website/course-and-exams-and-content/manage-course-exams.page";
import FileManagerPage from "../../modules/website/file-manager/file-manager.page";
import ManageTagsPage from "../../modules/website/others/manage-tags.page";
import ManageProductsPage from "../../modules/website/products/manage-products.page";
import ManageTestimonialsPage from "../../modules/website/testimonials/manage-testimonials.page";
import {
  ROUTE_FORGET_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD
} from "./auth.routes";
import { ROUTE_DASHBOARD } from "./dashboard.routes";
import {
  ROUTE_ENQUIRY_DASHBOARD,
  ROUTE_ENQUIRY_DETAILS,
  ROUTE_ENQUIRY_LIST
} from "./lms.routes";
import { ROUTE_MASTER_LIST, ROUTE_MASTER_LIST_APP } from "./masters.routes";
import { ROUTE_MANAGE_USERS } from "./user-management.routes";
import {
  ROUTE_MANAGE_COURSE_EXAMS, ROUTE_MANAGE_FILES,
  ROUTE_MANAGE_HOME_BANNERS, ROUTE_MANAGE_PRODUCTS,
  ROUTE_MANAGE_TAGS,
  ROUTE_MANAGE_TESTIMONIALS
} from "./website.routes";

export const ROUTES_OPEN: any[] = [
  {
    name: "Login",
    route: ROUTE_LOGIN,
    component: <LoginPage />,
  },
  {
    name: "Reset Pasword",
    route: ROUTE_RESET_PASSWORD,
    component: <ResetPasswordPage />,
  },
  {
    name: "Forget Password",
    route: ROUTE_FORGET_PASSWORD,
    component: <ForgotPasswordPage />,
  },
  {
    name: "Print Page",
    pagename: "Print Page",
    module: "Print Page",
    route: ROUTE_PRINT_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintReceipt />,
  },
];

export const ROUTES_PROTECTED: any[] = [
  {
    name: "Dashboard",
    // pagename: "DASHBOARD",
    module: "DASHBOARD",
    route: ROUTE_DASHBOARD,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: (
      <>
        Dashboard
        <UserOutlined />
      </>
    ),
  },
  {
    name: "Manage Users",
    pagename: "USER MANAGEMENT",
    module: "MANAGE USERS",
    route: ROUTE_MANAGE_USERS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageUsersComponent />,
  },
  {
    name: "enquiry-dashboard",
    pagename: "enquiry-dashboard",
    module: "ENQUIRY",
    route: ROUTE_ENQUIRY_DASHBOARD,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <CounsellorDashboardPage />,
  },
  {
    name: "All Enquiries",
    pagename: "ALL ENQUIRIES",
    module: "ENQUIRY",
    route: ROUTE_ENQUIRY_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageEnquiriesPage />,
  },
  {
    name: "Enquiry Details",
    pagename: "Enquiry Details",
    module: "Enquiry Details",
    route: ROUTE_ENQUIRY_DETAILS(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <EnquiryDetailsPage />,
  },
  {
    name: "Master List",
    pagename: "Master List",
    module: "Master",
    route: ROUTE_MASTER_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <MastersForm />,
  },

  {
    name: "Master Details",
    pagename: "Master Details",
    module: "Master",
    route: ROUTE_MASTER_LIST_APP(":app"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageMasterModulePage />,
  },

  {
    name: "All Candidates List",
    pagename: "ALL CANDIDATES",
    module: "ADMISSION",
    route: ROUTE_CANDIDATES_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <CandidatesList />,
  },
  {
    name: "All Enrolments List",
    pagename: "ALL ENROLMENTS",
    module: "ADMISSION",
    route: ROUTE_ENROLMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <EnrolmentsList />,
  },
  {
    name: "All Instalment List",
    pagename: "ALL INSTALMENTS",
    module: "ADMISSION",
    route: ROUTE_INSTALMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <InstalmentList />,
  },
  {
    name: "All Payments List",
    pagename: "ALL PAYMENTS",
    module: "ADMISSION",
    route: ROUTE_PAYMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PaymentList />,
  },
  {
    name: "Print Page",
    pagename: "Print Page",
    module: "Print Page",
    route: ROUTE_PRINT_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintReceipt />,
  },
  {
    name: "Fee Closing Approval",
    pagename: "Fee Closing Approval",
    module: "Admission",
    route: ROUTE_FEE_CLOSING_APPROVAL,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FeeClosingApprovalList />,
  },
  {
    name: "Fee Clearance",
    pagename: "Fee Clearance",
    module: "Accounts",
    route: ROUTE_FEE_CLEARANCE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentFeeClearance />,
  },
  {
    name: "Refunds",
    pagename: "Refunds",
    module: "Refunds",
    route: ROUTE_REFUNDS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <RefundList />,
  },
  {
    name: "Bank Deposit",
    pagename: "Bank Deposit",
    module: "Accounts",
    route: ROUTE_BANK_DEPOSIT,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentBankDeposit />,
  },
  {
    name: "Daily Expenses",
    pagename: "Daily Expenses",
    module: "Accounts",
    route: ROUTE_DAILY_EXPENSES,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentDailyExpenses />,
  },
  {
    name: "Education Income Report for Tally",
    pagename: "Education Income Report for Tally",
    module: "Accounts",
    route: ROUTE_EDUCATION_INCOME_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentEducationIncomeTally />,
  },
  {
    name: "Education Debit Report for Tally",
    pagename: "Education Debit Report for Tally",
    module: "Accounts",
    route: ROUTE_EDUCATION_DEBIT_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentEducationDebitTally />,
  },
  {
    name: "Publication Income Report for Tally",
    pagename: "Publication Income Report for Tally",
    module: "Accounts",
    route: ROUTE_PUBLICATION_INCOME_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentPublicationIncomeTally />,
  },
  {
    name: "Publication Debiut Report for Tally",
    pagename: "Publication Debit Report for Tally",
    module: "Accounts",
    route: ROUTE_PUBLICATION_DEBIT_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentPublicationDebitTally />,
  },
  {
    name: "File Manager",
    pagename: "File Manager",
    module: "Website",
    route: ROUTE_MANAGE_FILES,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FileManagerPage />,
  },
  {
    name: "ROUTE_MANAGE_HOME_BANNERS",
    pagename: "ROUTE_MANAGE_HOME_BANNERS",
    module: "Website",
    route: ROUTE_MANAGE_HOME_BANNERS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageBannersPage />,
  },
  {
    name: "ROUTE_MANAGE_PRODUCTS",
    pagename: "ROUTE_MANAGE_PRODUCTS",
    module: "Website",
    route: ROUTE_MANAGE_PRODUCTS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageProductsPage />,
  },
  {
    name: "ROUTE_MANAGE_TAGS",
    pagename: "ROUTE_MANAGE_TAGS",
    module: "Website",
    route: ROUTE_MANAGE_TAGS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageTagsPage />,
  },
  {
    name: "ROUTE_MANAGE_TESTIMONIALS",
    pagename: "ROUTE_MANAGE_TESTIMONIALS",
    module: "Website",
    route: ROUTE_MANAGE_TESTIMONIALS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageTestimonialsPage />,
  },
  {
    name: "ROUTE_MANAGE_COURSE_EXAMS",
    pagename: "ROUTE_MANAGE_COURSE_EXAMS",
    module: "Website",
    route: ROUTE_MANAGE_COURSE_EXAMS(':app'),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageCourseExamsPage />,
  },

];
