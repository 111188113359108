import { Button, Col, Drawer, Form, Image, Input, Row, Select } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { genericWebsiteCreate } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

const Options = Select;
declare var CKFinder: any;
const CreateEditBannerComponent = ({ onClose, visible, onSubmit }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [bannerUrl, setBannerUrl] = useState('' as string)
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);
    const onFinish = (values: any) => {
        const payload = {
            ...values,
            created_by: user._id,
            updated_by: user._id,
        }
        dispatch(genericWebsiteCreate({ payload: payload, module: 'home-banners' })).then((val: any) => {
            if (val.payload?.data?.success) {
                showNotification("Banner Saved Successfully", "success");
                onSubmit();
            }
        })
    }
    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    const openPopup = (ref: string) => {
        CKFinder.popup({
            chooseFiles: true,
            onInit: (finder: any) => {
                finder.on('files:choose', function (evt: any) {
                    var file = evt.data.files.first();
                    form.setFieldValue(ref, file.getUrl())
                    setBannerUrl(file.getUrl())
                });
                finder.on('file:choose:resizedImage', function (evt: any) {
                    form.setFieldValue(ref, evt.data.resizedUrl())
                    setBannerUrl(evt.data.resizedUrl())
                });
            }
        });
    }
    return <>
        <Drawer
            maskClosable={false}
            title="Manage Banner"
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : (screenType.isLg || screenType.isXl ? 720 : 500)}
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={{}}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
            >
                <Row >
                    <Col span={24} className="gutter-row">
                        <Form.Item name="banner_title" label="Banner Title">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item name="banner_position" label="Banner Position">
                            <Select>
                                <Options value="main-slider">Main Slider (1:2.7)</Options>
                                <Options value="featured-square-banners">Featured Square Banners (1:1)</Options>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item name="banner_url">
                            <Input disabled hidden />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        {bannerUrl != '' && <Image src={bannerUrl} />}
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Button type="primary" onClick={() => openPopup('banner_url')}>
                            Select/Upload Image
                        </Button>
                    </Col>
                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer>
    </>
}

export default CreateEditBannerComponent;