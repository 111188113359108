import { DeleteOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    DatePicker,
    Descriptions,
    Form,
    Input,
    Modal, notification, Radio, Row, Select, Table,
    Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkPaymentOrderStatus, initiatePaymentOrder, requestCourseEnrolment, requestCourseEnrolmentPublic } from "../../../core/store/reducers/admission.slice";
import { ROUTE_PRINT_RECEIPT } from "../../../shared/routes/admission.routes";
import { RootState } from "../../../shared/types/common";
import { formatDate, showNotification } from "../../../shared/utils/utils";
import CandidateRelaxationCard from "./special-relaxations";
const { Option } = Select

const domWindow = window as any;
export const installmentColumns = [
    {
        title: "Installment No.",
        dataIndex: "installment_no",
        key: "installment_no",
    },
    {
        title: "Ins. Admission Amount",
        dataIndex: "admission_amt",
        key: "admission_amt",
    },
    {
        title: "Ins. Tuition Amount",
        dataIndex: "tuition_amt",
        key: "tuition_amt",
    },
    {
        title: "Ins. Book Amount",
        dataIndex: "book_amt",
        key: "book_amt",
        // className: "capitalize",
    },
    {
        title: "Ins. Amount",
        dataIndex: "net_amt",
        key: "net_amt",
        // className: "capitalize",
    },
    {
        title: "Installment Date",
        dataIndex: "ins_sub_after_day",
        key: "ins_sub_after_day",
        // render: (key: any) => moment().add(key, "day").format("Do-MMM-YYYY"),
    },
];
export const installmentColumnsWithDate = [
    {
        title: "Installment No.",
        dataIndex: "sno",
        key: "sno",
    },
    {
        title: "Ins. Admission Amount",
        dataIndex: "admission_amt",
        key: "admission_amt",
    },
    {
        title: "Ins. Tuition Amount",
        dataIndex: "tuition_amt",
        key: "tuition_amt",
    },
    {
        title: "Ins. Book Amount",
        dataIndex: "book_amt",
        key: "book_amt",
        // className: "capitalize",
    },
    {
        title: "Ins. Amount",
        dataIndex: "net_amt",
        key: "net_amt",
        // className: "capitalize",
    },
    {
        title: "Installment Date",
        dataIndex: "prefered_instalment_date",
        key: "prefered_instalment_date",
        render: (key: any) => moment(key).format("Do-MMM-YYYY"),
    },
];

function CourseCard({
    feeData,
    setPaymentModal,
    candidateData,
    courseData,
    publicPage = false
}: any) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);
    const [paymentType, setPaymentType] = useState("full");
    const [paymentMode, setPaymentMode] = useState("Cash");
    const [paymentFor, setPaymentFor] = useState(1);
    const [amount, setAmount] = useState("");
    const [remark, setRemark] = useState("");
    const [feeDetails, setFeeDetails] = useState({} as any);
    const [addedBookFee, setAddedBookFee] = useState(0 as number);
    const [addedAdmFee, setAddedAdmFee] = useState(0 as number);
    const [courseEnrolmentData, setCourseEnrolmentData] = useState([] as any);
    const [instalmentEntries, setInstalmentEntries] = useState([] as any);
    const [paymentEntries, setPaymentEntries] = useState([] as any);
    const [specialRelations, setSpecialRelations] = useState(false as any);
    useEffect(() => {
        if (publicPage === true) {
            setPaymentMode('Online')
        }
    }, [publicPage])
    const setPaymentDetails = () => {
        const payload = {
            courseEnrolment: { ...courseEnrolmentData, remarks: remark },
            instalments: instalmentEntries,
            payments: paymentEntries?.map((val: any) => {
                return { ...val, remarks: remark }
            }),
        };
        console.log(payload);
        dispatch(requestCourseEnrolment(payload)).then((val: any) => {
            if (val?.payload?.data?.success) {
                window.open(
                    ROUTE_PRINT_RECEIPT(val?.payload?.data?.data?.payments[0]._id), "_blank");

                notification.success({ message: "Course added for candidate" });
                setPaymentModal(false);
            }
        });
    };

    useEffect(() => {
        let feeDet;
        if (feeData?.length > 0 && candidateData) {
            feeDet = JSON.parse(JSON.stringify(feeData[0]));
            if (candidateData.handicapped) {
                feeDet.general_admission_fee = feeDet.ph_admission_fee;
                feeDet.general_tuition_fee = feeDet.ph_tuition_fee;
                feeDet.general_books_fee = feeDet.ph_books_fee;
                feeDet.general_total_fee = feeDet.ph_total_fee;
                feeDet.total_discount = feeDet.ph_total_discount;
            } else {
                feeDet.ph_total_discount = 0;
            }
            for (let k = 0; k < feeDet.general_instalment_structure?.length; ++k) {

                feeDet.general_instalment_structure[k].sno = k + 1
                feeDet.general_instalment_structure[k].prefered_instalment_date = formatDate(moment().add(feeDet.general_instalment_structure[k].ins_sub_after_day, 'days'))
            }
            setFeeDetails(feeDet);
        }
    }, [feeData, candidateData]);
    const setFeeDataWithSR = (paymentStructureSR: any, paymentTypeSR: any) => {
        setPaymentType(paymentTypeSR);
        setFeeDetails({ ...paymentStructureSR, special_relaxation_data: courseEnrolmentData });
        setSpecialRelations(false)
    }
    useEffect(() => {
        if (paymentType == "full") {
            setInstalmentEntries([
                {
                    candidate_id: candidateData._id,
                    instalment_no: 1,
                    batch_id: courseData.batch_id,
                    batch_type_id: courseData.batch_type_id,
                    center_id: courseData.center_id,
                    course_id: courseData.course_id,
                    exam_id: courseData.exam_id,
                    session_id: courseData.session_id,
                    stream_id: courseData.stream_id,
                    admission_amount: feeDetails.general_admission_fee,
                    admission_discount: feeDetails.admission_fee_discount || 0,
                    tuition_amount: feeDetails.general_tuition_fee,
                    tuition_discount: feeDetails.tuition_discount || 0,
                    books_amount: feeDetails.general_books_fee,
                    books_discount: feeDetails.books_fee_discount || 0,
                    gross_instalment_amount: feeDetails.general_total_fee,
                    instalment_discount: feeDetails.instalment_discount || 0,
                    net_instalment_amount: feeDetails.general_total_fee,
                    prefered_instalment_date: formatDate(moment()),
                    realized: true,
                    actual_payment_date: formatDate(moment()),
                    created_by: user._id,
                    modified_by: user._id,
                },
            ]);
        }
        else {
            setInstalmentEntries(feeDetails?.general_instalment_structure.map((item: any, key: any) => {
                return {
                    candidate_id: candidateData._id,
                    instalment_no: key + 1,
                    batch_id: courseData.batch_id,
                    batch_type_id: courseData.batch_type_id,
                    center_id: courseData.center_id,
                    course_id: courseData.course_id,
                    exam_id: courseData.exam_id,
                    session_id: courseData.session_id,
                    stream_id: courseData.stream_id,
                    admission_amount: item.admission_amt,
                    admission_discount: item.admission_fee_discount || 0,
                    tuition_amount: item.tuition_amt,
                    tuition_discount: item.tuition_discount || 0,
                    books_amount: item.book_amt,
                    books_discount: item.books_fee_discount || 0,
                    gross_instalment_amount: item.net_amt,
                    instalment_discount: item.instalment_discount || 0,
                    net_instalment_amount: item.net_amt - (item.instalment_discount || 0),
                    prefered_instalment_date: formatDate(moment().add(item.ins_sub_after_day, 'days')),
                    realized: key === 0 ? true : false,
                    actual_payment_date: key === 0 ? formatDate(moment()) : null,
                    created_by: user._id,
                    modified_by: user._id,
                }
            })
            );
        }
    }, [paymentType, feeDetails, paymentType]);
    useEffect(() => {
        if (courseData?.batch_id) {
            setCourseEnrolmentData({
                candidate_id: candidateData._id,
                batch_id: courseData.batch_id,
                batch_type_id: courseData.batch_type_id,
                center_id: courseData.center_id,
                course_id: courseData.course_id,
                exam_id: courseData.exam_id,
                session_id: courseData.session_id,
                stream_id: courseData.stream_id,
                payment_type: paymentType,
                old_student_discount: 0,
                handicapped_discount: feeDetails.ph_total_discount,
                tuition_discount: feeDetails.tuition_discount || 0,
                admission_fee_discount: feeDetails.admission_fee_discount || 0,
                books_fee_discount: feeDetails.books_fee_discount || 0,
                instalment_fee: feeDetails.installment_charges,
                instalment_discount: feeDetails.instalment_discount || 0,
                n_instalments: paymentType == "full" ? 1 : feeDetails?.general_instalment_structure?.length,
                admission_fee: feeDetails.general_admission_fee,
                tution_fee: feeDetails.general_tuition_fee,
                book_fee: feeDetails.general_books_fee,
                total_fee: feeDetails.general_total_fee + (paymentType == 'installment' ? (parseFloat(feeDetails.installment_charges) || 0) : 0),
                created_by: user._id,
                modified_by: user._id,
                special_relaxation_data: feeDetails?.special_relaxation_data ? feeDetails?.special_relaxation_data : null
            });
        }
    }, [courseData, feeDetails, paymentType]);

    const payColumns = [
        {
            title: "S. No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: "Payment Mode",
            dataIndex: "payment_mode",
            key: "payment_mode",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Payment Type",
            dataIndex: "payment_type",
            key: "payment_type",
            className: "capitalize",
        },
        {
            title: "Cheque/DD/Pay Date",
            dataIndex: "actual_payment_date",
            key: "actual_payment_date",
        },
        {
            title: "EDC Charges",
            dataIndex: "edc_charges",
            key: "edc_charges",
        },
        {
            title: "QR Charges",
            dataIndex: "qr_charges",
            key: "qr_charges",
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
        },
        {
            title: "In Favor Of",
            dataIndex: "in_favor_of",
            key: "in_favor_of",
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "bank_name",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
            key: "branch_name",
        },
        {
            title: "Trans / DD / Cheque No",
            dataIndex: "ref_no",
            key: "ref_no",
        },
        {
            title: "Is Book Fee",
            dataIndex: "is_book_fee",
            key: "is_book_fee",
            render: (a: any) => {
                return a ? "Yes" : "No";
            },
        },
        {
            title: "Action",
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<DeleteOutlined />}
                                onClick={() => removePaymentEntry(a.sno)}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    function removePaymentEntry(sno: any) {
        setPaymentEntries(
            paymentEntries
                .filter((val: any) => {
                    return val.sno != sno;
                })
                .map((val: any, index: number) => {
                    return { ...val, sno: index + 1 };
                })
        );
    }
    function addPayment() {
        let paymentEntries1 = [
            ...paymentEntries,
            {
                candidate_id: candidateData._id,
                batch_id: courseData.batch_id,
                batch_type_id: courseData.batch_type_id,
                center_id: courseData.center_id,
                course_id: courseData.course_id,
                exam_id: courseData.exam_id,
                session_id: courseData.session_id,
                stream_id: courseData.stream_id,
                sno: paymentEntries.length + 1,
                payment_mode: paymentMode,
                amount: amount,
                payment_type: paymentType,
                actual_payment_date: formatDate(moment()),
                edc_charges: 0,
                qr_charges: 0,
                total_amount: amount,
                in_favor_of: "",
                bank_name: "",
                branch_name: "",
                ref_no: "",
                is_book_fee: paymentFor == 2 ? true : false,
                created_by: user._id,
                modified_by: user._id,
            },
        ];
        paymentEntries1 = paymentEntries1.map((val: any, index: number) => {
            return { ...val, sno: index + 1 };
        });
        setPaymentEntries(paymentEntries1);
        setAmount("")
    }
    function addOnlinePayment(txData: any) {
        let paymentEntries1 = [
            ...paymentEntries,
            {
                candidate_id: candidateData._id,
                batch_id: courseData.batch_id,
                batch_type_id: courseData.batch_type_id,
                center_id: courseData.center_id,
                course_id: courseData.course_id,
                exam_id: courseData.exam_id,
                session_id: courseData.session_id,
                stream_id: courseData.stream_id,
                sno: paymentEntries.length + 1,
                payment_mode: paymentMode,
                amount: amount,
                payment_type: paymentType,
                actual_payment_date: formatDate(moment()),
                edc_charges: 0,
                qr_charges: 0,
                total_amount: amount,
                in_favor_of: "",
                bank_name: txData.bankName,
                branch_name: "",
                ref_no: txData.txNumber,
                is_book_fee: paymentFor == 2 ? true : false,
                created_by: user._id,
                modified_by: user._id,
            },
        ];
        paymentEntries1 = paymentEntries1.map((val: any, index: number) => {
            return { ...val, sno: index + 1 };
        });
        setPaymentEntries(paymentEntries1);
        delete candidateData._id;
        const payload = {
            candidateData: { ...candidateData, center_id: courseData.center_id },
            courseEnrolment: { ...courseEnrolmentData, remarks: 'Online payment' },
            instalments: instalmentEntries,
            payments: paymentEntries1?.map((val: any) => {
                return { ...val, remarks: remark }
            }),
        };
        console.log(payload);
        dispatch(requestCourseEnrolmentPublic(payload)).then((val: any) => {
            if (val?.payload?.data?.success) {
                window.open(
                    ROUTE_PRINT_RECEIPT(val?.payload?.data?.data?.payments[0]._id), "_self");

                // notification.success({ message: "Course added for candidate" });
                // setPaymentModal(false);
                setPaymentMode('Paid');
            }
        });
    }
    useEffect(() => {
        let bookFee = 0;
        let admFee = 0;
        paymentEntries.forEach((val: any) => {
            if (val.is_book_fee) {
                bookFee += parseFloat(val.total_amount);
            } else {
                admFee += parseFloat(val.total_amount);
            }
        });
        setAddedAdmFee(admFee);
        setAddedBookFee(bookFee);
    }, [paymentEntries]);

    useEffect(() => {
        if (paymentType === "installment") {
            setAddedAdmFee(feeDetails?.general_instalment_structure[0].admission_amt);
            setAddedBookFee(feeDetails?.general_instalment_structure[0].book_amt);
            setPaymentEntries([])
            setAmount("")
        }
        else {

            setAddedAdmFee(feeDetails?.general_admission_fee);
            setAddedBookFee(feeDetails?.general_books_fee);
            setPaymentEntries([])
            setAmount("")
        }
    }, [paymentType]);

    const initiatePayment = async () => {
        const netAmt = paymentType === "installment"
            ? feeDetails?.general_instalment_structure[0]?.net_amt
            : (feeDetails?.general_total_fee - (feeDetails?.total_discount || 0));
        // netAmt = '1.00'
        setAmount(netAmt)

        const orderRes = await dispatch(initiatePaymentOrder({ body: {}, params: { studentId: candidateData._id ? candidateData._id : (candidateData.contact + "-" + candidateData.email), amount: netAmt } }))
        if (orderRes?.payload?.data?.data?.body?.resultInfo?.resultStatus !== 'S') {
            showNotification('Cannot initiate payment.', 'error')
            return;
        }
        const orderId = orderRes?.payload?.data?.reqBody?.body?.orderId;
        const txnToken = orderRes?.payload?.data?.data?.body?.txnToken;
        const txnAmount = orderRes?.payload?.data?.reqBody?.body?.txnAmount?.value;
        var config = {
            "root": "",
            "flow": "DEFAULT",
            "data": {
                "orderId": orderId, /* update order id */
                "token": txnToken, /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": txnAmount
            },
            "merchant": {
                "redirect": false
            },
            "handler": {
                "notifyMerchant": (eventName: any, data: any) => {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                },
                "transactionStatus": async (paymentStatus: any) => {
                    console.log("transactionStatus handler called")
                    console.log(paymentStatus)
                    domWindow.Paytm.CheckoutJS.close()
                    // if("STATUS" ==  "TXN_SUCCESS",)
                    const retData = await dispatch(checkPaymentOrderStatus({ body: {}, params: { orderId: orderId } }))
                    const resultStatus = retData?.payload?.data?.data?.body?.resultInfo?.resultStatus;
                    const { txnId, bankTxnId, gatewayName, paymentMode, txnDate, bankName } = retData?.payload?.data?.data?.body;

                    let txData = {
                        bankName: paymentMode + "/" + gatewayName + "/" + bankName,
                        txNumber: txnId + "/" + bankTxnId + "/" + txnDate
                    }

                    addOnlinePayment(txData)
                }
            }
        };
        if (domWindow.Paytm && domWindow.Paytm.CheckoutJS) {
            // domWindow.Paytm.CheckoutJS.onLoad(() => {
            domWindow.Paytm.CheckoutJS.init(config).then(() => {
                domWindow.Paytm.CheckoutJS.invoke();
            }).catch((error: any) => {
                console.log("error => ", error);
            });
            // });
        }
        return;
    }

    console.log(feeDetails)
    return (
        <>
            <Descriptions title="Course Fee Details" className="table-description">
                <Descriptions.Item label="Payment Criteria">
                    <Radio.Group
                        value={paymentType}
                        onChange={(evt) => setPaymentType(evt.target.value)}
                    >
                        <Radio value={"full"}>Full Payment</Radio>
                        <Radio disabled={feeDetails?.general_instalment_structure?.length == 0} value={"installment"}>Installments</Radio>
                    </Radio.Group>{" "}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Info">
                    {paymentType == 'full' ? 'Full Payment' : 'Instalment'}
                </Descriptions.Item>
                <Descriptions.Item label="Installment charges">
                    {feeDetails?.instalment_discount && feeDetails?.instalment_discount != 0 ? <><span className="line-through">Rs. {feeDetails.installment_charges}</span>&nbsp; &nbsp;Rs. {(feeDetails.installment_charges - feeDetails.instalment_discount)}</> : <>Rs. {feeDetails.installment_charges}</>}
                </Descriptions.Item>
                <Descriptions.Item label="Total Discount" span={3}>
                    {feeDetails.total_discount
                        ? "Rs. " + feeDetails.total_discount
                        : "NA"}
                </Descriptions.Item>
                <Descriptions.Item label="Old Student Discount"> NA </Descriptions.Item>
                <Descriptions.Item label="Handicapped Discount">
                    {candidateData.handicapped
                        ? `Rs. ${feeDetails.ph_total_discount}`
                        : "NA"}
                </Descriptions.Item>
                <Descriptions.Item label="Special Discount"> {feeDetails?.tuition_discount ? 'Rs. ' + feeDetails?.tuition_discount : 'NA'} </Descriptions.Item>
                <Descriptions.Item label="Total Fee" span={3}>
                    {" "}
                    {/* Rs {feeDetails?.general_total_fee} */}
                    {((feeDetails?.total_discount && feeDetails?.total_discount != 0) || (feeDetails?.instalment_discount && feeDetails?.instalment_discount != 0)) ? <><span className="line-through">Rs. {feeDetails.general_total_fee + (paymentType == 'installment' ? (parseFloat(feeDetails.installment_charges) || 0) : 0)}</span>&nbsp; &nbsp;Rs. {(feeDetails.general_total_fee + (paymentType == 'installment' ? ((parseFloat(feeDetails.installment_charges) || 0) - parseFloat(feeDetails.instalment_discount) || 0) : 0) - feeDetails.total_discount)}</> : <>Rs. {feeDetails.general_total_fee + (paymentType == 'installment' ? (parseFloat(feeDetails.installment_charges) || 0) : 0)}</>}
                </Descriptions.Item>
                <Descriptions.Item label="Admission fee">
                    {" "}
                    {/* Rs {feeDetails?.general_admission_fee} */}
                    {feeDetails?.admission_fee_discount && feeDetails?.admission_fee_discount != 0 ? <><span className="line-through">Rs. {feeDetails.general_admission_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.general_admission_fee - feeDetails.admission_fee_discount)}</> : <>Rs. {feeDetails.general_admission_fee}</>}
                </Descriptions.Item>
                <Descriptions.Item label="Tuition Fee">
                    {" "}
                    {/* Rs {feeDetails?.general_tuition_fee} */}
                    {feeDetails?.tuition_discount && feeDetails?.tuition_discount != 0 ? <><span className="line-through">Rs. {feeDetails.tuition_discount}</span>&nbsp; &nbsp;Rs. {(feeDetails.general_tuition_fee - feeDetails.tuition_discount)}</> : <>Rs. {feeDetails.general_tuition_fee}</>}
                </Descriptions.Item>
                <Descriptions.Item label="Book fee">
                    {/* Rs {feeDetails?.general_books_fee} */}
                    {feeDetails?.books_fee_discount && feeDetails?.books_fee_discount != 0 ? <><span className="line-through">Rs. {feeDetails.general_books_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.general_books_fee - feeDetails.books_fee_discount)}</> : <>Rs. {feeDetails.general_books_fee}</>}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Current Collected Amount"> Rs </Descriptions.Item>
            <Descriptions.Item label="Admission & Tuition Amount"> Rs -</Descriptions.Item>
            <Descriptions.Item label="Book Amount" span={24}>
                Rs -
            </Descriptions.Item> */}
            </Descriptions>

            {paymentType === "installment" && (
                <Col span={24}>
                    <Table
                        loading={false}
                        dataSource={feeDetails?.general_instalment_structure}
                        columns={installmentColumnsWithDate}
                        size="small"
                        pagination={false}
                    />
                </Col>
            )}

            {/* {(addedAdmFee + addedBookFee) < feeDetails?.general_total_fee && <>  */}
            {!publicPage &&
                <Descriptions
                    title="Fee Collection Payment Details"
                    style={{ marginTop: "20px" }}
                >
                    <Descriptions.Item label="Payment Mode">
                        <Radio.Group
                            value={paymentMode}
                            onChange={(evt) => setPaymentMode(evt.target.value)}
                        >
                            <Radio value={"Cash"}>Cash</Radio>
                            <Radio value={"EDC"} disabled>
                                EDC
                            </Radio>
                            <Radio value={"DD"} disabled>
                                DD
                            </Radio>
                            <Radio value={"Cheque"} disabled>
                                Cheque
                            </Radio>
                            <Radio value={"QR"} disabled>
                                QR Code
                            </Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee Submission for">
                        <Radio.Group
                            value={paymentFor}
                            onChange={(evt) => setPaymentFor(evt.target.value)}
                        >
                            <Radio
                                value={1}
                                disabled={
                                    parseFloat(feeDetails?.general_admission_fee) +
                                    parseFloat(feeDetails?.general_tuition_fee) <=
                                    addedAdmFee
                                }
                            >
                                Adm & Tuition
                            </Radio>
                            <Radio
                                value={2}
                                disabled={
                                    parseFloat(feeDetails?.general_book_fee) <= addedBookFee
                                }
                            >
                                Book
                            </Radio>
                        </Radio.Group>
                        <Button type="primary" disabled={paymentEntries?.length > 0} onClick={() => setSpecialRelations(true)}>
                            Special Relaxations
                        </Button>
                    </Descriptions.Item>
                </Descriptions>
            }
            {
                paymentMode === 'Paid' ? <>
                    <p className="text-center">
                        <img src={require('../../../assets/graphics/icons/success.gif')} style={{ maxWidth: "50%", width: "200px" }} />
                        <h2>Payment Successful</h2>
                        {/* <span>You may print the reciept opened in separate tab close this tab now.</span> */}
                    </p>
                </> : paymentMode === 'Online' ? <>
                    <Descriptions title="Online Payment" style={{ marginTop: "20px" }}>
                        <Descriptions.Item>
                            <Input
                                value={
                                    paymentType === "installment"
                                        ? feeDetails?.general_instalment_structure[0]?.net_amt
                                        : (feeDetails?.general_total_fee - (feeDetails?.total_discount || 0))
                                }
                                onChange={(evt: any) => setAmount(evt.target.value)}
                                placeholder={"Enter Amount"}
                            ></Input>
                        </Descriptions.Item>
                        <Descriptions.Item>
                            <Button type="primary" onClick={() => initiatePayment()}>Pay Online</Button>
                            {/* <Button onClick={() => addPayment()}>Add Payment</Button> */}
                        </Descriptions.Item>
                    </Descriptions>
                </> : paymentMode === "Cash" ? <Descriptions title="Cash" style={{ marginTop: "20px" }}>
                    <Descriptions.Item>
                        <Input
                            value={amount}
                            onChange={(evt: any) => setAmount(evt.target.value)}
                            placeholder={"Enter Amount"}
                        ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item>
                        <Button onClick={() => addPayment()}>Add Payment</Button>
                    </Descriptions.Item>
                </Descriptions> : paymentMode === "EDC" ? <Form>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Amount"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Transaction No"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Charge Category"}>
                                <Select style={{ width: "100%" }}>
                                    <Option value={"credit"}>Credit</Option>
                                    <Option value={"debit"}>Debit</Option>
                                    <Option value={"manual"}>Manual</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"EDC Charges"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Amount Total"}>
                                <label>0</label>
                            </Form.Item>
                        </Col>
                        <Col span={8} className={"text-right mt-5"}>
                            <Button type="default" htmlType="submit">Add Payment</Button>
                        </Col>
                    </Row>
                </Form> : paymentMode === "DD" ? <Form>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Amount"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"DD Date"}>
                                <DatePicker style={{ width: "100%" }}></DatePicker>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"In Favor of"}>
                                <Select style={{ width: "100%" }}>
                                    <Option value={"credit"}>Credit</Option>
                                    <Option value={"debit"}>Debit</Option>
                                    <Option value={"manual"}>Manual</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"DD No"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Bank Name"}>
                                <Select style={{ width: "100%" }}>
                                    <Option value={"credit"}>Credit</Option>
                                    <Option value={"debit"}>Debit</Option>
                                    <Option value={"manual"}>Manual</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Branch"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8} offset={16} className={"text-right mt-5"}>
                            <Button type="default" htmlType="submit">Add Payment</Button>
                        </Col>
                    </Row>
                </Form> : paymentMode === "Cheque" ? <Form>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Amount"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Cheque Date "}>
                                <DatePicker style={{ width: "100%" }}></DatePicker>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Cheque No"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Bank Name"}>
                                <Select style={{ width: "100%" }}>
                                    <Option value={"credit"}>Credit</Option>
                                    <Option value={"debit"}>Debit</Option>
                                    <Option value={"manual"}>Manual</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Branch"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8} className={"text-right mt-5"}>
                            <Button type="default" htmlType="submit">Add Payment</Button>
                        </Col>
                    </Row>
                </Form> : <Form>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Amount"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Cheque Date "}>
                                <DatePicker style={{ width: "100%" }}></DatePicker>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Cheque No"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Bank Name"}>
                                <Select style={{ width: "100%" }}>
                                    <Option value={"credit"}>Credit</Option>
                                    <Option value={"debit"}>Debit</Option>
                                    <Option value={"manual"}>Manual</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={"Amount"} label={"Branch"}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8} className={"text-right mt-5"}>
                            <Button type="default" htmlType="submit">Add Payment</Button>
                        </Col>
                    </Row>
                </Form>
            }



            {!publicPage && <><Table
                loading={false}
                dataSource={paymentEntries}
                columns={payColumns}
                size="small"
                pagination={false}
            />
                <p></p>
                <Descriptions title="Course Fee Details">
                    <Descriptions.Item label="Admission & Tuition Amount">
                        {" "}
                        {paymentType === "installment" ? (
                            <>
                                {" "}
                                Rs. {addedAdmFee}/Rs.{" "}
                                {feeDetails?.general_instalment_structure[0].admission_amt + +
                                    feeDetails?.general_instalment_structure[0].tuition_amt}
                            </>
                        ) : (
                            <>
                                {" "}
                                Rs. {addedAdmFee}/Rs.{" "}
                                {feeDetails?.general_admission_fee +
                                    feeDetails?.general_tuition_fee - (feeDetails?.admission_fee_discount || 0)
                                    - (feeDetails?.tuition_discount || 0)}
                            </>
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Book Amount">

                        {paymentType === "installment" ? (
                            <>
                                {" "}
                                Rs. {addedBookFee}/Rs.{" "}
                                {feeDetails?.general_instalment_structure[0].book_amt}
                            </>
                        ) : (
                            <>
                                {" "}
                                Rs. {addedBookFee}/ Rs. {feeDetails?.general_books_fee - (feeDetails?.books_fee_discount || 0)}
                            </>
                        )}


                    </Descriptions.Item>
                    <Descriptions.Item label="Total Amount">

                        {paymentType === "installment" ? (
                            <>
                                {" "}
                                Rs. {addedAdmFee + addedBookFee}/Rs.{" "}
                                {feeDetails?.general_instalment_structure[0].net_amt}
                            </>
                        ) : (
                            <>
                                {" "}
                                Rs. {addedAdmFee + addedBookFee}/ Rs. {feeDetails?.general_total_fee - (feeDetails?.total_discount || 0)}
                            </>
                        )}

                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title="Remarks" style={{ marginTop: "20px" }}>
                    <Descriptions.Item>
                        <TextArea
                            value={remark}
                            onChange={(evt: any) => setRemark(evt.target.value)}
                            placeholder={"Enter Fee Remark"}
                        ></TextArea>
                    </Descriptions.Item>
                </Descriptions>

                {
                    paymentType === "installment" ? <Button
                        type="primary"
                        disabled={addedAdmFee + addedBookFee != feeDetails?.general_instalment_structure[0]?.net_amt}
                        onClick={() => setPaymentDetails()}
                    >
                        Submit
                    </Button> : <Button
                        type="primary"
                        disabled={addedAdmFee + addedBookFee != (feeDetails?.general_total_fee - (feeDetails?.total_discount || 0))}
                        onClick={() => setPaymentDetails()}
                    >
                        Submit
                    </Button>
                }
            </>}


            {
                specialRelations && <Modal
                    open={specialRelations}
                    onCancel={() => setSpecialRelations(false)}
                    width={"80%"}
                    title={"Special Relaxations Details"}
                    // onOk={specialRelaxations()}
                    footer={false}
                >
                    <CandidateRelaxationCard candidateData={candidateData} courseData={courseData} feeData={feeData[0]} setFeeData={setFeeDataWithSR}></CandidateRelaxationCard>
                </Modal>
            }
        </>
    );
}
export default CourseCard;
