// App
export const ROUTE_PAGE_NOT_FOUND = `/404`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_FORGET_PASSWORD = `/forget-password`;
export const ROUTE_RESET_PASSWORD = `/reset-password`;

// API
export const LOGIN = "api/auth/login";
export const LOGOUT = "um/v1/logout";
export const TOKEN_REFRESH = "um/v1/refresh/token";
export const FORGET_PASSWORD = "um/v1/forget-password/";
export const RESET_PASSWORD = (token: string) =>
  `um/v1/reset-password/${token}`;
export const VERIFY_TOKEN = (token: string) => `um/v1/validate/${token}`;
