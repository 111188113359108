import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';

import { Button, Col, Form, Input, Row, Tabs } from 'antd';


import React, { useEffect, useRef, useState } from 'react';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

declare var CKFinder: any;

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    'data-node-key': string;
    onActiveBarTransform: (className: string) => void;
}

const DraggableTabNode = ({ className, onActiveBarTransform, ...props }: DraggableTabPaneProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({
        id: props['data-node-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    };

    useEffect(() => {
        if (!isSorting) {
            onActiveBarTransform('');
        } else if (className?.includes('ant-tabs-tab-active')) {
            onActiveBarTransform(
                css`
          .ant-tabs-ink-bar {
            transform: ${CSS.Transform.toString(transform)};
            transition: ${transition} !important;
          }
        `,
            );
        }
    }, [className, isSorting, transform]);

    return React.cloneElement(props.children as React.ReactElement, {
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners,
    });
};

const IconCardTabs = ({ infoCardsStructure, setInfoCardsStructure }: any) => {

    const [className, setClassName] = useState('');
    const defaultPanes = [] as any;
    const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setItems((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.key === active.id);
                const overIndex = prev.findIndex((i: any) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const [activeKey, setActiveKey] = useState("0");
    const [items, setItems] = useState(defaultPanes);
    const newTabIndex = useRef(0);

    const onChange = (key: string) => {
        setActiveKey(key);
    };
    useEffect(() => {
        const oldItems = [...items] as any
        infoCardsStructure?.forEach((val: any, index: number) => {
            oldItems.push({
                label: val.card_title, children: tabData(`${index}`, val), key: `${index}`, card_subtitle: val.card_subtitle, card_icon: val.card_icon
            })
        })
        setItems(oldItems);
    }, [])
    useEffect(() => {
        if (items?.length > 0) {
            const str = items.map((val: any, index: number) => {
                return {
                    order: index,
                    card_title: val?.label,
                    card_subtitle: val?.card_subtitle,
                    card_icon: val?.card_icon,
                }
            })
            setInfoCardsStructure(str)
        }
    }, [items])
    const openPopup = (idx: any) => {
        CKFinder.popup({
            chooseFiles: true,
            onInit: (finder: any) => {
                finder.on('files:choose', function (evt: any) {
                    var file = evt.data.files.first();
                    const url = file.getUrl();
                    setItems((prev: any) => {
                        return prev.map((val: any) => {
                            return val.key === idx ? {
                                ...val, card_icon: url
                            } : val
                        })
                    })
                });
                finder.on('file:choose:resizedImage', function (evt: any) {
                    const url = evt.data.resizedUrl();
                    setItems((prev: any) => {
                        return prev.map((val: any) => {
                            return val.key === idx ? {
                                ...val, card_icon: url
                            } : val
                        })
                    })
                });
            }
        });
    }
    const add = () => {
        const newActiveKey = `${newTabIndex.current++}`;
        const oldItems = [...items] as any
        oldItems.push({
            label: `Card Title`, children: tabData(newActiveKey), key: newActiveKey, card_subtitle: '', card_icon: ''
        })
        setItems(oldItems);
        setActiveKey(newActiveKey);
    };
    const tabData = (newActiveKey: any, val = {
        card_title: `Card Title`,
        card_subtitle: 'Card Sub Title',
        card_icon: ''
    }) => {
        return <Row className='p-3'>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Form.Item name={'card_title_' + newActiveKey}
                            initialValue={items[newActiveKey]?.label ? items[newActiveKey]?.label : val.card_title}
                            label="Card Title">
                            <Input
                                onChange={
                                    (e) => {
                                        setItems((prev: any) => {
                                            return prev.map((val: any) => {
                                                return val.key === newActiveKey ? {
                                                    ...val, label: e.target.value
                                                } : val
                                            })
                                        })
                                    }
                                }></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'card_sub_title_' + newActiveKey} initialValue={items[newActiveKey]?.card_subtitle ? items[newActiveKey]?.card_subtitle : val.card_subtitle} label="Card Sub Title">
                            <Input
                                onChange={
                                    (e) => {
                                        setItems((prev: any) => {
                                            return prev.map((val: any) => {
                                                return val.key === newActiveKey ? {
                                                    ...val, card_subtitle: e.target.value
                                                } : val
                                            })
                                        })
                                    }
                                }></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }}>
                        <Button type="primary" onClick={() => openPopup(newActiveKey)}>
                            Select/Upload Icon
                        </Button>
                    </Col>
                </Row>


            </Col>

        </Row>
    }
    const remove = (targetKey: TargetKey) => {
        const targetIndex = items.findIndex((pane: any) => pane.key === targetKey);
        const newPanes = items.filter((pane: any) => pane.key !== targetKey);
        if (newPanes.length && targetKey === activeKey) {
            const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
            setActiveKey(key);
        }
        setItems(newPanes);
    };

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };
    return (
        <Row>
            <Col span={24} className="p-3 mb-3" style={{ border: '1px solid #aaa', borderRadius: '5px' }}>
                <div style={{ marginBottom: 16 }} className="ant-form-item-label">
                    <label className="w500">INFO CARDS STRUCTURE</label><br />
                    <Button type='primary' onClick={add}>Add New Card</Button>
                </div>
                <Tabs
                    hideAdd
                    onChange={onChange}
                    activeKey={activeKey}
                    type="editable-card"
                    onEdit={onEdit}
                    className={className}
                    items={items}
                    renderTabBar={(tabBarProps, DefaultTabBar) => (
                        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                            <SortableContext items={items.map((i: any) => i.key)} strategy={horizontalListSortingStrategy}>
                                <DefaultTabBar {...tabBarProps}>
                                    {(node) => (
                                        <DraggableTabNode
                                            {...node.props}
                                            key={node.key}
                                            onActiveBarTransform={setClassName}
                                        >
                                            {node}
                                        </DraggableTabNode>
                                    )}
                                </DefaultTabBar>
                            </SortableContext>
                        </DndContext>
                    )}
                />
                <Row>
                    {items.map((val: any) => {
                        return <Col span={8} className="p-2"><div style={{ border: '1px solid #aaa', borderRadius: '5px' }}>
                            <Row align={'middle'}>
                                <Col span={6} className='p-2'>
                                    <img src={val?.card_icon ? val?.card_icon : ``} style={{ maxWidth: '100%' }} />
                                </Col>
                                <Col span={18}>
                                    <strong>{val.label}</strong><br />
                                    {val.card_subtitle}
                                </Col>
                            </Row>
                        </div></Col>
                    })}
                </Row>
            </Col>


        </Row >

    );
};

export default IconCardTabs;