import { PictureFilled } from "@ant-design/icons";
import { Form, Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { useState } from "react";

const ImageUpload = ({ name, maxSize = 300 }: any) => {
  const form = Form.useFormInstance();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = ({ file: newFile, fileList: newFileList }: any) => {
    const isAllowed = newFile?.size / 1024 < maxSize; // max file size: maxSize(KB)

    if (!isAllowed) {
      form.setFields([
        {
          name: name,
          errors: ["Image size should be less than " + maxSize + " KB"],
        },
      ]);
      return;
    } else {
      form.setFieldsValue({ [name]: newFileList });
    }
    setFileList(newFileList);
  };

  const uploadButton = (
    <>
      <div className="image-area">
        <PictureFilled className="font-size-24" />
      </div>
      <div className="text font-size-12">Asset Image</div>
    </>
  );

  return (
    <>
      <Upload
        accept=".png,.jpeg"
        name={name}
        listType="picture-card"
        fileList={fileList}
        showUploadList={{ showPreviewIcon: false }}
        beforeUpload={(file: any) => false}
        onChange={handleChange}
      >
        {fileList.length < 1 && uploadButton}
      </Upload>
    </>
  );
};

export default ImageUpload;
