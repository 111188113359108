import { Button, Col, Row, Space, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt, BiReceipt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestCoursePayments } from "../../../core/store/reducers/admission.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";


function PaymentList() {
    const [filterVisible, setFilterVisible] = useState(false);
    const [paymentsList, setPaymentsList] = useState([] as any);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { payments, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

    useEffect(() => {
        loadList();
    }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
            };
            dispatch(requestCoursePayments({ params: data, body: filters }));
        },
        [filters, dispatch, sorter, pagination]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        if (payments?.length > 0) {
            setPaymentsList(
                payments.map((obj: any, index: number) => {
                    return { key: index, ...obj };
                })
            );
        }
    }, [payments]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'Reg. No.',
            dataIndex: ['candidate_id', 'registration_number'],
            key: 'candidate_name',
        },
        {
            title: 'Name',
            dataIndex: ['candidate_id', 'candidate_name'],
            key: 'candidate_name',
        },
        {
            title: 'Mobile',
            dataIndex: ['candidate_id', 'contact'],
            key: 'contact',
        },
        {
            title: 'Email',
            dataIndex: ['candidate_id', 'email'],
            key: 'email',
        },
        {
            title: 'Payment Date',
            render: (data: any) => {
                return formatDate(data.actual_payment_date, "DD MMM, YYYY")
            },
            key: 'actual_payment_date',
        },
        {
            title: 'Payment Type',
            render: (data: any) => {
                return data.is_book_fee ? "Books" : "Adm/Tuition"
            },
            key: 'actual_payment_date',
        },
        {
            title: 'Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },
        {
            title: 'Mode',
            dataIndex: 'payment_mode',
            key: 'payment_mode',
        },
        {
            title: 'Center',
            dataIndex: ["course_enrolment_id", 'batch_id', 'center_id', 'center_name'],
            key: 'center_name',
        },
        {
            title: 'Course',
            dataIndex: ["course_enrolment_id", 'batch_id', 'course_id', 'course_name'],
            key: 'course_name',
        },
        {
            title: 'Batch Type',
            dataIndex: ["course_enrolment_id", 'batch_id', 'batch_type_id', 'batch_type_name'],
            key: 'batch_type_name',
        },
        {
            title: 'Exam',
            dataIndex: ["course_enrolment_id", 'batch_id', 'exam_id', 'exam_name'],
            key: 'exam_name',
        },
        {
            title: 'Stream',
            dataIndex: ["course_enrolment_id", 'batch_id', 'stream_id', 'stream_name'],
            key: 'stream_name',
        },
        {
            title: 'Session',
            dataIndex: ["course_enrolment_id", 'batch_id', 'session_id', 'session'],
            key: 'session',
        },
        {
            title: 'Action',
            dataIndex: 'address',
            fixed: 'right',
            render: (a: any, record: any) => {
                return (
                    <>
                        {/* <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<EditOutlined />}
                            // onClick={() => openAddEditPanel(a)}
                            ></Button>
                        </Tooltip>
                        <Tooltip title={"Details"}>
                            <Button
                                // onClick={() => navigate("/enquiry-details/" + a._id)}
                                type="link"
                                className="table-button"
                                icon={<ArrowRightOutlined />}
                            ></Button>
                        </Tooltip> */}
                        <Tooltip title={"Print Receipt"}>
                            <Button
                                onClick={() => {
                                    window.open("/print-receipt/" + record._id)
                                }}
                                type="link"
                                className="table-button"
                                icon={<BiReceipt />}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onFilterApply = (values: any) => {
        // let arrFilter = [];
        // for (let [key, value] of Object.entries(values)) {
        //   if (value !== "") {
        //     arrFilter.push({ key: key, value: value });
        //   }
        // }
        // let refinedArray = arrFilter.filter((data) => {
        //   return data.value !== undefined;
        // });
        // let obj = {} as any;
        // refinedArray.forEach((data: any) => {
        //   obj[data.key] = data.value;
        // });
        // setPagination({
        //   page: 1,
        //   limit: pagination.limit,
        // });
        // dispatch(setFilters(obj)); //filters
    };
    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "condidate_name",
                label: "",
                placeholder: "Candidate Name Search",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Payments"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
        <DataTableComponent
            loading={false}
            dataSource={paymentsList}
            columns={columns}
            onChange={onChangeHandler}
            title={"Payment List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="2000px"
        />


    </>


}
export default PaymentList;


