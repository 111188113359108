import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_WEBSITE_GENERIC_CREATE,
  API_WEBSITE_GENERIC_LIST_MINIMAL,
  API_WEBSITE_GENERIC_LIST_W_PAGE,
  API_WEBSITE_GENERIC_UPDATE,
} from "../../../shared/routes/website.routes";
import { User } from "../../../shared/types/user-management";
import { Client } from "../../api-client";
const config = {
  name: "userManagement",
};
export const getGenericList: any = createAsyncThunk(
  `${config.name}/getGenericList`,
  async (payload: any) => {
    let params = payload.params;
    return Client.post(
      API_WEBSITE_GENERIC_LIST_W_PAGE(payload.module),
      payload.body,
      { params }
    );
  }
);
export const getGenericListMinimal: any = createAsyncThunk(
  `${config.name}/getGenericListMinimal`,
  async (payload: any) => {
    return Client.get(API_WEBSITE_GENERIC_LIST_MINIMAL(payload.module), {
      params: payload.params,
    });
  }
);

export const genericWebsiteCreate: any = createAsyncThunk(
  `${config.name}/genericWebsiteCreate`,
  async ({ payload, module }: any) => {
    return Client.post(API_WEBSITE_GENERIC_CREATE(module), payload);
  }
);
export const genericWebsiteUpdate: any = createAsyncThunk(
  `${config.name}/genericWebsiteUpdate`,
  async ({ payload, module }: any) => {
    return Client.put(
      API_WEBSITE_GENERIC_UPDATE(module, payload?._id),
      payload
    );
  }
);

export const websiteManagementReducer = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    genericList: [] as any,
    total: 0 as number,
    filters: [] as any,
  },
  reducers: {
    resetWebsiteData: (state) => {},
    setFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetFilters: (state) => {
      state.filters = [] as any;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGenericList.pending, (state) => {
        state.loading = true;
        state.genericList = [] as Array<User>;
      })
      .addCase(getGenericList.fulfilled, (state, action) => {
        state.loading = false;
        state.genericList = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(getGenericList.rejected, (state) => {
        state.loading = false;
        state.genericList = [] as Array<User>;
      });
  },
});

export const { resetWebsiteData, resetFilters, setFilters } =
  websiteManagementReducer.actions;
export default websiteManagementReducer.reducer;
