import { notification, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editCandidateRegistration, requestCandidateRegistration } from '../../../core/store/reducers/admission.slice';
import { RootState } from '../../../shared/types/common';
import AdmissionForm from './admissondetails';
import AdmissionFormPersonalDetails from './personaldetails';

const App = ({ onClose, id, enquiryDetails, isEdit, data: _data, setCandidateData, show = true }: any) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const [current, setCurrent] = useState(0 as number);
    const [data, setData] = useState(_data ? _data : []) as any;
    const dispatch = useDispatch();

    useEffect(() => {
        if (enquiryDetails?.user_name) {
            setData({
                candidate_name: enquiryDetails.user_name,
                email: enquiryDetails.user_email,
                contact: enquiryDetails.user_number
            })
        }
    }, [enquiryDetails])

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const submitDetails = async (_data: any) => {
        if (isEdit) {
            let regNo = data.registration_number;
            delete data.registration_number
            dispatch(editCandidateRegistration({ data: { ...data, ..._data, modified_by: user._id }, regNo: regNo })).then((val: any) => {
                if (val?.payload?.data?.success) {
                    onClose()
                    notification.success({ message: "Candidate updated succesfully." });
                }
            })
            return
        }
        if (!show) {
            console.log({ ...data, ..._data, });
            localStorage.setItem('candidate_data', { ...data, ..._data, created_by: user._id, modified_by: user._id })
            setCandidateData(
                { _id: null, ...data, ..._data, created_by: user._id, modified_by: user._id }
            )
            onClose()
            // dispatch(requestCandidateRegistration({ ...data, ..._data, })).then((val: any) => {
            //     if (val?.payload?.data?.success) {
            //         onClose()
            //         setCandidateData(val?.payload?.data?.data)
            //         notification.success({ message: "Candidate registered succesfully. You can mark this lead as converted now." });
            //     }
            // })
        }
        else {
            dispatch(requestCandidateRegistration({ ...data, ..._data, center_id: enquiryDetails.centre._id, enquiry_id: enquiryDetails._id, created_by: user._id, modified_by: user._id })).then((val: any) => {
                if (val?.payload?.data?.success) {
                    onClose()
                    // setCandidateData(val?.payload?.data?.data)
                    notification.success({ message: "Candidate registered succesfully. You can mark this lead as converted now." });
                }
            })
        }


    }
    const steps = [
        {
            title: 'Admission Form',
            content: <AdmissionForm show={false} next={() => next()} setData={(values: any) => setData({ ...data, ...values })} data={data}></AdmissionForm>,
        },
        {
            title: 'Personal Details',
            content: <AdmissionFormPersonalDetails data={data} next={(data: any) => submitDetails(data)} setData={(values: any) => setData({ ...data, ...values })} prev={() => prev()}></AdmissionFormPersonalDetails>,
        },
        // {
        //     title: 'Fee Details',
        //     content: 'Last-content',
        // },
    ];
    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const contentStyle: React.CSSProperties = {
        lineHeight: '260px',
        textAlign: 'center',
        marginTop: 16,
    };
    return (
        <>
            <Steps current={current} items={items} style={{ display: !show ? "none" : "block" }} />
            <div style={contentStyle}>{steps[current].content}</div>

        </>
    );
};

export default App;