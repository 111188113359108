import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_CREATE_USER,
  API_USER_LIST,
} from "../../../shared/routes/user-management.routes";
import { User } from "../../../shared/types/user-management";
import { Client } from "../../api-client";
const config = {
  name: "userManagement",
};
export const getUserList: any = createAsyncThunk(
  `${config.name}/getUserList`,
  async (payload: any) => {
    let params = payload.params;
    return Client.post(API_USER_LIST, payload.body, { params });
  }
);

// export const getUserCount: any = createAsyncThunk(
//   `${config.name}/getUserCount`,
//   async () => {
//     return Client.get(USERS_COUNT);
//   }
// );

export const createNewUser: any = createAsyncThunk(
  `${config.name}/createNewUser`,
  async (body: any) => {
    return Client.post(API_CREATE_USER, body);
  }
);
// export const editUser: any = createAsyncThunk(
//   `${config.name}/editUser`,
//   async (body: any) => {
//     return Client.patch(USER_BY_ID(body.id), body);
//   }
// );
// export const deleteUserById: any = createAsyncThunk(
//   `${config.name}/editUser`,
//   async (id: string) => {
//     return Client.delete(USER_BY_ID(id));
//   }
// );

// export const getUserRoleList: any = createAsyncThunk(
//   `${config.name}/getUserRolesList`,
//   async (payload: any) => {
//     // let params = payload.queryString;
//     return Client.get(ROLES);
//   }
// );

// export const createNewUserRole: any = createAsyncThunk(
//   `${config.name}/createNewUserRole`,
//   async (body: any) => {
//     return Client.post(ROLE, body);
//   }
// );

// export const getPermissionsList: any = createAsyncThunk(
//   `${config.name}/getPermissionsList`,
//   async () => {
//     return Client.get(PERMISSIONS);
//   }
// );
// export const getUserGroupsList: any = createAsyncThunk(
//   `${config.name}/getUserGroupsList`,
//   async () => {
//     return Client.get(GROUPS);
//   }
// );

export const userManagementReducer = createSlice({
  name: config.name,
  initialState: {
    loadingUsers: false,
    userList: [] as Array<User>,
    total: 0 as number,
    filters: [] as any,
  },
  reducers: {
    resetUserList: (state) => {
      state.loadingUsers = false;
      state.userList = [] as Array<User>;
      state.total = 0 as number;
      state.filters = [] as any;
    },
    setFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetFilters: (state) => {
      state.filters = [] as any;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserList.pending, (state) => {
        state.loadingUsers = true;
        state.userList = [] as Array<User>;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.loadingUsers = false;
        state.userList = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(getUserList.rejected, (state) => {
        state.loadingUsers = false;
        state.userList = [] as Array<User>;
      });
  },
});

export const { resetUserList, resetFilters, setFilters } =
  userManagementReducer.actions;
export default userManagementReducer.reducer;
