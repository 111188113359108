import { Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestPaymentDetail } from "../../../core/store/reducers/admission.slice";
import { getCourseRelationByCenterCourse } from "../../../core/store/reducers/masters.slice";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";
import "./print-reciept.style.less";

const PrintReceipt = () => {
    const { payment } = useSelector((state: RootState) => state.admission);
    const dispatch = useDispatch();
    const params = useParams();
    const [courseRelData, setCourseRelData] = useState(null as any);
    const [amtRows, setAmtRows] = useState(null as any);

    useEffect(() => {
        dispatch(requestPaymentDetail(params?.id));
    }, []);
    useEffect(() => {
        const center_id = payment?.course_enrolment_id?.batch_id?.center_id?._id;
        const course_id = payment?.course_enrolment_id?.batch_id?.course_id?._id;
        let payment1 = JSON.parse(JSON.stringify(payment))
        if (payment1?.instalmentData?.length == 1) {
            payment1.instalment_id.admission_amount -= payment1.instalment_id.admission_discount
            payment1.instalment_id.tuition_amount -= payment1.instalment_id.tuition_discount
            payment1.instalment_id.books_amount -= payment1.instalment_id.books_discount
        }
        if (center_id && course_id) {
            dispatch(getCourseRelationByCenterCourse({ center_id: center_id, course_id: course_id })).then((val: any) => {
                if (val?.payload?.data?.data?.length > 0) {
                    setCourseRelData(val?.payload?.data?.data[0])
                    console.log(val?.payload?.data?.data[0])
                    let courseRel = val?.payload?.data?.data[0];
                    if (payment1?.is_book_fee === true) {

                        let cgst = courseRel.bk_tax_cgst;
                        let sgst = courseRel.bk_tax_sgst;
                        let igst = courseRel.bk_tax_igst;
                        let row = {
                            rows: [] as any,
                            total: {} as any,
                            gstv: 0,
                            balance: { balance: 0, ins_charges: 0, due: 0, due_date: '' } as any,
                            summary: {} as any
                        } as any;
                        row.rows.push({
                            sno: 1,
                            name: 'Book Fee',
                            hsn: '999293',
                            amt: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) + payment1?.instalment_id?.books_discount).toFixed(2),
                            discount: payment1?.instalment_id?.books_discount,
                            taxable: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst)).toFixed(2),
                            cgstp: courseRel.bk_tax_cgst,
                            cgstv: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) * cgst / 100).toFixed(2),
                            sgstp: courseRel.bk_tax_sgst,
                            sgstv: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) * sgst / 100).toFixed(2),
                            igstp: courseRel.bk_tax_igst,
                            igstv: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) * igst / 100).toFixed(2),
                            total: payment1?.instalment_id?.books_amount
                        })
                        row.total = {
                            sno: '',
                            name: 'Total',
                            hsn: '',
                            amt: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) + payment1?.instalment_id?.books_discount).toFixed(2),
                            discount: payment1?.instalment_id?.books_discount,
                            taxable: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst)).toFixed(2),
                            cgstp: '',
                            cgstv: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) * cgst / 100).toFixed(2),
                            sgstp: '',
                            sgstv: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) * sgst / 100).toFixed(2),
                            igstp: '',
                            igstv: (payment1?.instalment_id?.books_amount * 100 / (100 + cgst + sgst + igst) * igst / 100).toFixed(2),
                            // gstv: 0,
                            total: payment1?.instalment_id?.books_amount
                        }
                        row.gstv = (row.total.total - row.total.taxable).toFixed(2);
                        if (payment1?.instalmentData?.length > 2) {
                            payment1.instalmentData.filter((val: any) => {
                                return val.realized == false
                            }).forEach((val: any) => {
                                row.balance.balance += val?.books_amount;
                                row.balance.due_date = row.balance.due_date != '' ? row.balance.due_date : formatDate(val.prefered_instalment_date, "DD MMM, YYYY");
                            })
                            row.balance.ins_charges = row.balance.balance != 0 ? payment1?.course_enrolment_id?.instalment_fee : 0;
                            row.balance.due += row.balance.ins_charges + row.balance.balance;
                        }
                        setAmtRows(row)
                    } else {
                        let cgst = courseRel.at_tax_cgst;
                        let sgst = courseRel.at_tax_sgst;
                        let igst = courseRel.at_tax_igst;
                        let row = {
                            rows: [] as any,
                            total: {} as any,
                            gstv: 0,
                            balance: { balance: 0, ins_charges: 0, due: 0, due_date: '' } as any,
                            summary: {} as any
                        } as any;
                        row.rows.push({
                            sno: 1,
                            name: 'Admission Fee',
                            hsn: '999293',
                            amt: (payment1?.instalment_id?.admission_amount * 100 / (100 + cgst + sgst + igst) + payment1?.instalment_id?.admission_discount).toFixed(2),
                            discount: payment1?.instalment_id?.admission_discount,
                            taxable: (payment1?.instalment_id?.admission_amount * 100 / (100 + cgst + sgst + igst)).toFixed(2),
                            cgstp: cgst,
                            cgstv: (payment1?.instalment_id?.admission_amount * 100 / (100 + cgst + sgst + igst) * cgst / 100).toFixed(2),
                            sgstp: sgst,
                            sgstv: (payment1?.instalment_id?.admission_amount * 100 / (100 + cgst + sgst + igst) * sgst / 100).toFixed(2),
                            igstp: igst,
                            igstv: (payment1?.instalment_id?.admission_amount * 100 / (100 + cgst + sgst + igst) * igst / 100).toFixed(2),
                            total: payment1?.instalment_id?.admission_amount
                        })
                        row.rows.push({
                            sno: 2,
                            name: 'Tuition Fee',
                            hsn: '999293',
                            amt: (payment1?.instalment_id?.tuition_amount * 100 / (100 + cgst + sgst + igst) + payment1?.instalment_id?.tuition_discount).toFixed(2),
                            discount: payment1?.instalment_id?.tuition_discount,
                            taxable: (payment1?.instalment_id?.tuition_amount * 100 / (100 + cgst + sgst + igst)).toFixed(2),
                            cgstp: cgst,
                            cgstv: (payment1?.instalment_id?.tuition_amount * 100 / (100 + cgst + sgst + igst) * cgst / 100).toFixed(2),
                            sgstp: sgst,
                            sgstv: (payment1?.instalment_id?.tuition_amount * 100 / (100 + cgst + sgst + igst) * sgst / 100).toFixed(2),
                            igstp: igst,
                            igstv: (payment1?.instalment_id?.tuition_amount * 100 / (100 + cgst + sgst + igst) * igst / 100).toFixed(2),
                            total: payment1?.instalment_id?.tuition_amount
                        })
                        row.total = {
                            sno: '',
                            name: 'Total',
                            hsn: '',
                            amt: (parseFloat(row.rows[0].amt) + parseFloat(row.rows[1].amt)).toFixed(2),
                            discount: parseFloat(row.rows[0].discount) + parseFloat(row.rows[1].discount),
                            taxable: (parseFloat(row.rows[0].taxable) + parseFloat(row.rows[1].taxable)).toFixed(2),
                            cgstp: '',
                            cgstv: (parseFloat(row.rows[0].cgstv) + parseFloat(row.rows[1].cgstv)).toFixed(2),
                            sgstp: '',
                            sgstv: (parseFloat(row.rows[0].sgstv) + parseFloat(row.rows[1].sgstv)).toFixed(2),
                            igstp: '',
                            igstv: (parseFloat(row.rows[0].igstv) + parseFloat(row.rows[1].igstv)).toFixed(2),
                            // gstv: 0,
                            total: (parseFloat(row.rows[0].total) + parseFloat(row.rows[1].total)).toFixed(2)
                        }
                        row.gstv = (row.total.total - row.total.taxable).toFixed(2);
                        if (payment1?.instalmentData?.length > 2) {
                            payment1.instalmentData.filter((val: any) => {
                                return val.realized == false
                            }).forEach((val: any) => {
                                row.balance.balance += val?.books_amount;
                                row.balance.due_date = row.balance.due_date != '' ? row.balance.due_date : formatDate(val.prefered_instalment_date, "DD MMM, YYYY");
                            })
                            row.balance.ins_charges = row.balance.balance != 0 ? payment1?.course_enrolment_id?.instalment_fee : 0;
                            row.balance.due += row.balance.ins_charges + row.balance.balance;
                        }
                        setAmtRows(row)
                    }
                }
            });
            setTimeout(() => {
                Print();
            }, 1000);
        }


    }, [payment])

    const Print = () => {
        //console.log('print');
        let printContents: any = document.getElementById("report-view")?.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };
    // console.log(candidate, "");

    return (
        <>
            <Button onClick={Print}>Print</Button>
            <div className="report-view-container">
                <div className="report-view" id="report-view">
                    <div>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", float: "right", padding: "5px", background: "#00ffff !important" }}>GSTIN - 21AAFCV2531H1ZQ</p>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", fontSize: '18px' }}>VANIK EDUCATION PVT LTD</p>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", fontSize: '14px', maxWidth: '70%' }}>Address : {payment?.course_enrolment_id?.batch_id?.center_id?.center_address}</p>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", fontSize: '14px' }}>Mobile : {payment?.course_enrolment_id?.batch_id?.center_id?.phone} &nbsp;&nbsp;&nbsp;&nbsp; Email ID : {payment?.course_enrolment_id?.batch_id?.center_id?.email}</p>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px', fontWeight: 'bold' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '80%', verticalAlign: 'center' }}>
                                        <p
                                            style={{ color: "black", margin: "0" }}
                                            className="text-center">TAX INVOICE</p>
                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px' }}>
                                        <p style={{
                                            color: "black",
                                            margin: "0pt"
                                        }}>Fee Receipt</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '20px', fontWeight: "bold" }}>
                                        <p style={{ color: "black" }}>Reverse Charge : &nbsp;&nbsp;<span>Nil</span></p>
                                        <p style={{ color: "black" }}>Invoice No. : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>
                                            {payment?.invoice_number}
                                        </span></p>
                                        <p style={{ color: "black" }}>Invoice Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(payment?.actual_payment_date).format("DD MMM YYYY")}</p>
                                        <p style={{ color: "black" }} className="capitalize">State :  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{courseRelData?.center_ref?.center_city_ref?.state}</p>
                                        <p style={{ color: "black", margin: "0" }}>State Code : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21 </p>
                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px', fontWeight: "bold" }}>
                                        <p style={{ color: "black" }}>Place of delivery : &nbsp;&nbsp;&nbsp;{payment?.candidate_id?.present_address?.present_city}</p>
                                        <p style={{ color: "black" }}>Date of supply : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(payment?.actual_payment_date).format("DD MMM YYYY")}</p>
                                        <p style={{ color: "black" }}>Place of supply : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{payment?.course_enrolment_id?.batch_id?.center_id?.center_city}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr style={{ border: "1px solid #000", fontWeight: "bold" }}>
                                    <td colSpan={4} style={{ padding: '3px 7px 1px 7px' }}>
                                        <p style={{ color: "black", margin: "0" }} className="text-center">Details of Receiver | Billed to</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '20%', padding: '20px', borderLeft: "1px solid #000", fontWeight: "bold" }}>
                                        <p style={{ color: "black" }}>
                                            Roll Number :
                                        </p>
                                        <p style={{ color: "black" }}>Student Name :</p>
                                        <p style={{ color: "black" }}>State :</p>
                                        <p style={{ color: "black" }}>State Code :</p>
                                        <p style={{ color: "black" }}>Course :</p>
                                        <p style={{ color: "black" }}>Exam :</p>
                                        <p style={{ color: "black" }}>Batch Code :</p>
                                        <p style={{ color: "black" }}>Batch Type :</p>
                                        <p style={{ color: "black" }}>Batch St. Date :</p>
                                    </td>
                                    <td style={{ width: '20%', borderLeft: 'none!important', padding: '20px' }}>
                                        <p style={{ color: "black" }}>
                                            {
                                                payment?.course_enrolment_id?.roll_no}
                                        </p>
                                        <p style={{ color: "black" }}>{payment?.candidate_id?.candidate_name}</p>
                                        <p style={{ color: "black" }}>{courseRelData?.center_ref?.center_city_ref?.state}</p>
                                        <p style={{ color: "black" }}>21</p>
                                        <p style={{ color: "black" }}>{payment?.course_enrolment_id?.batch_id?.course_id?.course_name}</p>
                                        <p style={{ color: "black" }}>{payment?.course_enrolment_id?.batch_id?.exam_id?.exam_name}</p>
                                        <p style={{ color: "black" }}>{payment?.course_enrolment_id?.batch_id?.batch_type_id?.batch_type_name}</p>
                                        <p style={{ color: "black" }}>{payment?.course_enrolment_id?.batch_id?.batch_code_id?.batch_code}</p>
                                        <p style={{ color: "black" }}>{payment?.course_enrolment_id?.batch_id?.batch_time_id?.batch_time_from + " " + payment?.course_enrolment_id?.batch_id?.batch_time_id?.batch_time_to}</p>

                                    </td>
                                    <td style={{ width: '45%', padding: '20px', borderLeft: "1px solid #000" }}>
                                        <p style={{ color: "black" }}><span style={{ fontWeight: "bold" }}>Stream :</span>  <span>{payment?.course_enrolment_id?.batch_id?.stream_id?.stream_name}</span></p>
                                        <p style={{ color: "black" }}>
                                            <span style={{ fontWeight: "bold" }}>Venue</span> :<br />
                                            {
                                                payment?.course_enrolment_id?.batch_id?.center_id
                                                    ?.center_address
                                            }
                                        </p>
                                        <p style={{ color: "black", fontWeight: "bold" }}>Student Address :</p>
                                        <p style={{ color: "black" }}>
                                            {payment?.candidate_id?.present_address?.present_address_line_1}{" "}
                                            {payment?.candidate_id?.present_address?.present_address_line_2}{" "}
                                            {payment?.candidate_id?.present_address?.present_city}{" "}
                                            {payment?.candidate_id?.present_address?.present_district}{" "}
                                            {payment?.candidate_id?.present_address?.present_state}{" "}
                                            {payment?.candidate_id?.present_address?.present_pincode}
                                        </p>
                                    </td>
                                    <td style={{ width: '15%', padding: '3px 7px 1px 7px', borderLeft: "1px solid #000", borderRight: "1px solid #000" }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Student Photo :</p>
                                        <table border={0} cellSpacing={0} cellPadding={0}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: '3px 7px 1px 7px' }}>
                                                        <img
                                                            src={payment?.candidate_id?.photo}
                                                            alt=""
                                                            width={136}
                                                            height={137}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                {amtRows?.rows?.length > 0 ? (
                                    <>
                                        <tr className="text-center" style={{ fontWeight: "bold" }}>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Sr no</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Name of Service/Product</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>HSN/ SAC</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Amount (₹)</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Less: Discount(₹)</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Taxable value(₹)</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>CGST</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>SGST</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>IGST</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Total(₹)</p>
                                            </td>
                                        </tr>
                                        <tr className="text-center" style={{ fontWeight: "bold" }}>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Rate(%)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Amt.(₹)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Rate(%)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Amt.(₹)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Rate(%)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Amt.(₹)</p>
                                            </td>
                                        </tr>
                                        {amtRows?.rows?.map((val: any) => {
                                            return <tr>
                                                {Object.keys(val).map((key: any) => {
                                                    return <td style={{ padding: '3px 7px 1px 7px' }}>
                                                        <p style={{ color: "black", margin: "0" }}>{val[key]}</p>
                                                    </td>
                                                })}
                                            </tr>
                                        })}
                                        <tr>
                                            {Object.keys(amtRows?.total).map((key: any) => {
                                                return <td style={{ padding: '3px 7px 1px 7px' }}>
                                                    <p style={{ color: "black", margin: "0" }}>{amtRows?.total[key]}</p>
                                                </td>
                                            })}
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Sr no</p>
                                            </td>
                                            <td colSpan={2} rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Name of Service/Product</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>HSN/ SAC</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Amount (₹)</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Less: Discount(₹)</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Taxable value(₹)</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>CGST</p>
                                            </td>
                                            <td colSpan={3}>
                                                <p style={{ color: "black", margin: "0" }}>SGST</p>
                                            </td>
                                            <td colSpan={5}>
                                                <p style={{ color: "black", margin: "0" }}>IGST</p>
                                            </td>
                                            <td rowSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Total(₹)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Rate(%)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Amt.(₹)</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>Rate(%)</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Amt.(₹)</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Rate(%)</p>
                                            </td>
                                            <td colSpan={3}>
                                                <p style={{ color: "black", margin: "0" }}>Amt.(₹)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>1</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Admission Fee</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>-</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>{payment?.instalment_id?.admission_amount}</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>{payment?.instalment_id?.admission_amount}</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>00.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td colSpan={3}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>{payment?.instalment_id?.admission_amount}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>2</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>Tuition Fee</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>-</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>{payment?.instalment_id?.tuition_amount}</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>{payment?.instalment_id?.tuition_amount}</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>00.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td colSpan={2}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td colSpan={3}>
                                                <p style={{ color: "black", margin: "0" }}>0.00</p>
                                            </td>
                                            <td style={{ padding: '3px 7px 1px 7px' }}>
                                                <p style={{ color: "black", margin: "0" }}>{payment?.instalment_id?.tuition_amount}</p>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                {amtRows?.balance &&
                                    <tr>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Balance Amount</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0" }}>{amtRows?.balance?.balance == 0 ? 'Nil' : '₹ ' + amtRows.balance.balance}</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Installment Charges</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0" }}>{amtRows?.balance?.ins_charges == 0 ? 'Nil' : '₹ ' + amtRows.balance.ins_charges}</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Total Amount Due</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0" }}>{amtRows?.balance?.due == 0 ? 'Nil' : '₹ ' + amtRows.balance.due}</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Due Date</p>
                                        </td>
                                        <td style={{ padding: '3px 7px 1px 7px' }}>
                                            <p style={{ color: "black", margin: "0" }}>{amtRows?.balance?.due_date == '' ? 'NA' : amtRows.balance.due_date}</p>
                                        </td>
                                    </tr>}
                                <tr>
                                    <td style={{ padding: '20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Mode Of Payment :</p>
                                    </td>
                                    <td colSpan={7} style={{ padding: '20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>{payment?.payment_mode}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }} className="text-center">Remarks</p>
                                    </td>
                                    <td style={{ width: '25%', padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Total Amount Before Tax</p>
                                    </td>
                                    <td style={{ width: '15%', padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {amtRows?.total?.taxable}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={5} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>{payment?.remarks}</p>
                                    </td>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Add: CGST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {amtRows?.total?.cgstv}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Add: SGST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {amtRows?.total?.sgstv}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Add: IGST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {amtRows?.total?.igstv}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Tax Amount: GST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {amtRows?.gstv}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Total Amount After Tax</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {amtRows?.total?.total}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={3} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>• VANIK reserves all the rights to make any alterations in
                                            its programs/fee/faculty/venue of classes without any
                                            prior notice to anybody under unavoidable circumstances.
                                            However, all such changes will be
                                            well-notified to the students. The decision of VANIK shall
                                            be final and abiding. </p><p style={{ color: "black", margin: "0" }}>• Student can avail the fee refund
                                                facility within first five days for Regular batches &amp;
                                                first 3 days for Weekend batches from the date of admission
                                                batch or course.Fee will be refunded after deducting initial
                                                processing and admission charges, equivalent to admission
                                                fee (As mentioned in fee receipt). Refund amount will be
                                                paid through account payee cheque only, which will be in the
                                                name of the candidate. </p><p style={{ color: "black", margin: "0" }}>• Instalment charge / late fee will
                                                    applied in case of payment not received before due date
                                        </p>
                                    </td>
                                    <td style={{ padding: '7px 20px 5px 20px', height: '20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>GST Payable on Reverse Charge</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>Nil</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px', height: '20px' }} colSpan={2}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Authorized Signatory &amp; Seal</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ height: '150px' }} colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrintReceipt;
