import { Button, Col, Drawer, Form, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { genericWebsiteCreate, genericWebsiteUpdate } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
const CreateEditTagsComponent = ({ onClose, visible, onSubmit, selectedEntity }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { user } = useSelector((state: RootState) => state.auth);
    const onFinish = (values: any) => {
        if (selectedEntity) {
            const payload = {
                ...values,
                _id: selectedEntity._id,
                updated_by: user._id,
            };
            dispatch(genericWebsiteUpdate({ payload: payload, module: 'tags' })).then((val: any) => {
                if (val?.payload?.data) {
                    showNotification("Tags Updated Successfully", "success");
                    onSubmit();
                }
            });
        } else {
            const payload = {
                ...values,
                created_by: user._id,
                updated_by: user._id,
            }
            dispatch(genericWebsiteCreate({ payload: payload, module: 'tags' })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification("Tags Saved Successfully", "success");
                    onSubmit();
                }
            })
        }
    }
    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]

    return <>
        <Drawer
            maskClosable={false}
            title="Manage Tags"
            className="form-drawer"
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={{ ...selectedEntity }}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
            >
                <Row >
                    <Col span={12} className="gutter-row pr-2">
                        <Form.Item name="tag_name" label="Tag Name">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer>
    </>
}

export default CreateEditTagsComponent;