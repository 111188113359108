import AdmissionReducer from "./admission.slice";
import authReducer from "./auth.slice";
import lmsReducer from "./lead-management.slice";
import masterReducer from "./masters.slice";
import settingsReducer from "./settings.slice";
import userManagementReducer from "./user-management.slice";
import websiteManagementReducer from "./website.slice";
const allReducers = {
  auth: authReducer,
  userManagement: userManagementReducer,
  leadManagement: lmsReducer,
  settings: settingsReducer,
  masters: masterReducer,
  admission: AdmissionReducer,
  website: websiteManagementReducer,
};
export default allReducers;
