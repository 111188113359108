export const ROUTE_MANAGE_USERS = "/manage-users";
export const ROUTE_MANAGE_USER_GROUPS = "/manage-user-groups";
export const ROUTE_MANAGE_USER_ROLES = "/manage-user-roles";
export const ROUTE_USER_PROFILE = "/user-profile";

const PREFIX = "api/um";
export const API_USER_LIST = PREFIX + "/employee/all";
export const API_CREATE_USER = PREFIX + "/employee";
// export const USER_BY_ID = (id: string) => `um/v1/user/${id}`;
// export const USERS = "um/v1/users";
// export const USERS_COUNT = "um/v1/users/count";
// export const ROLE = "um/v1/role";
// export const ROLES = "um/v1/roles?isComposite=true";
// export const PERMISSIONS = "um/v1/roles?isComposite=false";
// export const GROUPS = "um/v1/groups";
