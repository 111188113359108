import { Col, Row } from "antd";

type Props = {
  title: string;
  subtitle: string;
  buttons?: JSX.Element;
};
const ContentHeaderComponent: React.FC<Props> = ({
  title,
  subtitle,
  buttons = <></>,
}: Props) => {
  return (
    <>
      <div className="my-3 mx-4">
        <Row align="middle">
          <Col flex="auto">
            <span className="weight-300 font-size-24">{title}</span>
          </Col>
          <Col>{buttons}</Col>
        </Row>
      </div>
    </>
  );
};
export default ContentHeaderComponent;
