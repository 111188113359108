import { Drawer } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../core/api-client";
import {
  createMasterData,
  getCourseListByCenter,
  updateMasterData
} from "../../core/store/reducers/masters.slice";
import GenericFormComponent from "../../shared/components/generic-form/generic-form.component";
import { API_GET_MASTER_DROPDOWN } from "../../shared/routes/masters.routes";
import { RootState } from "../../shared/types/common";
import { showNotification } from "../../shared/utils/utils";

interface Props {
  onClose: () => void;
  visible: boolean;
  selectedModuleData?: any;
  moduleData: any;
  onSubmit?: () => void;
}
const CreateEditMaster = ({
  onClose,
  visible,
  selectedModuleData = null,
  moduleData,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { screenType } = useSelector((state: RootState) => state.settings);
  const [selectedValues, setSelectedValues] = useState({} as any);
  const [addEditFormData, setAddEditFormData] = useState([] as any);
  const [formProps, setFormProps] = useState({} as any);

  if (typeof selectedModuleData?.center_city_ref === 'object') {
    selectedModuleData.center_city_ref = selectedModuleData.center_city_ref._id
  }
  var colSpan = screenType.isPortrait ? 24 : 12;
  const onFinish = (values: any) => {
    let payload = {
      ...values,
      modified_by: user._id,
    };
    if (payload?.center_city_ref) {
      payload.city_id = payload.center_city_ref;
    }
    if (payload?.batch_time_from) {
      payload.batch_time_from = moment(payload.batch_time_from).format("hh:mm A")
    }
    if (payload?.batch_time_to) {
      payload.batch_time_to = moment(payload.batch_time_to).format("hh:mm A")
    }
    if (selectedModuleData === null) {
      payload.created_by = user._id;
      dispatch(createMasterData({ path: moduleData.key, body: payload })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("New Record Created", "success");
            onSubmit();
          }
        }
      );
    } else {
      payload.updated_by = user._id;
      payload.modified_by = user._id;
      payload = { ...selectedModuleData, ...payload };
      dispatch(updateMasterData({ path: moduleData.key, body: payload })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("Record Updated", "success");
            onSubmit();
          }
        }
      );
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };

  const onValuesChange = (value: any, _allValues: any) => {
    if (value.centre) {
      dispatch(getCourseListByCenter({ center_id: value.centre })).then(
        (val: any) => {
          if (val?.payload?.data?.data?.length > 0) {
          }
        }
      );
    }
  };
  useEffect(() => {
    if (moduleData?.addEditForm?.length > 0) {
      prepareForm(moduleData.addEditForm);
    }
  }, [moduleData]);
  const prepareForm = async (formData: any) => {
    for (let k = 0; k < formData.length; ++k) {
      formData[k].colSpan = formData[k].colSpan ? formData[k].colSpan : colSpan;
      if (formData[k].api === true) {
        const ddData = await Client.get(
          API_GET_MASTER_DROPDOWN(formData[k].schemaKey),
          { params: { key: formData[k].labelKey } }
        );
        if (ddData?.data?.success) {
          formData[k].options = ddData.data.data.map((val: any) => {
            return {
              value: val[formData[k].valKey],
              label: (
                <span className="capitalize">{val[formData[k].labelKey]}</span>
              ),
            };
          });
        }
      }
    }
    setAddEditFormData(formData);
  };
  useEffect(() => {
    if (typeof selectedModuleData?.batch_time_from == 'string') {
      selectedModuleData.batch_time_from = moment("2023-01-01 " + selectedModuleData.batch_time_from)
    }
    if (typeof selectedModuleData?.batch_time_to == 'string') {
      selectedModuleData.batch_time_to = moment("2023-01-01 " + selectedModuleData.batch_time_to)
    }
    if (addEditFormData?.length > 0) {
      setFormProps({
        name: "creat-edit-" + moduleData.key,
        heading: (selectedModuleData ? "Edit " : "Add New ") + moduleData.label,
        initialValues: {
          ...selectedModuleData,
        },
        formElements: addEditFormData,
        extraButtons: [
          {
            // htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
          },
          {
            // htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
          },
          {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
          },
        ],
        onFinish: onFinish,
        onFinishFailed: onFinishFailed,
        onValuesChange: onValuesChange,
      });
    }
  }, [addEditFormData]);

  console.log(screenType);
  return (
    <>
      {formProps?.heading && (
        <Drawer
          maskClosable={false}
          title={<strong>{formProps.heading}</strong>}
          className="form-drawer"
          width={
            screenType.isPortrait
              ? "90%"
              : screenType.isLg || screenType.isXl
                ? 720
                : 500
          }
          onClose={onClose}
          open={visible}
        >
          <GenericFormComponent {...formProps} />
        </Drawer>
      )}
    </>
  );
};

export default CreateEditMaster;
