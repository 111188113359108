import { Button, Checkbox, Col, DatePicker, Descriptions, Divider, Input, Row, Select, Space, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import CandidateCard from "./candidate-card";
var _ = require("lodash");

function CandidateRelaxationCard({ candidateData, courseData, feeData, setFeeData = () => { } }: any) {
    const [admFee, setAdmFee] = useState(false)
    const [bookFee, setBookFee] = useState(false)
    const [feeStructure, setFeeStructure] = useState(null as any)

    const installmentColumns = [
        {
            title: "Installment No.",
            dataIndex: "sno",
            key: "sno",
            render: (a: any) => { return feeStructure?.general_installments_count == 0 ? "Full Payment" : <>{a}</> }
        },
        {
            title: "Adm Ins. Amount/Discount",
            key: "adm",
            render: (a: any, _: any, index: any) => <Space split={"/"}>
                <Input value={a.admission_amt} onChange={(e: any) => setInstalmentStructureData('admission_amt', e.target.value, index)}></Input>
                <Input value={a.admission_fee_discount} onChange={(e: any) => setInstalmentStructureData('admission_fee_discount', e.target.value, index)}></Input>
            </Space>
        },
        {
            title: "Tuition Ins. Amount/Discount",
            key: "amount",
            render: (a: any, _: any, index: any) => {
                return <Space split={"/"}>
                    <Input value={a.tuition_amt} onChange={(e: any) => setInstalmentStructureData('tuition_amt', e.target.value, index)}></Input>
                    <Input value={a.tuition_discount} onChange={(e: any) => setInstalmentStructureData('tuition_discount', e.target.value, index)}></Input>
                </Space>
            }
        },
        {
            title: "Book Ins. Amount/Discount",
            key: "payment_type",
            render: (a: any, _: any, index: any) => <Space split={"/"}>
                <Input value={a.book_amt} onChange={(e: any) => setInstalmentStructureData('book_amt', e.target.value, index)}></Input>
                <Input value={a.books_fee_discount} onChange={(e: any) => setInstalmentStructureData('books_fee_discount', e.target.value, index)}></Input>
            </Space>
        },
        {
            title: "Ins. Amount/Discount",
            key: "actual_payment_date",
            render: (a: any, _: any, index: any) => <Space split={"/"}>
                <Input value={a.net_amt} disabled></Input>
                <Input value={a.instalment_discount} disabled></Input>
            </Space>
        },
        {
            title: "Installment Date",
            key: "prefered_instalment_date",
            render: (a: any, _: any, index: any) =>
                <DatePicker value={moment(a.prefered_instalment_date)} onChange={(e: any) => setInstalmentStructureData('prefered_instalment_date', e, index)}></DatePicker>

        }

    ]
    // console.log("feeData", feeData)
    const setInstalmentStructureData = (key: string, value: any, index: number) => {
        let feeStructure1 = JSON.parse(JSON.stringify(feeStructure))
        if (key == 'prefered_instalment_date') {
            feeStructure1.general_instalment_structure[index][key] = moment(value).format('YYYY-MM-DD');
            setFeeStructure(feeStructure1)
            return;
        }
        feeStructure1.general_instalment_structure[index][key] = parseFloat(value)
        // if (['admission_amt', 'tuition_amt', 'book_amt'].indexOf(key) !== -1) {
        feeStructure1.general_instalment_structure[index].net_amt =
            feeStructure1.general_instalment_structure[index].admission_amt +
            feeStructure1.general_instalment_structure[index].tuition_amt +
            feeStructure1.general_instalment_structure[index].book_amt
        // }
        feeStructure1.general_instalment_structure[index].instalment_discount =
            feeStructure1.general_instalment_structure[index].admission_fee_discount +
            feeStructure1.general_instalment_structure[index].tuition_discount +
            feeStructure1.general_instalment_structure[index].books_fee_discount;
        if (index == feeStructure.general_installments_count - 1) {
            feeStructure1.general_instalment_structure[index].net_amt =
                feeStructure1.general_instalment_structure[index].admission_amt +
                feeStructure1.general_instalment_structure[index].tuition_amt +
                feeStructure1.general_instalment_structure[index].book_amt +
                feeStructure1.installment_charges - (feeStructure1.instalment_discount || 0)

            feeStructure1.general_instalment_structure[index].instalment_discount =
                feeStructure1.general_instalment_structure[index].admission_fee_discount +
                feeStructure1.general_instalment_structure[index].tuition_discount +
                feeStructure1.general_instalment_structure[index].books_fee_discount +
                feeStructure1.instalment_discount || 0;
        }

        setFeeStructure(feeStructure1)
    }
    useEffect(() => {
        setFeeStructure({ ...feeData, tuition_discount: 0, admission_fee_discount: 0, books_fee_discount: 0, instalment_discount: 0, general_installments_count: 0, general_instalment_structure: [] })
    }, [feeData])
    const setTuitionDiscount = (e: any) => {
        setFeeStructure({ ...feeStructure, tuition_discount: parseFloat(e.target.value) })
    }
    const setAdmDiscount = (e: any) => {
        setFeeStructure({ ...feeStructure, admission_fee_discount: parseFloat(e.target.value) })
    }
    const setBookDiscount = (e: any) => {
        setFeeStructure({ ...feeStructure, books_fee_discount: parseFloat(e.target.value) })
    }
    const setInsDiscount = (e: any) => {
        setFeeStructure({ ...feeStructure, instalment_discount: parseFloat(e.target.value) })
    }
    const setInstalments = (e: any) => {
        let general_installments_count = parseInt(e);
        let general_instalment_structure = [];
        for (let k = 0; k < general_installments_count; ++k) {
            general_instalment_structure.push(
                {
                    "sno": (k + 1),
                    // "admission_amt": feeStructure?.general_instalment_structure[k].admission_amt,
                    // "tuition_amt": feeStructure?.general_instalment_structure[k].tuition_amt,
                    // "book_amt": feeStructure?.general_instalment_structure[k].book_amt,
                    // "net_amt": feeStructure?.general_instalment_structure[k].net_amt,
                    // "": feeStructure?.general_instalment_structure[k].,
                    // "net_amt": feeStructure?.general_instalment_structure[k].net_amt,
                    // "net_amt": feeStructure?.general_instalment_structure[k].net_amt,
                    "admission_amt": 0,
                    "tuition_amt": 0,
                    "book_amt": 0,
                    "net_amt": 0,
                    "admission_fee_discount": 0,
                    "tuition_discount": 0,
                    "books_fee_discount": 0,
                    "instalment_discount": 0,
                    "ins_sub_after_day": 0,
                    "prefered_instalment_date": moment()
                }
            )
        }

        setFeeStructure({
            ...feeStructure, general_installments_count: parseInt(e), general_instalment_structure: general_instalment_structure,
            ...(general_installments_count < 2 ? { instalment_discount: 0 } : {})
        })
    }
    const submitFeeStructure = () => {
        setFeeData({
            ...feeStructure,
            total_discount: feeStructure?.admission_fee_discount + feeStructure?.tuition_discount + feeStructure?.books_fee_discount
        }, feeStructure?.general_installments_count > 0 ? 'installment' : 'full')
    }

    let insFooter = {
        "admission_amt": 0,
        "tuition_amt": 0,
        "book_amt": 0,
        "net_amt": 0,
        "admission_fee_discount": 0,
        "tuition_discount": 0,
        "books_fee_discount": 0,
        "net_discount": 0,
        "ins_sub_after_day": 0
    }
    for (let k = 0; k < feeStructure?.general_instalment_structure?.length; ++k) {
        let fee = feeStructure?.general_instalment_structure[k];
        insFooter.admission_amt += fee.admission_amt
        insFooter.tuition_amt += fee.tuition_amt
        insFooter.book_amt += fee.book_amt
        insFooter.net_amt += fee.net_amt
        insFooter.admission_fee_discount += fee.admission_fee_discount
        insFooter.tuition_discount += fee.tuition_discount
        insFooter.books_fee_discount += fee.books_fee_discount
        insFooter.net_discount += fee.admission_fee_discount + fee.tuition_discount + fee.books_fee_discount
    }
    console.log("FeeStr", feeStructure)
    console.log(insFooter)
    // insFooter.net_discount += feeStructure.instalment_discount;
    // insFooter.net_amt += feeStructure.installment_charges;
    return (
        <>{feeStructure && <Row>
            <Col span={24}>
                <CandidateCard
                    candidateData={candidateData}
                    courseData={courseData}
                ></CandidateCard>
            </Col>
            <Col span={24} className="pt-3">
                <Descriptions title="Fee Details" className="table-description">
                    <Descriptions.Item label="Admission Fee">{feeStructure?.general_admission_fee}</Descriptions.Item>
                    <Descriptions.Item label="Tuition Fee">{feeStructure?.general_tuition_fee}</Descriptions.Item>
                    <Descriptions.Item label="Books Fee">{feeStructure?.general_books_fee}</Descriptions.Item>
                    <Descriptions.Item label="Total Fee">{feeStructure?.general_total_fee}</Descriptions.Item>
                    <Descriptions.Item label="Min. First Ins. Amount for Book Fee">{Math.round(feeStructure?.min_percent_first_ins_book_fee * feeStructure?.general_books_fee / 100)}</Descriptions.Item>
                </Descriptions>
            </Col>
            <Divider></Divider>
            <Col span={24}>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>Special Discount</td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            <Input onChange={setTuitionDiscount}></Input>
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%" }}>
                            <Checkbox style={{ fontSize: "14px" }} checked={admFee} onChange={(evt) => setAdmFee(evt.target.checked)}>Admission Fee Discount</Checkbox>
                            {
                                admFee && <Input onChange={setAdmDiscount}></Input>
                            }

                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Tuition Fee Discount
                            {
                                (feeStructure.tuition_discount && feeStructure.tuition_discount != 0) ? <Input value={feeStructure.tuition_discount} disabled></Input> : <></>
                            }
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            <Checkbox style={{ fontSize: "14px" }} checked={bookFee} onChange={(evt) => setBookFee(evt.target.checked)}>Books Fee Discount</Checkbox>
                            {
                                bookFee && <Input onChange={setBookDiscount}></Input>}

                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Select Installments <br></br>
                            <Select value={feeStructure?.general_installments_count} style={{ width: "100%" }} onChange={setInstalments}>
                                {[
                                    { name: 'No Instalments', value: 0 },
                                    // { name: 'Instalment 1', value: 1 },
                                    { name: 'Instalment 2', value: 2 },
                                    { name: 'Instalment 3', value: 3 },
                                    { name: 'Instalment 4', value: 4 },
                                    { name: 'Instalment 5', value: 5 }
                                ].map((val: any) => {
                                    return <Select.Option value={val.value}>{val.name}</Select.Option>
                                })}
                            </Select>
                        </td>
                    </tr>
                </table>
            </Col>
            <Col span={24}>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>Installment Charges</td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            {feeStructure.installment_charges}
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Installment Discount
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}><Input disabled={feeStructure?.general_installments_count < 2} value={feeStructure?.instalment_discount} onChange={setInsDiscount}></Input></td>

                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Remaining Ins. Charges
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            {feeStructure.installment_charges - (feeStructure.instalment_discount || 0)}
                        </td>
                    </tr>
                </table>
            </Col>
            <Col span={24}>
                <table style={{ width: "100%" }}>
                    <tr >
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>Enter Remarks</td>
                        <td style={{ border: "1px solid #f0f0f0", width: "83%", padding: "10px", fontSize: "14px" }}>
                            <TextArea></TextArea>
                        </td>

                    </tr>
                </table>
            </Col>
            <Col span={24}>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td style={{ border: "1px solid #f0f0f0", width: "32%", padding: "10px", fontSize: "14px" }}>Fee Details After Special Discount</td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Total Fee (with Ins. Charges) : <b>
                                {feeStructure.general_total_fee - feeStructure.tuition_discount - feeStructure.admission_fee_discount - feeStructure.books_fee_discount + (feeStructure?.general_installments_count >= 2 ? (feeStructure.installment_charges - (feeStructure.instalment_discount || 0)) : 0)}
                            </b>
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Admission Fee : <b>{feeStructure.general_admission_fee - feeStructure.admission_fee_discount}</b>
                        </td>
                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Tuition Fee : <b>{feeStructure.general_tuition_fee - feeStructure.tuition_discount}</b>
                        </td>

                        <td style={{ border: "1px solid #f0f0f0", width: "16.6%", padding: "10px", fontSize: "14px" }}>
                            Books Fee : <b>{feeStructure.general_books_fee - feeStructure.books_fee_discount}</b>
                        </td>

                    </tr>
                </table>
            </Col>
            <Divider></Divider>
            {feeStructure?.general_instalment_structure?.length > 0 && <Col span={24}>
                <Table
                    loading={false}
                    dataSource={feeStructure?.general_instalment_structure}
                    columns={installmentColumns}
                    size="small"
                    pagination={false}
                    summary={() => (
                        <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}><strong>Total / Remaining</strong></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{feeStructure.general_admission_fee - feeStructure.admission_fee_discount} / {feeStructure.general_admission_fee - feeStructure.admission_fee_discount - (insFooter.admission_amt)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{feeStructure.general_tuition_fee - feeStructure.tuition_discount} / {feeStructure.general_tuition_fee - feeStructure.tuition_discount - (insFooter.tuition_amt)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{feeStructure.general_books_fee - feeStructure.books_fee_discount} / {feeStructure.general_books_fee - feeStructure.books_fee_discount - (insFooter.book_amt)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{feeStructure.general_total_fee - feeStructure.tuition_discount - feeStructure.admission_fee_discount - feeStructure.books_fee_discount + (feeStructure?.general_installments_count >= 2 ? (feeStructure.installment_charges - (feeStructure.instalment_discount || 0)) : 0)} / {feeStructure.general_total_fee - feeStructure.tuition_discount - feeStructure.admission_fee_discount - feeStructure.books_fee_discount + (feeStructure?.general_installments_count >= 2 ? (feeStructure.installment_charges - (feeStructure.instalment_discount || 0)) : 0) - (insFooter.net_amt)}</Table.Summary.Cell>
                                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </Col>}
            <Col span={24}>

                <Button
                    loading={false}
                    onClick={submitFeeStructure}
                    className={"px20"}
                    type={'primary'}
                >
                    Submit
                </Button>
            </Col>
        </Row>}

        </>
    );
}
export default CandidateRelaxationCard;
