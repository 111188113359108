import { Descriptions } from "antd";
import { formatDate } from "../../../shared/utils/utils";
function CandidateCardNoBatch({ candidateData }: any) {
    const data = candidateData
    return <>
        <Descriptions title="Student Registration Details" className="table-description">
            <Descriptions.Item label="Registration Number">{data?.registration_number ? data?.registration_number : 'xxxxxxxxxx'}</Descriptions.Item>
            <Descriptions.Item label="Roll No">xxxxxxxxxx</Descriptions.Item>
            <Descriptions.Item label="Candidate Name">{data?.candidate_name}</Descriptions.Item>
            <Descriptions.Item label="Date of Birth">{formatDate(data?.dob, "MMM DD, Y")}</Descriptions.Item>
            <Descriptions.Item label="Gender" className="capitalize">{data?.gender}</Descriptions.Item>
            <Descriptions.Item label="Category">{data?.category}</Descriptions.Item>
            <Descriptions.Item label="Is Handicapped">{data?.handicapped ? 'Yes' : 'No'}</Descriptions.Item>
        </Descriptions>
    </>
}
export default CandidateCardNoBatch;


