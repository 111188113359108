import { Col, Form, Row, Select } from "antd";
const { Option } = Select
const FilterRelationship = ({ type, centers }: any) => {

    return (
        <Form>

            <Row align="middle" className={"padding20"} gutter={50}>

                {
                    type.includes("session") && <Col span={12}>
                        <Form.Item
                            label="Session"
                            name="session"
                            rules={[{ required: true, message: 'Please choose session' }]}
                        >
                            <Select>

                            </Select>
                        </Form.Item>
                    </Col>
                }

                {
                    type.includes("center") && <Col span={12}>
                        <Form.Item
                            label="Center Name"
                            name="session"
                            rules={[{ required: true, message: 'Please choose center name' }]}
                        >
                            <Select placeholder={"Choose Center Name"}>
                                {
                                    centers.map((item: any) => {
                                        return <Option value={item?._id}>{item?.center_name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                }

                {
                    type.includes("course") && <Col span={12}>
                        <Form.Item
                            label="Course Name"
                            name="session"
                            rules={[{ required: true, message: 'Please choose Course' }]}
                        >
                            <Select></Select>
                        </Form.Item>
                    </Col>
                }

                {
                    type.includes("batch") && <Col span={12}>
                        <Form.Item
                            label="Batch Type"
                            name="session"
                            rules={[{ required: true, message: 'Please choose Batch' }]}
                        >
                            <Select></Select>
                        </Form.Item>
                    </Col>
                }

                {
                    type.includes("exam") && <Col span={12}>
                        <Form.Item
                            label="Exam Name"
                            name="session"
                            rules={[{ required: true, message: 'Please choose Exam' }]}
                        >
                            <Select></Select>
                        </Form.Item>
                    </Col>
                }
                {
                    type.includes("stream") && <Col span={12}>
                        <Form.Item
                            label="Stream Name"
                            name="session"
                            rules={[{ required: true, message: 'Please choose Stream' }]}
                        >
                            <Select></Select>
                        </Form.Item>
                    </Col>
                }


            </Row>
        </Form>
    );
};

export default FilterRelationship;