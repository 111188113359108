import { useEffect } from 'react';
declare var CKFinder: any;
const FileManagerPage = () => {

    useEffect(() => {
        setTimeout(() => {
            CKFinder.widget('file-manager', {
                height: 700
            });
        }, 500);
    }, []);

    return <>
        <div id="file-manager"></div>
    </>
}
export default FileManagerPage;