import "../../assets/styles/resetpassword.less";
import LoginLayout from "../../shared/layouts/login.layout";

function ForgotPasswordPage() {
  return <LoginLayout>{/* <ForgotPasswordForm /> */}</LoginLayout>;
}
export default ForgotPasswordPage;

// export function ForgotPasswordForm() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [mailSent, setMailSent] = useState(false);
//   const onFinish = (values: any) => {
//     const data = {
//       userEmail: values?.username,
//     };
//     dispatch(requestForgetPassword(data)).then((val: any) => {
//       if (val?.payload?.status === 200) {
//         setMailSent(true);
//       }
//     });
//   };
//   return (
//     <>
//       {!mailSent && (
//         <Row>
//           <Col
//             xs={{ span: 22, offset: 1 }}
//             md={{ span: 20, offset: 2 }}
//             lg={{ span: 18, offset: 3 }}
//             xl={{ span: 12, offset: 6 }}
//           >
//             <Form
//               name="normal_login"
//               className="login-form"
//               layout="vertical"
//               onFinish={onFinish}
//             >
//               <h2 className="font-size-28 weight-300">
//                 {t("LOGIN.FORGOTPASSWORD")}
//               </h2>
//               <h5>{t("LOGIN.PASSWORDRESETLINK")}</h5>
//               <Form.Item
//                 name="username"
//                 label={t("COMMON.LABELS.EMAILID")}
//                 rules={[
//                   {
//                     required: true,
//                     message: t("LOGIN.MESSAGES.USERNAMEREQUIRED"),
//                   },
//                   {
//                     type: "email",
//                     message: t("LOGIN.MESSAGES.USERNAMEINVALID"),
//                   },
//                   {
//                     max: 50,
//                     message: t("COMMON.ERROR.MAX_LENGTH", { length: 50 }),
//                   },
//                 ]}
//               >
//                 <Input
//                   size="large"
//                   placeholder={t("COMMON.PLACEHOLDERS.EMAIL_ID")}
//                 />
//               </Form.Item>
//               <Form.Item>
//                 <div style={{ textAlign: "right" }}>
//                   <Space>
//                     <Button
//                       className="btn-cancel"
//                       size="large"
//                       onClick={() => navigate(ROUTE_LOGIN)}
//                     >
//                       {t("COMMON.ACTIONS.CANCEL")}
//                     </Button>
//                     <Button
//                       type="primary"
//                       size="large"
//                       className={"mt20"}
//                       htmlType="submit"
//                     >
//                       {t("COMMON.ACTIONS.SEND")}
//                     </Button>
//                   </Space>
//                 </div>
//               </Form.Item>
//             </Form>
//           </Col>
//         </Row>
//       )}
//       {mailSent && (
//         <Row>
//           <Col
//             xs={{ span: 22, offset: 1 }}
//             md={{ span: 20, offset: 2 }}
//             lg={{ span: 18, offset: 3 }}
//             xl={{ span: 12, offset: 6 }}
//           >
//             <h2 className="text-center font-size-28 weight-300">
//               {t("LOGIN.RESETPASSWORDLINKSENT")}
//             </h2>
//             <h5 className="text-center">{t("LOGIN.CHECKEMAILFORRESET")}</h5>
//             <p className="text-center mt-5">
//               <Button
//                 className="btn-cancel"
//                 htmlType="submit"
//                 size="large"
//                 style={{ width: "150px" }}
//                 onClick={() => navigate(ROUTE_LOGIN)}
//               >
//                 {t("COMMON.ACTIONS.GOTOLOGIN")}
//               </Button>
//             </p>
//           </Col>
//         </Row>
//       )}
//     </>
//   );
// }
