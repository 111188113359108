import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
// import icons
import { BiRupee } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import {
  RiDashboardLine,
  RiDatabase2Line, RiUserAddLine,
  RiUserReceivedLine
} from "react-icons/ri";

import { useSelector } from "react-redux";
import "../../../assets/styles/sidebar.less";
import {
  ROUTE_BANK_DEPOSIT, ROUTE_DAILY_EXPENSES,
  ROUTE_EDUCATION_DEBIT_TALLY,
  ROUTE_EDUCATION_INCOME_TALLY,
  ROUTE_FEE_CLEARANCE,
  ROUTE_PUBLICATION_DEBIT_TALLY,
  ROUTE_PUBLICATION_INCOME_TALLY
} from "../../routes/accounts.routes";
import {
  ROUTE_CANDIDATES_LIST, ROUTE_ENROLMENTS_LIST,
  ROUTE_FEE_CLOSING_APPROVAL,
  ROUTE_INSTALMENTS_LIST, ROUTE_PAYMENTS_LIST,
  ROUTE_REFUNDS_LIST
} from "../../routes/admission.routes";
import { ROUTE_DASHBOARD } from "../../routes/dashboard.routes";
import {
  ROUTE_ENQUIRY_DASHBOARD,
  ROUTE_ENQUIRY_LIST
} from "../../routes/lms.routes";
import { ROUTE_MASTER_LIST } from "../../routes/masters.routes";
import {
  ROUTE_MANAGE_USERS,
  ROUTE_MANAGE_USER_GROUPS
} from "../../routes/user-management.routes";
import {
  ROUTE_MANAGE_COURSE_EXAMS, ROUTE_MANAGE_FILES,
  ROUTE_MANAGE_GALLERY, ROUTE_MANAGE_HOME_BANNERS,
  ROUTE_MANAGE_PRODUCTS, ROUTE_MANAGE_TAGS, ROUTE_MANAGE_TESTIMONIALS
} from "../../routes/website.routes";
import { RootState } from "../../types/common";

const MENU_ITEMS = [
  {
    label: "Dashboard",
    key: "dashboard",
    route: ROUTE_DASHBOARD,
    icon: (
      <span role="img" className="anticon">
        <RiDashboardLine />
      </span>
    ),
    children: [],
  },
  {
    label: "Users",
    key: "users",
    icon: <UserOutlined />,
    route: "",
    permission: [],
    children: [
      {
        label: "Manage Users",
        key: "manage-users",
        route: ROUTE_MANAGE_USERS,
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Roles",
        key: "roles",
        route: 'roles',
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Profiles",
        key: "profiles",
        route: ROUTE_MANAGE_USER_GROUPS,
        // icon: <IconComponent icon={InactiveIcon} />,
      },
    ],
  },
  {
    label: "Enquiry",
    key: "enquiry",
    icon: (
      <span role="img" className="anticon">
        <RiUserReceivedLine />
      </span>
    ),
    permission: ['counsellor'],
    // route: "",
    children: [
      {
        label: "Dashboard",
        key: "enq-dashboard",
        route: ROUTE_ENQUIRY_DASHBOARD,
        permission: ['counsellor'],
      },
      {
        label: "All Enquiries",
        key: "all-enquiries",
        route: ROUTE_ENQUIRY_LIST,
        permission: ['counsellor'],
      },
    ],
  },
  {
    label: "Masters",
    key: "masters",
    route: ROUTE_MASTER_LIST,
    icon: (
      <span role="img" className="anticon">
        <RiDatabase2Line />
      </span>
    ),
    permission: [],
    children: [],
  },
  {
    label: "Admissions",
    key: "admissions",
    route: 'admissions',
    icon: (
      <span role="img" className="anticon">
        <RiUserAddLine />
      </span>
    ),
    permission: ['counsellor', 'accounts'],
    children: [
      {
        label: "Manage Candidates",
        key: "manage-candidates",
        route: ROUTE_CANDIDATES_LIST,
        permission: ['counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Course Enrolments",
        key: "manage-enrolment",
        route: ROUTE_ENROLMENTS_LIST,
        permission: ['counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Course Instalments",
        key: "manage-instaments",
        route: ROUTE_INSTALMENTS_LIST,
        permission: ['counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Course Payments",
        key: "manage-payments",
        route: ROUTE_PAYMENTS_LIST,
        permission: ['counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Fee Closing Approval",
        key: "fee-closing-approval",
        route: ROUTE_FEE_CLOSING_APPROVAL,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
    ],
  },
  {
    label: "Accounts",
    key: "accounts",
    route: 'accounts',
    icon: (
      <span role="img" className="anticon">
        <BiRupee />
      </span>
    ),
    permission: ['accounts'],
    children: [
      {
        label: "Fee Clearance",
        key: "fee-clearance",
        route: ROUTE_FEE_CLEARANCE,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Refunds",
        key: "manage-refunds",
        route: ROUTE_REFUNDS_LIST,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Bank Deposit",
        key: "bank-deposit",
        route: ROUTE_BANK_DEPOSIT,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Daily Expenses",
        key: "daily-expenses",
        route: ROUTE_DAILY_EXPENSES,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Education Income (Tally)",
        key: "education-income-tally",
        route: ROUTE_EDUCATION_INCOME_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Education Debit (Tally)",
        key: "education-debit-tally",
        route: ROUTE_EDUCATION_DEBIT_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Publication Income (Tally)",
        key: "publication-income-tally",
        route: ROUTE_PUBLICATION_INCOME_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Publication Debit (Tally)",
        key: "publication-debit-tally",
        route: ROUTE_PUBLICATION_DEBIT_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
    ],
  },
  // {
  //   label: "HR",
  //   key: "hr",
  //   route: 'hr',
  //   icon: (
  //     <span role="img" className="anticon">
  //       <RiFileUserLine />
  //     </span>
  //   ),
  //   children: [],
  // },
  {
    label: "Website",
    key: "website",
    route: 'website',
    icon: (
      <span role="img" className="anticon">
        <CgWebsite />
      </span>
    ),
    permission: ['webmaster'],
    children: [
      {
        label: "File Manager",
        key: "file-manager",
        route: ROUTE_MANAGE_FILES,
        permission: ['webmaster'],
      },
      {
        label: "Banners",
        key: "banner",
        route: ROUTE_MANAGE_HOME_BANNERS,
        permission: ['webmaster'],
      },
      {
        label: "Products",
        key: "products",
        route: ROUTE_MANAGE_PRODUCTS,
        permission: ['webmaster'],
      },
      {
        label: "Course Category",
        key: "course-category",
        route: ROUTE_MANAGE_COURSE_EXAMS('course-category'),
        permission: ['webmaster'],
      },
      {
        label: "Courses",
        key: "courses-wb",
        route: ROUTE_MANAGE_COURSE_EXAMS('course'),
        permission: ['webmaster'],
      },
      {
        label: "Exam Category",
        key: "exam-cat",
        route: ROUTE_MANAGE_COURSE_EXAMS('exam-category'),
        permission: ['webmaster'],
      },
      {
        label: "Exams",
        key: "exams",
        route: ROUTE_MANAGE_COURSE_EXAMS('exam'),
        permission: ['webmaster'],
      },
      {
        label: "Tags",
        key: "tags",
        route: ROUTE_MANAGE_TAGS,
        permission: ['webmaster'],
      },
      {
        label: "Content Category",
        key: "content-category",
        route: ROUTE_MANAGE_COURSE_EXAMS('content-category'),
        permission: ['webmaster'],
      },
      {
        label: "Content Items",
        key: "content",
        route: ROUTE_MANAGE_COURSE_EXAMS('content-item'),
        permission: ['webmaster'],
      },
      {
        label: "Gallery",
        key: "gallery",
        route: ROUTE_MANAGE_GALLERY,
        permission: ['webmaster'],
      },
      {
        label: "Testimonials",
        key: "testimonials",
        route: ROUTE_MANAGE_TESTIMONIALS,
        permission: ['webmaster'],
      }
    ],
  },
];

const MenuComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  console.log(user)
  const role = user.user_role;
  return (
    <>
      <Menu
        onClick={(e: any) => {
          if (e.key !== "") {
            navigate(e.key);
          }
        }}
        defaultOpenKeys={[MENU_ITEMS[0].label]}
        mode="horizontal"
        theme="dark"
        itemIcon={true}
        expandIcon={<MenuOutlined />}
        overflowedIndicator={<MenuOutlined />}
      >
        {MENU_ITEMS.map((menuGroup: any) => {
          if ([...(menuGroup?.permission || []), 'admin'].indexOf(role) != -1) {
            if (menuGroup?.children?.length === 0) {
              return (
                <Menu.Item
                  key={menuGroup.route}
                  icon={menuGroup.icon}
                  title={menuGroup.label}
                >
                  {menuGroup.label}
                </Menu.Item>
              );
            } else {
              return (
                <SubMenu
                  key={menuGroup.label}
                  icon={menuGroup.icon}
                  title={menuGroup.label}
                >
                  {menuGroup.children &&
                    menuGroup.children.map((menuItem: any) => {
                      if ([...(menuItem?.permission || []), 'admin'].indexOf(role) != -1) {
                        return (
                          <Menu.Item icon={menuItem.icon} key={menuItem.route}>
                            {menuItem.label}
                          </Menu.Item>
                        );
                      }
                    })}
                </SubMenu>
              );
            }
          }
        })}
      </Menu>
      {/* </Sider> */}
    </>
  );
};

export default MenuComponent;
