import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Descriptions, Form, Input, notification, Radio, Row, Select, Table, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { realizeNextInstalment, requestInstalmentsByEnrol } from "../../../core/store/reducers/admission.slice";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";


export function NextInstalmentRealize({ instalmentData, closeModal }: any) {
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootState) => state.auth);
    const [paymentType, setPaymentType] = useState("full");
    const [paymentMode, setPaymentMode] = useState("Cash");
    const [paymentFor, setPaymentFor] = useState(1);
    const [amount, setAmount] = useState("");
    const [remark, setRemark] = useState("");
    const [addedBookFee, setAddedBookFee] = useState(0 as number);
    const [addedAdmFee, setAddedAdmFee] = useState(0 as number);
    const [paymentEntries, setPaymentEntries] = useState([] as any);
    const [allInstalments, setAllInstalments] = useState([] as any);
    const installmentColumns = [
        {
            title: "Installment No.",
            dataIndex: "installment_no",
            key: "installment_no",
        },
        {
            title: "Ins. Admission Amount",
            dataIndex: "admission_amount",
            key: "admission_amt",
        },
        {
            title: "Ins. Tuition Amount",
            dataIndex: "tuition_amount",
            key: "tuition_amt",
        },
        {
            title: "Ins. Book Amount",
            dataIndex: "books_amount",
            key: "book_amt",
            // className: "capitalize",
        },
        {
            title: "Ins. Amount",
            dataIndex: "net_instalment_amount",
            key: "net_amt",
            // className: "capitalize",
        },
        {
            title: "Paid",
            dataIndex: "realized",
            key: "net_amt",
            render: (a: any) => a ? 'Yes' : 'No'
            // className: "capitalize",
        },
        {
            title: "Installment Date",
            dataIndex: "prefered_instalment_date",
            key: "prefered_instalment_date",
            render: (key: any) => moment(key).format("Do-MMM-YYYY"),
        },
    ];

    useEffect(() => {
        if (instalmentData?.course_enrolment_id?._id) {
            dispatch(requestInstalmentsByEnrol(instalmentData?.course_enrolment_id?._id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setAllInstalments(val?.payload?.data?.data?.map((data: any, index: number) => {
                        return {
                            ...data, installment_no: index + 1
                        }
                    }))
                }
            })
        }
    }, [instalmentData])


    const candidateData = instalmentData?.candidate_id;
    const batchDetails = instalmentData?.course_enrolment_id?.batch_id;

    const feeDetails = { ...instalmentData?.course_enrolment_id, total_discount: instalmentData?.course_enrolment_id?.admission_fee_discount + instalmentData?.course_enrolment_id?.books_fee_discount + instalmentData?.course_enrolment_id?.tuition_discount + instalmentData?.course_enrolment_id?.instalment_discount }
    function addPayment() {
        let paymentEntries1 = [
            ...paymentEntries,
            {
                candidate_id: candidateData._id,
                batch_id: instalmentData.batch_id,
                batch_type_id: instalmentData.batch_type_id,
                center_id: instalmentData.center_id,
                course_id: instalmentData.course_id,
                exam_id: instalmentData.exam_id,
                session_id: instalmentData.session_id,
                stream_id: instalmentData.stream_id,
                instalment_id: instalmentData?._id,
                course_enrolment_id: instalmentData?.course_enrolment_id,
                sno: paymentEntries.length + 1,
                payment_mode: paymentMode,
                amount: amount,
                payment_type: paymentType,
                actual_payment_date: formatDate(moment()),
                edc_charges: 0,
                qr_charges: 0,
                total_amount: amount,
                in_favor_of: "",
                bank_name: "",
                branch_name: "",
                ref_no: "",
                is_book_fee: paymentFor == 2 ? true : false,
                created_by: user._id,
                modified_by: user._id,
            },
        ];
        paymentEntries1 = paymentEntries1.map((val: any, index: number) => {
            return { ...val, sno: index + 1 };
        });
        setPaymentEntries(paymentEntries1);
        setAmount("")
    }
    const payColumns = [
        {
            title: "S. No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: "Payment Mode",
            dataIndex: "payment_mode",
            key: "payment_mode",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Payment Type",
            dataIndex: "payment_type",
            key: "payment_type",
            className: "capitalize",
        },
        {
            title: "Cheque/DD/Pay Date",
            dataIndex: "actual_payment_date",
            key: "actual_payment_date",
        },
        {
            title: "EDC Charges",
            dataIndex: "edc_charges",
            key: "edc_charges",
        },
        {
            title: "QR Charges",
            dataIndex: "qr_charges",
            key: "qr_charges",
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
        },
        {
            title: "In Favor Of",
            dataIndex: "in_favor_of",
            key: "in_favor_of",
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "bank_name",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
            key: "branch_name",
        },
        {
            title: "Trans / DD / Cheque No",
            dataIndex: "ref_no",
            key: "ref_no",
        },
        {
            title: "Is Book Fee",
            dataIndex: "is_book_fee",
            key: "is_book_fee",
            render: (a: any) => {
                return a ? "Yes" : "No";
            },
        },
        {
            title: "Action",
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<DeleteOutlined />}
                                onClick={() => removePaymentEntry(a.sno)}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    function removePaymentEntry(sno: any) {
        setPaymentEntries(
            paymentEntries
                .filter((val: any) => {
                    return val.sno != sno;
                })
                .map((val: any, index: number) => {
                    return { ...val, sno: index + 1 };
                })
        );
    }
    useEffect(() => {
        let bookFee = 0;
        let admFee = 0;
        paymentEntries.forEach((val: any) => {
            if (val.is_book_fee) {
                bookFee += parseFloat(val.total_amount);
            } else {
                admFee += parseFloat(val.total_amount);
            }
        });
        setAddedAdmFee(admFee);
        setAddedBookFee(bookFee);
    }, [paymentEntries]);

    const submitInstamentRealization = () => {
        let payload = paymentEntries?.map((val: any) => {
            return { ...val, remarks: remark }
        })

        dispatch(realizeNextInstalment({ id: instalmentData?._id, payload })).then((val: any) => {
            if (val?.payload?.data?.success) {
                notification.success({ message: "Instalment realized successfully" });
                closeModal();
            }
        })
    }
    return (<>
        <Row gutter={10}>
            <Col span={24}>
                <Descriptions title="Student Registration Details" className="table-description">
                    <Descriptions.Item label="Registration Number">{candidateData?.registration_number}</Descriptions.Item>
                    <Descriptions.Item label="Roll No">xxxxxxxxxx</Descriptions.Item>
                    <Descriptions.Item label="Candidate Name">{candidateData?.candidate_name}</Descriptions.Item>
                    <Descriptions.Item label="Date of Birth">{candidateData?.dob}</Descriptions.Item>
                    <Descriptions.Item label="Gender" className="capitalize">{candidateData?.gender}</Descriptions.Item>
                    <Descriptions.Item label="Category">{candidateData?.category}</Descriptions.Item>
                    <Descriptions.Item label="Session">{batchDetails?.session_id?.session}</Descriptions.Item>
                    <Descriptions.Item label="Stream">{batchDetails?.stream_id?.stream_name}</Descriptions.Item>
                    <Descriptions.Item label="Is Handicapped">{candidateData?.handicapped ? "Yes" : "No"}</Descriptions.Item>
                    <Descriptions.Item label="Batch Details" span={24}>
                        {batchDetails?.batch_code_id?.batch_code} (Total : {batchDetails?.total_seat} {" "}
                        Online : {batchDetails?.online_seat} {" "}
                        Online Filled : 0 {" "}
                        Online Remaining : 0 {" "}
                        Offline: {batchDetails?.offline_seat_fs} {" "}
                        Offline Filled : {batchDetails?.offline_seat_ss} {" "}
                        Offline Remaining : 0)
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            {/* <Col span={4} className={"mt-9"}>
                <img
                    width={200}
                    src={candidateData.photo}
                />
            </Col> */}
        </Row>
        {/* <Row>
            <Col span={24}>
                <CourseCard feeData={[]}
                    setPaymentModal={() => { }}
                    candidateData={[]}
                    courseData={[]}></CourseCard>
            </Col>
        </Row> */}
        <Descriptions title="Course Fee Details" className="table-description">
            <Descriptions.Item label="Total No of Installments">
                {feeDetails?.n_instalments}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Info">
                Instalment
            </Descriptions.Item>
            <Descriptions.Item label="Installment charges">
                {feeDetails?.instalment_discount && feeDetails?.instalment_discount != 0 ? <><span className="line-through">Rs. {feeDetails.instalment_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.instalment_fee - feeDetails.instalment_discount)}</> : <>Rs. {feeDetails.instalment_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Total Discount" span={3}>
                {
                    "Rs. " + (feeDetails.total_discount)
                }
            </Descriptions.Item>
            <Descriptions.Item label="Old Student Discount">
                {
                    "Rs. " + (feeDetails.old_student_discount)
                }
            </Descriptions.Item>
            <Descriptions.Item label="Handicapped Discount">
                NA
            </Descriptions.Item>
            <Descriptions.Item label="Special Discount"> {feeDetails?.tuition_discount ? 'Rs. ' + feeDetails?.tuition_discount : 'NA'} </Descriptions.Item>
            <Descriptions.Item label="Total Fee" span={3}>
                {" "}
                {/* Rs {feeDetails?.total_fee} */}
                {feeDetails?.total_discount && feeDetails?.total_discount != 0 ? <><span className="line-through">Rs. {feeDetails.total_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.total_fee - feeDetails.total_discount)}</> : <>Rs. {feeDetails.total_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Admission fee">
                {" "}
                {/* Rs {feeDetails?.admission_fee} */}
                {feeDetails?.admission_fee_discount && feeDetails?.admission_fee_discount != 0 ? <><span className="line-through">Rs. {feeDetails.admission_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.admission_fee - feeDetails.admission_fee_discount)}</> : <>Rs. {feeDetails.admission_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Tuition Fee">
                {" "}
                {feeDetails?.tuition_discount && feeDetails?.tuition_discount != 0 ? <><span className="line-through">Rs. {feeDetails.tuition_discount}</span>&nbsp; &nbsp;Rs. {(feeDetails.tution_fee - feeDetails.tuition_discount)}</> : <>Rs. {feeDetails.tution_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Book fee">
                {feeDetails?.books_fee_discount && feeDetails?.books_fee_discount != 0 ? <><span className="line-through">Rs. {feeDetails.book_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.book_fee - feeDetails.books_fee_discount)}</> : <>Rs. {feeDetails.book_fee}</>}
            </Descriptions.Item>
        </Descriptions>

        <Col span={24} className={'mt-3'}>
            <Table
                loading={false}
                dataSource={allInstalments}
                columns={installmentColumns}
                size="small"
                pagination={false}
            />
        </Col>



        <Descriptions
            title="Fee Collection Payment Details"
            style={{ marginTop: "20px" }}
        >
            <Descriptions.Item label="Payment Mode">
                <Radio.Group
                    value={paymentMode}
                    onChange={(evt) => setPaymentMode(evt.target.value)}
                >
                    <Radio value={"Cash"}>Cash</Radio>
                    <Radio value={"EDC"} disabled>
                        EDC
                    </Radio>
                    <Radio value={"DD"} disabled>
                        DD
                    </Radio>
                    <Radio value={"Cheque"} disabled>
                        Cheque
                    </Radio>
                    <Radio value={"QR"} disabled>
                        QR Code
                    </Radio>
                </Radio.Group>
            </Descriptions.Item>
            <Descriptions.Item label="Fee Submission for">
                <Radio.Group
                    value={paymentFor}
                    onChange={(evt) => setPaymentFor(evt.target.value)}
                >
                    <Radio value={1}>
                        Adm & Tuition
                    </Radio>
                    <Radio value={2}>
                        Book
                    </Radio>
                </Radio.Group>
            </Descriptions.Item>
        </Descriptions>

        {console.log(instalmentData)}
        {
            paymentMode === "Cash" ? <Descriptions title="Cash" style={{ marginTop: "20px" }}>
                <Descriptions.Item>
                    <Input
                        value={amount}
                        onChange={(evt: any) => setAmount(evt.target.value)}
                        placeholder={"Enter Amount"}
                    ></Input>
                </Descriptions.Item>
                <Descriptions.Item>
                    <Button onClick={() => addPayment()}>Add Payment</Button>
                </Descriptions.Item>
            </Descriptions> : paymentMode === "EDC" ? <Form>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Amount"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Transaction No"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Charge Category"}>
                            <Select style={{ width: "100%" }}>
                                <Select.Option value={"credit"}>Credit</Select.Option>
                                <Select.Option value={"debit"}>Debit</Select.Option>
                                <Select.Option value={"manual"}>Manual</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"EDC Charges"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Amount Total"}>
                            <label>0</label>
                        </Form.Item>
                    </Col>
                    <Col span={8} className={"text-right mt-5"}>
                        <Button type="default" htmlType="submit">Add Payment</Button>
                    </Col>
                </Row>
            </Form> : paymentMode === "DD" ? <Form>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Amount"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"DD Date"}>
                            <DatePicker style={{ width: "100%" }}></DatePicker>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"In Favor of"}>
                            <Select style={{ width: "100%" }}>
                                <Select.Option value={"credit"}>Credit</Select.Option>
                                <Select.Option value={"debit"}>Debit</Select.Option>
                                <Select.Option value={"manual"}>Manual</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"DD No"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Bank Name"}>
                            <Select style={{ width: "100%" }}>
                                <Select.Option value={"credit"}>Credit</Select.Option>
                                <Select.Option value={"debit"}>Debit</Select.Option>
                                <Select.Option value={"manual"}>Manual</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Branch"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={16} className={"text-right mt-5"}>
                        <Button type="default" htmlType="submit">Add Payment</Button>
                    </Col>
                </Row>
            </Form> : paymentMode === "Cheque" ? <Form>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Amount"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Cheque Date "}>
                            <DatePicker style={{ width: "100%" }}></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Cheque No"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Bank Name"}>
                            <Select style={{ width: "100%" }}>
                                <Select.Option value={"credit"}>Credit</Select.Option>
                                <Select.Option value={"debit"}>Debit</Select.Option>
                                <Select.Option value={"manual"}>Manual</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Branch"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8} className={"text-right mt-5"}>
                        <Button type="default" htmlType="submit">Add Payment</Button>
                    </Col>
                </Row>
            </Form> : <Form>
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Amount"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Cheque Date "}>
                            <DatePicker style={{ width: "100%" }}></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Cheque No"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Bank Name"}>
                            <Select style={{ width: "100%" }}>
                                <Select.Option value={"credit"}>Credit</Select.Option>
                                <Select.Option value={"debit"}>Debit</Select.Option>
                                <Select.Option value={"manual"}>Manual</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={"Amount"} label={"Branch"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8} className={"text-right mt-5"}>
                        <Button type="default" htmlType="submit">Add Payment</Button>
                    </Col>
                </Row>
            </Form>
        }



        {/* </>} */}
        <Table
            loading={false}
            dataSource={paymentEntries}
            columns={payColumns}
            size="small"
            pagination={false}
        />
        <p></p>
        <Descriptions title="Instalment Details">
            <Descriptions.Item label="Admission & Tuition Amount">

                Rs. {addedAdmFee}/Rs.{" "}
                {instalmentData.admission_amount +
                    instalmentData.tuition_amount}

            </Descriptions.Item>
            <Descriptions.Item label="Book Amount">
                Rs. {addedBookFee}/Rs.{" "}
                {instalmentData.books_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount">


                Rs. {addedAdmFee + addedBookFee}/Rs.{" "}
                {instalmentData.admission_amount +
                    instalmentData.tuition_amount + instalmentData.books_amount}

            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Remarks" style={{ marginTop: "20px" }}>
            <Descriptions.Item>
                <TextArea
                    value={remark}
                    onChange={(evt: any) => setRemark(evt.target.value)}
                    placeholder={"Enter Fee Remark"}
                ></TextArea>
            </Descriptions.Item>
        </Descriptions>

        <Button
            type="primary"
            disabled={addedAdmFee + addedBookFee != (instalmentData.admission_amount +
                instalmentData.tuition_amount + instalmentData.books_amount)}
            onClick={() => submitInstamentRealization()}
        >
            Submit
        </Button>


    </>
    )
}