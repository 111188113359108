import {
  ArrowRightOutlined, CloseOutlined, DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Tag, Tooltip } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt, BiTransfer } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteEnquiryById,
  getEnquiryList,
  setFilters
} from "../../../core/store/reducers/lead-management.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT, courses } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import { getTagColor, showNotification } from "../../../shared/utils/utils";
import CreateEditEnquiry from "./components/create-edit-enquiry.component";
import TransferEnquiry from "./components/transfer-enquiry.component";
import BulkUploadEnquiry from "./components/upload-enquiry.component";

const ManageEnquiriesPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // List states
  const { loadingEnquiries, enquiryList, filters, total } = useSelector(
    (state: RootState) => state.leadManagement
  );
  const { screenType } = useSelector((state: RootState) => state.settings);
  const { user } = useSelector((state: RootState) => state.auth);

  // List local state
  const [enquiryListData, setEnquiryListData] = useState([] as any);

  // Flag to open create/edit form
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [bulkUploadFBModalOpen, setBulkUploadFBModalOpen] = useState(false);
  const [transferEnquiry, setTransferEnquiry] = useState(false);
  const [createEditOpen, setCreateEditOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null as any);
  const [filterVisible, setFilterVisible] = useState(false);

  // Actions and selections
  const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
  const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

  // Functions and Constants
  const loadList = useCallback(
    (_page: any = 1) => {
      const data = {
        ...pagination,
        ...sorter,
      };
      dispatch(getEnquiryList({ params: data, body: filters }));
    },
    [filters, dispatch, sorter, pagination]
  );

  const createEntityListner = () => {
    setCreateEditOpen(true);
    setSelectedEntity(null);
  };

  // useEffect Hooks
  useEffect(() => {
    loadList();
  }, [loadList]);

  //filters, loadList
  useEffect(() => {
    setEnquiryListData(
      enquiryList.map((obj: any, index: number) => {
        return { key: index, ...obj };
      })
    );
  }, [enquiryList]);

  const onFilterApply = (values: any) => {
    let arrFilter = [];
    for (let [key, value] of Object.entries(values)) {
      if (value !== "") {
        arrFilter.push({ key: key, value: value });
      }
    }
    let refinedArray = arrFilter.filter((data) => {
      return data.value !== undefined;
    });
    let obj = {} as any;
    refinedArray.forEach((data: any) => {
      obj[data.key] = data.value;
    });
    setPagination({
      page: 1,
      limit: pagination.limit,
    });
    dispatch(setFilters(obj)); //filters
  };
  const onPageChange = (pageNumber: number) => {
    setPagination({
      page: pageNumber,
      limit: pagination.limit,
    });
  };

  const openAddEditPanel = (entity: any) => {
    setSelectedEntity(entity);
    setCreateEditOpen(true);
  };

  const deleteEnquiry = (entityId: any) => {
    dispatch(deleteEnquiryById(entityId)).then((val: any) => {
      if (val?.payload?.data?.success) {
        showNotification("Enquiry Deleted Successfully", "success");
        loadList()
      }
    })
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "user_name",
      sorter: true,
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "user_email",
      sorter: true,
      key: "user_email",
    },
    {
      title: "Mobile",
      dataIndex: "user_number",
      sorter: true,
      key: "user_number",
    },
    {
      title: "COURSE",
      dataIndex: ["course", "course_name"],
      sorter: true,
      key: "course",
      className: "capitalize",
    },
    {
      title: "CENTRE",
      dataIndex: ["centre", "center_name"],
      sorter: true,
      key: "centre",
      className: "capitalize",
    },
    ...((user?.user_role === "admin" || user?.user_role === "lms-admin" || user?.user_role === "center-head") ? [{
      title: "Counsellor",
      sorter: true,
      key: "current_counsellor_name",
      render: (a: any) => {
        return a.current_counsellor?.name;
      },
    }] : []),
    {
      title: "Follow-Up Date",
      dataIndex: "next_follow_up_date",
      sorter: true,
      key: "next_follow_up_date",
      render: (a: any) => {
        return a ? moment(a).format("MMM DD, Y LT") : "";
      },
    },
    {
      title: "Rating",
      dataIndex: "rating",
      sorter: true,
      key: "rating",
      render: (text: any) => (
        <>
          {text ? <Tag color={getTagColor(text, "rating")}>{text}</Tag> : <></>}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "lead_status",
      sorter: true,
      key: "lead_status",
    },
    {
      title: "Source",
      dataIndex: "lead_source",
      sorter: true,
      key: "lead_source",
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      sorter: true,
      key: "created_at",
      render: (a: any) => {
        return a ? moment(a).format("MMM DD, Y LT") : "";
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      sorter: true,
      key: "updated_at",
      render: (a: any) => {
        return a ? moment(a).format("MMM DD, Y LT") : "";
      },
    },
    {
      title: "Actions",
      width: "160px",
      fixed: "right",
      render: (a: any) => {
        return (
          <>
            {(user?.user_role !== "counsellor" || a?.current_counsellor?._id === user?._id) && <><Tooltip title={"Edit"}>
              <Button
                type="link"
                className="table-button"
                icon={<EditOutlined />}
                onClick={() => openAddEditPanel(a)}
              ></Button>
            </Tooltip>
              <Tooltip title={"Transfer Enquiry"}>
                <Button
                  onClick={() => openTransferEnquiry(a)}
                  type="link"
                  className="table-button"
                  icon={<BiTransfer />}
                ></Button>
              </Tooltip></>}
            <Tooltip title={"Details"}>
              <Button
                onClick={() => navigate("/enquiry-details/" + a._id)}
                type="link"
                className="table-button"
                icon={<ArrowRightOutlined />}
              ></Button>
            </Tooltip>
            {(user?.user_role === "admin" || user?.user_role === "lms-admin" || user?.user_role === "center-head") && (
              <Tooltip title={"Delete"}>
                <Button
                  onClick={() => deleteEnquiry(a._id)}
                  type="link"
                  className="table-button"
                  icon={<CloseOutlined />}
                ></Button>
              </Tooltip>
            )}
          </>
        );
      },
      key: "actions",
    },
  ];

  const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
    if (_sorter.order === undefined) {
      setSorter({ sort_by: "", sort_type: "" });
    } else {
      let order = "";
      let columnKey = "";
      if (_sorter.order === "ascend") order = "asc";
      else order = "desc";

      if (_sorter.columnKey === "enabled") {
        columnKey = "status";
      } else if (_sorter.columnKey === "displayName") {
        columnKey = "name";
      } else {
        columnKey = _sorter.columnKey;
      }
      setSorter({ sort_by: columnKey, sort_type: order });
    }
  };

  const formProps = {
    name: "enquiry-filters",
    enableEnterSubmit: true,
    formElements: [
      {
        name: "user_name",
        label: "",
        placeholder: "Name Search",
        type: "Input",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      },
      {
        name: "user_email",
        label: "",
        placeholder: "Search Email ID",
        type: "Input",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      },
      {
        name: "user_number",
        label: "",
        placeholder: "Search Phone Number",
        type: "InputNumber",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      },
      {
        name: "centre",
        label: "",
        placeholder: "Search Centre",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
        type: "Select",
        colSpan: 12,
        showSearch: true,
        allowClear: true,
        options: [
          { value: "Vanik CRP", label: "Vanik CRP" },
          { value: "Vanik Acharya Vihar", label: "Vanik Acharya Vihar" },
          { value: "Vanik Saheed Nagar", label: "Vanik Saheed Nagar" },
          { value: "Vanik Cuttack", label: "Vanik Cuttack" },
          { value: "Vanik Berhampur", label: "Vanik Berhampur" },
          { value: "Vanik Kolkata", label: "Vanik Kolkata" },
          { value: "Vanik Sambalpur", label: "Vanik Sambalpur" },
          { value: "Vanik Rourkela", label: "Vanik Rourkela" },
          { value: "Vanik Balasore", label: "Vanik Balasore" },
        ],
      },
      {
        name: "course",
        label: "",
        placeholder: "Search Course",
        type: "Select",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
        showSearch: true,
        allowClear: true,
        options: courses?.map((course: string) => {
          return { value: course, label: course };
        }),
      },
      {
        name: "lead_status",
        label: "",
        placeholder: "Search Lead Status",
        type: "Select",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
        showSearch: true,
        allowClear: true,
        options: [
          { value: "New", label: "New" },
          { value: "Contacted", label: "Contacted" },
          { value: "Converted", label: "Converted" },
          { value: "Not Contactable", label: "Not Contactable" },
          { value: "Not Interested", label: "Not Interested" },
        ],
      },
      {
        name: "rating",
        label: "",
        placeholder: "Search Rating",
        type: "Select",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
        showSearch: true,
        allowClear: true,
        options: [
          { value: "Hot", label: "Hot" },
          { value: "Warm", label: "Warm" },
          { value: "Cold", label: "Cold" },
        ],
      },
      {
        name: "current_counsellor",
        label: "",
        placeholder: "Search Counsellor",
        type: "Select",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
        showSearch: true,
        allowClear: true,
        options: [],
      },
    ],
    initialValues: {
      name: "",
      userId: "",
      userGroups: "",
      email: "",
      status: "",
      phoneNumber: "",
      credentialSource: "",
    },
    extraButtons: [
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Clear",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Apply",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFilterApply,
    onFinishFailed: null,
    onValuesChange: null,
  };

  const createEditSubmit = () => {
    setCreateEditOpen(false);
    loadList();
  };
  const bulkUploadSuccess = () => {
    setBulkUploadModalOpen(false);
    loadList();
  };
  const openTransferEnquiry = (enquiry: any) => {
    setTransferEnquiry(true);
    setSelectedEntity(enquiry);
  };
  const closeTransferEnquiry = () => {
    setTransferEnquiry(false);
    setSelectedEntity(null);
    loadList();
  };
  const headerButtons = (
    <Space>
      <Button
        type="primary"
        // size="large"
        icon={<PlusOutlined />}
        onClick={createEntityListner}
      >
        <span className="font-size-14">New {!screenType?.isPortrait && "Enquiry"}</span>
      </Button>
      {/* <Button
        type="primary"
        // size="large"
        icon={<UploadOutlined />}
        onClick={() => setBulkUploadFBModalOpen(true)}
      >
        <span className="font-size-14">Upload FB Leads</span>
      </Button> */}
      {(user?.user_role === "admin" || user?.user_role === "lms-admin" || user?.user_role === "center-head") && (
        <>
          <Button
            type="primary"
            // size="large"
            icon={<UploadOutlined />}
            onClick={() => setBulkUploadModalOpen(true)}
          >
            {!screenType?.isPortrait && <span className="font-size-14">Upload Leads</span>}
          </Button>
          <Button
            icon={<DownloadOutlined />}
            href="assets/templates/enquiry_template_v1.0.csv"
          >
            {!screenType?.isPortrait && <span className="font-size-14">Download Template</span>}
          </Button>
        </>
      )}
      <Button
        shape="circle"
        type="primary"
        icon={
          <span role="img" className="anticon">
            <BiFilterAlt />
          </span>
        }
        onClick={() => {
          setFilterVisible(!filterVisible);
        }}
      ></Button>
    </Space>
  );
  return (
    <>
      <div
        className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
        style={{ borderBottom: "none!important" }}
      >
        <Row>
          <Col span={24}>
            <ContentHeaderComponent
              title="Manage Enquiries"
              subtitle=""
              buttons={headerButtons}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Tabs className="table-status-tabs mx-3">
              <Tabs.TabPane tab="New" key="new"></Tabs.TabPane>
              <Tabs.TabPane tab="Follow-Up" key="follow-up"></Tabs.TabPane>
              <Tabs.TabPane tab="Active" key="active"></Tabs.TabPane>
              <Tabs.TabPane tab="Invalid" key="invalid"></Tabs.TabPane>
              <Tabs.TabPane tab="Converted" key="converted"></Tabs.TabPane>
            </Tabs>
          </Col>
        </Row> */}
        {filterVisible && (
          <Row className="bg-body">
            <Col span={24} className="py-1">
              <div className="px-3 table-filter-form">
                <GenericFormComponent {...formProps} />
              </div>
            </Col>
          </Row>
        )}
      </div>

      <DataTableComponent
        loading={loadingEnquiries}
        dataSource={enquiryListData}
        columns={columns}
        onChange={onChangeHandler}
        title={"Enquiry List"}
        defaultPage={0}
        currentPage={pagination.page}
        totalRows={total}
        onPageChange={onPageChange}
        maxWidth="2200px"
      />
      {createEditOpen && (
        <CreateEditEnquiry
          onClose={() => setCreateEditOpen(false)}
          visible={createEditOpen}
          selectedEnquiry={selectedEntity}
          onSubmit={createEditSubmit}
        />
      )}

      <Modal
        title={<strong>Bulk Upload Enquiries</strong>}
        width={1024}
        footer={false}
        onCancel={() => setBulkUploadModalOpen(false)}
        open={bulkUploadModalOpen}
      >
        <BulkUploadEnquiry
          onSubmit={bulkUploadSuccess}
          type="generic"
        ></BulkUploadEnquiry>
      </Modal>
      <Modal
        title={<strong>Bulk Upload Facebook Enquiries</strong>}
        width={1024}
        footer={false}
        onCancel={() => setBulkUploadFBModalOpen(false)}
        open={bulkUploadFBModalOpen}
      >
        <BulkUploadEnquiry
          onSubmit={bulkUploadSuccess}
          type="facebook"
        ></BulkUploadEnquiry>
      </Modal>
      {transferEnquiry && (
        <Modal
          title={<strong>Transfer Enquiry</strong>}
          footer={false}
          onCancel={() => setTransferEnquiry(false)}
          open={transferEnquiry}
        >
          <TransferEnquiry
            onSubmit={closeTransferEnquiry}
            selectedEnquiry={selectedEntity}
          ></TransferEnquiry>
        </Modal>
      )}
    </>
  );
};
export default ManageEnquiriesPage;
