import { notification } from "antd";
import { API_BASE_URL } from "../../shared/constants";
//MOCK_API_BASE_URL as
const displayError = async (data: any, shouldHide: any) => {
  let message = "Something went wrong";
  if (data?.errors?.errorDetails?.length > 0) {
    const error = data?.errors?.errorDetails[0];
    message = error.message;
  }
  if (data?.message) {
    message = data.message;
  }
  !shouldHide &&
    notification.error({
      message: message,
    });
};

export async function Client(
  endpoint: string,
  { method, body, ...customConfig } = {} as any
) {
  // const language = localStorage?.getItem("lang") || "en-US";
  const headers: any = {
    "Content-Type": "application/json",
    // Locale: language,
    // Channel: "Web",
  };
  if (localStorage.getItem("accessToken")) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;
  }
  const config = {
    method: method, //body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    redirect: "follow",
  };
  if (body) {
    if (config.headers["Content-Type"] !== "application/json") {
      config.body = new URLSearchParams(Object.entries(body)).toString();
    } else {
      config.body = JSON.stringify(body);
    }
  }
  let data;
  try {
    const response = await window.fetch(API_BASE_URL + endpoint, config);
    return processResponse(response, customConfig);
  } catch (err: any) {
    displayError(
      { message: "Something went wrong", code: "ERR001" },
      customConfig?.shouldHide
    );
    return Promise.reject(err.message ? err.message : data);
  }
}

const processResponse = async (response: any, customConfig: any) => {
  let data = await response.json();
  if (response.ok) {
    if (response.status === 204) {
      return {
        status: response.status,
        headers: response.headers,
        url: response.url,
      };
    }
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  } else if (response.status === 401) {
    window.location.href = "/login";
    localStorage.clear();
  }
  displayError(data, customConfig?.shouldHide);
  return Promise.reject(data);
};

Client.get = function (endpoint: string, customConfig: any = {}) {
  if (customConfig.params) {
    const params = createQS(customConfig);
    return Client(endpoint + "?" + params.join("&"), {
      ...customConfig,
      method: "GET",
    });
  } else {
    delete customConfig.params;
    return Client(endpoint, { ...customConfig, method: "GET" });
  }
};
Client.post = function (endpoint: string, body: any, customConfig: any = {}) {
  if (customConfig.params) {
    const params = createQS(customConfig);
    endpoint = endpoint + "?" + params.join("&");
  }
  delete customConfig.params;
  return Client(endpoint, { ...customConfig, body, method: "POST" });
};
Client.put = function (endpoint: string, body: any, customConfig = {}) {
  return Client(endpoint, { ...customConfig, body, method: "PUT" });
};
Client.patch = function (endpoint: string, body: any, customConfig = {}) {
  return Client(endpoint, { ...customConfig, body, method: "PATCH" });
};
Client.delete = function (endpoint: string, customConfig = {}) {
  return Client(endpoint, { ...customConfig, method: "DELETE" });
};
const createQS = (customConfig: any) => {
  let params: any = [];
  for (let p in customConfig.params) {
    let key = p;
    if (Array.isArray(customConfig.params[p])) {
      customConfig.params[p].forEach((element: any) => {
        params.push(`${key}=${encodeURIComponent(element)}`);
      });
    } else {
      params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
    }
  }
  return params;
};
