import { Button, Col, DatePicker, Drawer, Form, Image, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CKEditor } from 'ckeditor4-react';
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { genericWebsiteCreate, genericWebsiteUpdate, getGenericListMinimal } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
import DragTabs from "./drag-tabs";
import IconCardTabs from "./icon-card-tabs";

declare var CKFinder: any;
const Option = Select;
const CreateEditComponent = ({ onClose, visible, onSubmit, selectedEntity, moduleData }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [bannerUrl, setBannerUrl] = useState(selectedEntity ? selectedEntity?.banner_url : '' as string)
    const [iconUrl, setIconUrl] = useState(selectedEntity ? selectedEntity?.icon_url : '' as string)
    const [courseCategoryList, setCourseCategoryList] = useState([] as any)
    const [examCategoryList, setExamCategoryList] = useState([] as any)
    const [examList, setExamList] = useState([] as any)
    const [productList, setProductList] = useState([] as any)
    const [contentCategoryList, setContentCategoryList] = useState([] as any)
    const [tagsList, setTagsList] = useState([] as any)
    const [tabsStructure, setTabsStructure] = useState(selectedEntity?.tabs as any)
    const [infoCardsStructure, setInfoCardsStructure] = useState(selectedEntity?.info_cards as any)
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        dispatch(getGenericListMinimal({ module: 'tags', params: { keys: 'tag_name' } })).then((val: any) => {
            console.log(val?.payload?.data?.data)
            if (val?.payload?.data?.data) {
                setTagsList(val?.payload?.data?.data?.map((val: any) => {
                    return {
                        value: val._id, label: val.tag_name
                    }
                }))
            }
        });
        if (moduleData?.key === 'course-category' || moduleData?.key === 'exam') {

            dispatch(getGenericListMinimal({ module: 'exam-category', params: { keys: 'exam_category_name' } })).then((val: any) => {
                console.log(val?.payload?.data?.data)
                if (val?.payload?.data?.data) {
                    setExamCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.exam_category_name
                        }
                    }))
                }
            });
        }
        if (moduleData?.key === 'content-item') {

            dispatch(getGenericListMinimal({ module: 'content-category', params: { keys: 'content_category_name' } })).then((val: any) => {
                console.log(val?.payload?.data?.data)
                if (val?.payload?.data?.data) {
                    setContentCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.content_category_name
                        }
                    }))
                }
            });
        }
        if (moduleData?.key === 'course') {

            dispatch(getGenericListMinimal({ module: 'course-category', params: { keys: 'course_category_name' } })).then((val: any) => {
                console.log(val?.payload?.data?.data)
                if (val?.payload?.data?.data) {
                    setCourseCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.course_category_name
                        }
                    }))
                }
            });
            dispatch(getGenericListMinimal({ module: 'exam', params: { keys: 'exam_name' } })).then((val: any) => {
                console.log(val?.payload?.data?.data)
                if (val?.payload?.data?.data) {
                    setExamList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.exam_name
                        }
                    }))
                }
            });
            dispatch(getGenericListMinimal({ module: 'products', params: { keys: 'product_name' } })).then((val: any) => {
                console.log(val?.payload?.data?.data)
                if (val?.payload?.data?.data) {
                    setProductList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.product_name
                        }
                    }))
                }
            });

        }
    }, [moduleData])
    const onFinish = (values: any) => {

        if (selectedEntity) {
            const payload = {
                ...values,
                tabs: tabsStructure,
                info_cards: infoCardsStructure,
                _id: selectedEntity._id,
                updated_by: user._id,
            };
            dispatch(genericWebsiteUpdate({ payload: payload, module: moduleData.key })).then((val: any) => {
                if (val?.payload?.data) {
                    showNotification(moduleData.label + " Updated Successfully", "success");
                    onSubmit();
                }
            });
        } else {
            const payload = {
                ...values,
                tabs: tabsStructure,
                info_cards: infoCardsStructure,
                created_by: user._id,
                updated_by: user._id,
            }
            console.log(payload);
            dispatch(genericWebsiteCreate({ payload: payload, module: moduleData.key })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification(moduleData.label + " Saved Successfully", "success");
                    onSubmit();
                }
            })
        }
    }
    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    const openPopup = (ref: string, setData: any) => {
        CKFinder.popup({
            chooseFiles: true,
            onInit: (finder: any) => {
                finder.on('files:choose', function (evt: any) {
                    var file = evt.data.files.first();
                    form.setFieldValue(ref, file.getUrl())
                    setData(file.getUrl())
                });
                finder.on('file:choose:resizedImage', function (evt: any) {
                    form.setFieldValue(ref, evt.data.resizedUrl())
                    setData(evt.data.resizedUrl())
                });
            }
        });
    }
    return <>
        <Drawer
            maskClosable={false}
            title={"Manage " + moduleData?.label}
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : '80%'}
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={{ ...selectedEntity, ...(selectedEntity?.date_publish ? { date_publish: moment(selectedEntity?.date_publish) } : {}) }}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
            >
                <Row >
                    <Col span={12} className="gutter-row pr-2">
                        <Form.Item name="seo_url" label="SEO URL">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row pl-2">
                        <Form.Item name={moduleData.name_key} label={moduleData?.label + " Name"}>
                            <Input onChange={(e) => {
                                let data = e.target.value;
                                data = data.replace(/[^a-zA-Z0-9 -]/g, '').split("  ").join(" ").split(" ").join("-").toLowerCase()
                                form.setFieldValue("seo_url", data);
                            }} />
                        </Form.Item>
                    </Col>
                    {moduleData.key === 'content-item' &&
                        <Col span={24} className="gutter-row">
                            <Form.Item name="content_item_sub_title" label="Content Sub Title">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    }
                    {moduleData.key !== 'content-category' && <Col span={12} className="gutter-row">
                        {bannerUrl != '' && <Image src={bannerUrl} />}
                        <Form.Item name="banner_url" label="Banner">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('banner_url', setBannerUrl)}>
                            Select/Upload Image
                        </Button>
                    </Col>}
                    {moduleData.key === 'content-category' && <Col span={12} className="gutter-row">
                        <Form.Item name="display_position" label="Type" rules={[{ required: true, message: "Required" }]}>
                            <Select>
                                <Option value={'result'}>Results</Option>
                                <Option value={'notification'}>Notifications</Option>
                                <Option value={'free-downloads'}>Free Downloads</Option>
                            </Select>
                        </Form.Item>
                    </Col>}
                    <Col span={12} className="gutter-row pl-4">
                        {iconUrl != '' && <Image height="150px" src={iconUrl} />}
                        <Form.Item name="icon_url" label="Icon">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('icon_url', setIconUrl)}>
                            Select/Upload Image
                        </Button>
                    </Col>
                    {moduleData.key !== 'content-category' && <Col span={24} className="gutter-row">
                        <Form.Item name="content">
                            <Input disabled hidden />
                        </Form.Item>
                        <CKEditor
                            name={'content'}
                            config={{
                                // extraPlugins: 'ckfinder',
                                filebrowserBrowseUrl: 'http://panel.vanikonline.in/ckfinder/ckfinder.html',
                                filebrowserUploadUrl: 'http://panel.vanikonline.in/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files'
                            }}
                            initData={selectedEntity ? selectedEntity?.content : <p>Add content</p>}
                            onChange={(event) => {
                                console.log(event.editor.getData());
                                form.setFieldValue('content', event.editor.getData())
                            }}
                        />
                    </Col>}
                    {(moduleData.key === 'course-category' || moduleData.key === 'exam') &&
                        <Col span={12} className="gutter-row">
                            <Form.Item name="exam_category_ids" label="Exam Categories">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {examCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {moduleData.key === 'course' && <>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="course_category_ids" label="Course Categories">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {courseCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="exam_ids" label="Exams">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {examList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="product_ids" label="Products">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {productList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col></>
                    }
                    {moduleData.key === 'content-item' &&
                        <Col span={12} className="gutter-row">
                            <Form.Item name="content_category_id" label="Content Categories">
                                <Select showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {contentCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {moduleData.key !== 'content-category' && <Col span={12} className="gutter-row">
                        <Form.Item name="tags" label="Tags">
                            <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tagsList?.map((option: any, seli: number) => {
                                    return (
                                        <Select.Option
                                            key={seli}
                                            value={option.value}
                                        // className={"capitalize"}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {moduleData.key === 'content-item' &&
                        <Col span={12} className="gutter-row">
                            <Form.Item name="date_publish" label="Publishing Start Date">
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    }
                    {moduleData.key !== 'content-category' && moduleData.key !== 'content-item' && <Col span={24} className="gutter-row">
                        <DragTabs tabsStructure={tabsStructure} setTabsStructure={setTabsStructure}></DragTabs>
                    </Col>}

                    {moduleData.key !== 'content-category' && moduleData.key !== 'content-item' && <Col span={24} className="gutter-row">
                        <IconCardTabs infoCardsStructure={infoCardsStructure} setInfoCardsStructure={setInfoCardsStructure}></IconCardTabs>
                    </Col>}
                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer>
    </>
}

export default CreateEditComponent;