import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Space,
  Table,
  Upload,
  UploadProps
} from "antd";
import { useForm } from "antd/es/form/Form";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postBulkEnquiry } from "../../../../core/store/reducers/lead-management.slice";
import {
  getCenterList,
  getCourseList
} from "../../../../core/store/reducers/masters.slice";
import { RootState } from "../../../../shared/types/common";
import { showNotification } from "../../../../shared/utils/utils";
const { Dragger } = Upload;

export const genericUploadKeys = [
  "user_name",
  "user_email",
  "user_number",
  "centre",
  "course",
  "course_type",
  "college",
  "qualification",
  "address",
  "batch_timing",
  "rating",
  "lead_status",
  "lead_source",
  "lead_type",
  "action_taken",
  "remarks",
];

export const genericFileColumns = [
  "CANDIDATE NAME",
  "EMAIL",
  "PHONE",
  "CENTRE",
  "COURSE",
  "COURSE TYPE",
  "COLLEGE",
  "QUALIFICATION",
  "ADDRESS",
  "BATCH TIMING",
  "RATING",
  "LEAD STATUS",
  "LEAD SOURCE",
  "LEAD TYPE",
  "ACTION TAKEN",
  "REMARKS",
];
interface Props {
  type?: string;
  onSubmit?: () => void;
}
const BulkUploadEnquiry = ({
  type,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const [uploadForm] = useForm();
  const { user } = useSelector((state: RootState) => state.auth);
  const [csvFile, setCsvFile] = useState(null as any);
  const [payload, setPayload] = useState([] as any);
  const [records, setRecords] = useState([] as any);
  const [recordFields, setRecordFields] = useState([] as any);
  const [processing, setProcessing] = useState(false);
  const [courses, setCourses] = useState({} as any);
  const [coursesIdKey, setCoursesIdKey] = useState({} as any);
  const [coursesLabelKey, setCoursesLabelKey] = useState({} as any);
  const [centers, setCenters] = useState({} as any);
  const [centersIdKey, setCentersIdKey] = useState({} as any);
  const [centersLabelKey, setCentersLabelKey] = useState({} as any);
  const [errorTemplate, setErrorTemplate] = useState(false as any);
  useEffect(() => {
    dispatch(getCenterList()).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        let centers = val.payload.data.data;
        let centerId = {} as any,
          centerVal = {} as any;
        centers.forEach((c: any) => {
          centerId[c._id] = c.center_name;
          centerVal[c.center_name] = c._id;
        });
        setCentersIdKey(centerId);
        setCentersLabelKey(centerVal);
        setCenters(
          centers.map((val: any) => {
            return { label: val.center_name, value: val._id };
          })
        );
      }
    });
    dispatch(getCourseList()).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        let courses = val.payload.data.data;
        let courseId = {} as any,
          courseVal = {} as any;
        courses.forEach((c: any) => {
          courseId[c._id] = c.course_name;
          courseVal[c.course_name] = c._id;
        });
        setCoursesIdKey(courseId);
        setCoursesLabelKey(courseVal);
        setCourses(
          courses.map((val: any) => {
            return { label: val.course_name, value: val._id };
          })
        );
      }
    });
  }, []);
  const props: UploadProps = {
    name: "file",
    beforeUpload: (file) => {
      setProcessing(true);
      setCsvFile(file);
      return false;
    },
    onRemove: (file) => {
      setCsvFile(null as any);
    },
  };
  useEffect(() => {
    if (csvFile) {
      setErrorTemplate(false);
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results: any) => {
          if (results?.data?.length > 0 && results?.meta?.fields?.length > 0) {
            if (results.data?.length > 500) {
              message.error("Number of records should not exceed 500");
            } else {
              processRecords(results.data, results.meta.fields);
            }
          } else {
            message.error("Invalid CSV file");
          }
          uploadForm.resetFields();
        },
      });
    }
  }, [csvFile]);
  useEffect(() => {
    if (records.length > 0 && recordFields?.length > 0) {
      console.log(records);
      console.log(recordFields);
    }
  }, [records, recordFields]);
  const processRecords = (records: any, columns: any) => {
    let row = {} as any;
    let payload = [];
    if (type === "generic") {
      if (columns.length !== genericFileColumns.length) {
        message.error("Invalid CSV file");
      }
      columns.forEach((val: string) => {
        let idx = genericFileColumns.indexOf(val);
        if (idx === -1) {
          message.error("Invalid CSV file");
          return;
        }
      });

      for (let rowIdx = 0; rowIdx < records.length; ++rowIdx) {
        row = {} as any;
        columns.forEach((val: string) => {
          let idx = genericFileColumns.indexOf(val);
          row[genericUploadKeys[idx]] = records[rowIdx][columns[idx]];
          if (genericUploadKeys[idx] === "centre") {
            if (
              Object.keys(centersLabelKey).indexOf(
                row[genericUploadKeys[idx]]
              ) !== -1
            ) {
              row[genericUploadKeys[idx]] =
                centersLabelKey[row[genericUploadKeys[idx]]];
              row.centre_name = records[rowIdx][columns[idx]];
            } else {
              row[genericUploadKeys[idx]] = (
                <span className="text-danger">
                  {records[rowIdx][columns[idx]]}
                </span>
              );
              setErrorTemplate(true);
            }
          }
          if (genericUploadKeys[idx] === "course") {
            if (
              Object.keys(coursesLabelKey).indexOf(
                row[genericUploadKeys[idx]]
              ) !== -1
            ) {
              row[genericUploadKeys[idx]] =
                coursesLabelKey[row[genericUploadKeys[idx]]];
              row.course_name = records[rowIdx][columns[idx]];
            } else {
              row[genericUploadKeys[idx]] = (
                <span className="text-danger">
                  {records[rowIdx][columns[idx]]}
                </span>
              );
              setErrorTemplate(true);
            }
          }
        });
        payload.push(row);
      }
    }
    if (type === "facebook") {
    }

    setRecords(records);
    setRecordFields(
      columns.map((val: string) => {
        let idx = genericFileColumns.indexOf(val);
        return {
          title: val,
          dataIndex:
            genericUploadKeys[idx] === "course"
              ? "course_name"
              : genericUploadKeys[idx] === "centre"
                ? "centre_name"
                : genericUploadKeys[idx],
          key: genericUploadKeys[idx],
        };
      })
    );
    setPayload(payload);
    setProcessing(false);
  };
  const onFinish = () => {
    setProcessing(true);
    const payloadData = payload.map((val: any) => {
      delete val.centre_name;
      delete val.course_name;
      return { ...val, created_by: user._id, updated_by: user._id };
    });
    dispatch(postBulkEnquiry({ enquiry_data: payloadData })).then(
      (val: any) => {
        if (val?.payload?.data) {
          showNotification("Enquiries Uploaded Successfully", "success");
          onSubmit();
        }
        setProcessing(false);
      }
    );
  };
  const resetAll = () => {
    uploadForm.resetFields();
    setProcessing(false);
    setCsvFile(null);
    setPayload([]);
    setRecords([]);
    setRecordFields([]);
    setRecordFields([]);
  };
  console.log(payload);
  console.log(recordFields);
  return (
    <>
      {/* {processing && <Spin></Spin>} */}
      {records?.length === 0 && (
        <Form form={uploadForm}>
          <Row justify="center">
            <Col span={6}>
              <Form.Item>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag csv file this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>{" "}
        </Form>
      )}
      {records?.length > 0 && (
        <>
          <Row className="mb-2">
            <Col>
              <Space>
                <Button type="link" onClick={resetAll}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={onFinish}
                  loading={processing}
                  disabled={errorTemplate}
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
          {errorTemplate && (
            <Row className="mb-2">
              <Col>
                <span className="text-danger">
                  There are some errors in template as highlighted in red.
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Table
                loading={false}
                dataSource={payload}
                columns={recordFields}
                scroll={{ x: "auto" }}
              ></Table>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BulkUploadEnquiry;
