import { createSlice } from "@reduxjs/toolkit";

const config = {
  name: "settings",
};

export const settingsReducer = createSlice({
  name: config.name,
  initialState: {
    screenType: null,
    activeRoute: {},
  },
  reducers: {
    setScreenType: (state, action) => {
      state.screenType = action.payload;
    },
    setActiveRoute: (state, action) => {
      state.activeRoute = { ...action.payload };
    },
  },
});

export const { setActiveRoute, setScreenType } = settingsReducer.actions;
export default settingsReducer.reducer;
