import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PublicAdmission from "../../modules/admission/public-admission";
import MainLayout from "../../shared/layouts/main.layout";
import { ROUTE_NEW_ADMISSION } from "../../shared/routes/admission.routes";
import { ROUTE_LOGIN } from "../../shared/routes/auth.routes";
import { ROUTE_DASHBOARD } from "../../shared/routes/dashboard.routes";

import {
  ROUTES_OPEN,
  ROUTES_PROTECTED
} from "../../shared/routes/renderer.route";
import { RootState } from "../../shared/types/common";
import { setActiveRoute } from "../store/reducers/settings.slice";

function RouteGuard() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const routeIndex = ROUTES_PROTECTED.findIndex((route) => {
      let routeParam = route.route.split("/")[1];
      let currentPath = location.pathname.split("/")[1];
      return routeParam === currentPath;
    });
    dispatch(setActiveRoute(ROUTES_PROTECTED[routeIndex]));
  }, [location, dispatch]);

  return accessToken ? (
    <React.Fragment>
      <Routes>
        {ROUTES_PROTECTED.map((route: any, index: number) => {
          return (
            <Route
              path={route.route}
              key={index}
              element={
                ['/print-receipt/:id'].indexOf(route.route) === -1 ?
                  <MainLayout>{route.component}</MainLayout>
                  : <>{route.component}</>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to={ROUTE_DASHBOARD} replace />} />
      </Routes>
    </React.Fragment>
  ) : (
    <Routes>
      {ROUTES_OPEN.map((route: any, index: number) => {
        return (
          <Route path={route.route} key={index} element={route.component} />
        );
      })}
      <Route path="*" element={<Navigate to={ROUTE_LOGIN} replace />} />
      <Route path={ROUTE_NEW_ADMISSION} element={<PublicAdmission />} />
    </Routes>
  );
}

export default RouteGuard;
