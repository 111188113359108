import { Col, Descriptions, Divider, notification, Row, Steps } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { requestCandidateRegistration } from "../../../core/store/reducers/admission.slice";
import { getFeeStructure } from "../../../core/store/reducers/masters.slice";
import CandidateCard from "../candidates/candidate-card";
import CandidateCardNoBatch from "../candidates/candidate-card-no-batch";
import CourseCard from "../candidates/course-card";
import CourseCardCreate from "../candidates/course-card-create";
import AdmissionForm from "../new-admission/index";

const PublicAdmission: React.FC = () => {
    const [paymentModal, setPaymentModal] = useState(false);
    const [current, setCurrent] = useState(0 as number);
    const [candidateData, setCandidateData] = useState([]) as any;
    const [courseData, setCourseData] = useState([]);
    const [feeData, setFeeData] = useState([]);
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const [data, setData] = useState([]) as any;
    const getPaymentDetails = (values: any) => {
        dispatch(getFeeStructure({ ...values })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setFeeData(val?.payload?.data?.data);
                setCourseData(values);
                setStep(3);
            }
        });
    };

    const submitDetails = async (_data: any) => {
        dispatch(requestCandidateRegistration({ ...data, ..._data })).then(
            (val: any) => {
                if (val?.payload?.data?.success) {
                    setStep(2);
                    notification.success({
                        message:
                            "Candidate registered succesfully. You can mark this lead as converted now.",
                    });
                }
            }
        );
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    return (
        <Row justify="center">
            <Col lg={20} xl={16} md={22} sm={23} xs={24} className={"padding20 bg-white"}>
                <h2>Online Registration</h2>
                <Divider></Divider>
                <Steps
                    size={"default"}
                    current={step - 1}
                    style={{ marginBottom: "40px" }}
                    items={[
                        {
                            title: "Candidate Details",
                        },
                        {
                            title: "Course Details",
                        },
                        {
                            title: "Payment Details",
                        },
                    ]}
                />

                {step === 1 ? (
                    <AdmissionForm
                        setCandidateData={(values: any) => setCandidateData(values)}
                        onClose={() => setStep(2)}
                        enquiryDetails={candidateData}
                        id={candidateData?._id}
                        show={false}
                    ></AdmissionForm>
                ) : step === 2 ? (
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={24}>
                            <CandidateCardNoBatch
                                candidateData={candidateData}
                            ></CandidateCardNoBatch>
                        </Col>
                        <Divider></Divider>
                        <Descriptions title="Course Details"></Descriptions>
                        <Col span={24}>
                            <CourseCardCreate
                                candidateData={candidateData}
                                setCourseData={(data: any) => getPaymentDetails(data)}
                            ></CourseCardCreate>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={24}>
                            <CandidateCard
                                candidateData={candidateData}
                                courseData={courseData}
                            ></CandidateCard>
                        </Col>
                        <Divider></Divider>

                        <Col span={24}>
                            <CourseCard
                                candidateData={candidateData}
                                courseData={courseData}
                                feeData={feeData}
                                setPaymentModal={() => setPaymentModal(false)}
                                publicPage={true}
                            ></CourseCard>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};
export default PublicAdmission;
