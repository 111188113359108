import moment from "moment";

const CommonSchema: any = (schema_name: string) => [
  {
    title: "URL",
    dataIndex: "seo_url",
    sorter: true,
    key: "seo_url",
  },
  {
    title: "Name",
    dataIndex: schema_name,
    sorter: true,
    key: schema_name,
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "status",
    dataIndex: "status",
    sorter: true,
    key: "status",
    render: (a: any) => {
      return a ? "Active" : "Inactive";
    },
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];
const CommonFilterForm: any = (schema_name: string, schema_label: string) => [
  {
    name: schema_name,
    label: "",
    placeholder: schema_label + " Name",
    type: "Input",
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];

export const allModules = [
  {
    key: "course-category",
    name_key: "course_category_name",
    label: "Course Category",
    schema: CommonSchema("course_category_name"),
    filterForm: CommonFilterForm("course_category", "Course Category"),
    tableWidth: "1000px",
  },
  {
    key: "course",
    name_key: "course_name",
    label: "Courses",
    schema: CommonSchema("course_name"),
    filterForm: CommonFilterForm("course_name", "Course"),
    tableWidth: "1000px",
  },
  {
    key: "exam-category",
    name_key: "exam_category_name",
    label: "Exam Category",
    schema: CommonSchema("exam_category_name"),
    filterForm: CommonFilterForm("exam_category_name", "Exam Category"),
    tableWidth: "1000px",
  },
  {
    key: "exam",
    name_key: "exam_name",
    label: "Exams",
    schema: CommonSchema("exam_name"),
    filterForm: CommonFilterForm("exam_name", "Exams"),
    tableWidth: "1000px",
  },
  {
    key: "content-category",
    name_key: "content_category_name",
    label: "Content Category",
    schema: CommonSchema("content_category_name"),
    filterForm: CommonFilterForm("content_category_name", "Content Category"),
    tableWidth: "1000px",
  },
  {
    key: "content-item",
    name_key: "content_item_title",
    label: "Content Item",
    schema: CommonSchema("content_item_title"),
    filterForm: CommonFilterForm("content_item_title", "Content Item"),
    tableWidth: "1000px",
  },
];

export const modulesKeys = allModules.map((module: any) => {
  return module.key;
});
