import { Tabs } from "antd";
const { TabPane } = Tabs;
type Props = {
  allTabs: Array<{ title: any; component: any }>;
  onChange?: (values: string) => void;
  defaultActiveKey?: string;
};
const TabGroupComponent: React.FC<Props> = ({
  allTabs,
  onChange,
  defaultActiveKey = "2",
}: Props) => {
  return (
    <div>
      <Tabs defaultActiveKey={defaultActiveKey} onChange={onChange}>
        {allTabs.map((tab: any, index: number) => {
          return (
            <TabPane tab={tab.title} key={index + 1}>
              {tab.component}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabGroupComponent;
