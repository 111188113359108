import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCounsellorList,
  updateEnquiryById,
} from "../../../../core/store/reducers/lead-management.slice";
import GenericFormComponent, {
  GenericFormProps,
} from "../../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../../shared/types/common";
import { showNotification } from "../../../../shared/utils/utils";

interface Props {
  selectedEnquiry?: any;
  onSubmit?: () => void;
}
const TransferEnquiry = ({
  selectedEnquiry = null,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [counsellorList, setCounsellorList] = useState([] as any);
  useEffect(() => {
    dispatch(
      getCounsellorList({
        current_counsellor: selectedEnquiry.current_counsellor._id,
      })
    ).then((val: any) => {
      if (val?.payload?.data?.success && val?.payload?.data?.data?.length > 0) {
        setCounsellorList(
          val.payload.data.data.map((val: any) => {
            return { label: `${val.name}`, value: val._id };
          })
        );
      }
    });
  }, []);
  const onFinish = (values: any) => {
    if (selectedEnquiry) {
      const payload = {
        ...values,
        _id: selectedEnquiry._id,
        updated_by: user._id,
        enquiry_history: [
          ...selectedEnquiry.enquiry_history,
          {
            ...values,
            old_counsellor: selectedEnquiry.current_counsellor._id,
            created_by: user._id,
            created_at: moment().format(),
          },
        ],
      };
      dispatch(updateEnquiryById(payload)).then((val: any) => {
        if (val?.payload?.data) {
          showNotification("Enquiry Updated Successfully", "success");
          onSubmit();
        }
      });
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };

  const formProps: GenericFormProps = {
    name: "transfer-enquiry",
    initialValues: {
      // current_counsellor: selectedEnquiry?.current_counsellor,
    },
    formElements: [
      {
        name: "current_counsellor",
        label: "Select Counsellor",
        placeholder: "Select Counsellor",
        type: "Select",
        colSpan: 12,
        options: counsellorList,
        rules: [
          {
            required: true,
            message: "Counsellor is required",
          },
        ],
      },
    ],
    extraButtons: [
      {
        htmlType: "submit",
        colSpan: 5,
        loading: false,
        submitButtonText: "Update",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
  };
  return (
    <>{counsellorList?.length > 0 && <GenericFormComponent {...formProps} />}</>
  );
};

export default TransferEnquiry;
