import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Tag, Tooltip } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEnquiryDashboardData } from "../../../core/store/reducers/lead-management.slice";
import TabGroupComponent from "../../../shared/components/tab-group/tab-group.component";
import { RootState } from "../../../shared/types/common";
import { getTagColor } from "../../../shared/utils/utils";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, ChartDataLabels);

const CounsellorDashboardPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const [dashboardData, setDashboardData] = useState({} as any);
  useEffect(() => {
    dispatch(getEnquiryDashboardData()).then((val: any) => {
      if (val?.payload?.data?.success && val?.payload?.data?.data) {
        let hotCount = getCountBy_id(
          val?.payload?.data?.data?.rating_counts,
          "Hot"
        );
        let warmCount = getCountBy_id(
          val?.payload?.data?.data?.rating_counts,
          "Warm"
        );
        let coldCount = getCountBy_id(
          val?.payload?.data?.data?.rating_counts,
          "Cold"
        );
        let statusNames: any = [];
        let statusCounts: any = [];
        val?.payload?.data?.data?.status_counts.forEach((statusData: any) => {
          statusNames.push(statusData._id);
          statusCounts.push(statusData.count);
        });
        let counsellorNames: any = [];
        let counsellorCounts: any = [];
        val?.payload?.data?.data?.counsellor_counts?.forEach((cData: any) => {
          counsellorNames.push(cData.counsellor_name);
          counsellorCounts.push(cData.count);
        });

        setDashboardData({
          ...val.payload.data.data,
          status_names: statusNames,
          status_counts: statusCounts,
          counsellor_names: counsellorNames,
          counsellor_counts: counsellorCounts,
          hot_count: hotCount,
          rating_counts: [hotCount, warmCount, coldCount],
        });
      }
    });
  }, []);
  const getCountBy_id = (obj: any, key: string) => {
    let filteredData = obj?.filter((val: any) => {
      return val._id === key;
    });
    return filteredData?.length > 0 ? filteredData[0]["count"] : 0;
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Comparison",
      },
      datalabels: {
        display: true,
        color: 'black',
        font: {
          size: 14
        }
      }
    },

  };
  const labelsCoun = dashboardData.counsellor_names;
  const dataCoun = {
    labels: labelsCoun,
    datasets: [
      {
        label: "Counts",
        data: dashboardData?.counsellor_counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(30, 150, 200, 0.5)",
        ],
      },
    ],
  };
  const labelsRating = ["Hot", "Warm", "Cold"];
  const dataRating = {
    labels: labelsRating,
    datasets: [
      {
        label: "Counts",
        data: dashboardData?.rating_counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(30, 150, 200, 0.5)",
        ],
      },
    ],
  };
  const labelsStatus = dashboardData.status_names;
  const dataStatus = {
    labels: labelsStatus,
    datasets: [
      {
        label: "Counts",
        data: dashboardData?.status_counts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.5)",
          "rgba(30, 150, 200, 0.5)",
        ],
      },
    ],
  };
  const followUpColumns: any = [
    {
      title: "Name",
      dataIndex: "user_name",
      sorter: true,
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "user_email",
      sorter: true,
      key: "user_email",
    },
    {
      title: "Mobile",
      dataIndex: "user_number",
      sorter: true,
      key: "user_number",
    },
    {
      title: "COURSE",
      dataIndex: ["course", "course_name"],
      sorter: true,
      key: "course",
      className: "capitalize",
    },
    {
      title: "CENTRE",
      dataIndex: ["centre", "center_name"],
      sorter: true,
      key: "centre",
      className: "capitalize",
    },
    {
      title: "Counsellor",
      sorter: true,
      key: "current_counsellor_name",
      render: (a: any) => {
        return a.current_counsellor?.name;
      },
    },
    {
      title: "Rating",
      dataIndex: "rating",
      sorter: true,
      key: "rating",
      render: (text: any) => (
        <>
          {text ? <Tag color={getTagColor(text, "rating")}>{text}</Tag> : <></>}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "lead_status",
      sorter: true,
      key: "lead_status",
    },
    {
      title: "Actions",
      width: "50px",
      fixed: "right",
      render: (a: any) => {
        return (
          <>
            <Tooltip title={"Details"}>
              <Button
                onClick={() => navigate("/enquiry-details/" + a._id)}
                type="link"
                className="table-button"
                icon={<ArrowRightOutlined />}
              ></Button>
            </Tooltip>
          </>
        );
      },
      key: "actions",
    },
  ];
  return (
    <>
      <Row className="mt-4 mx-4">
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3901/3901662.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.lead_count}</h1>
                <p className="mb-0 weight-700">LEADS</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2621/2621098.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.conversions}</h1>
                <p className="mb-0 weight-700">CONVERSIONS</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3275/3275168.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.hot_count}</h1>
                <p className="mb-0 weight-700">HOT LEADS</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={6}>
          <div className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-2">
            <Row align="middle" className="mb-2">
              <Col span={6}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/3893/3893198.png"
                  className="img-fluid p-2"
                />
              </Col>
              <Col span={18} className="pl-3">
                <h1 className="m-0">{dashboardData.follow_up_count}</h1>
                <p className="mb-0 weight-700">FOLLOW-UP DUE</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mx-4 mt-2">
        {/* <Col span={12} className="p-2">
          <div
            className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
            style={{ height: "400px", paddingBottom: "70px" }}
          >
            <h3>Follow-Ups (Due and Upcoming)</h3>
          </div>
        </Col> */}
        {dashboardData && user?.user_role !== "admin" && user?.user_role !== "lms-admin" && user?.user_role !== "center-head" && (
          <Col span={12}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 px-4 pb-4 mb-2"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <TabGroupComponent
                defaultActiveKey="1"
                allTabs={[
                  {
                    title: "Today's Follow-Ups",
                    component: (
                      <Table
                        size="small"
                        loading={false}
                        dataSource={dashboardData.follow_up_list_today}
                        columns={followUpColumns}
                        scroll={{ x: "1000px" }}
                        tableLayout="auto"
                      />
                    ),
                  },
                  {
                    title: "Overdue Follow-Ups",
                    component: (
                      <Table
                        size="small"
                        loading={false}
                        dataSource={dashboardData.follow_up_list_follow_up_due}
                        columns={followUpColumns}
                        scroll={{ x: "1000px" }}
                        tableLayout="auto"
                      />
                    ),
                  },
                ]}
              ></TabGroupComponent>
            </div>
          </Col>
        )}
        {dashboardData && (user?.user_role === "admin" || user?.user_role === "lms-admin" || user?.user_role === "center-head") && (
          <Col span={12}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <h3>Enquiry Counts (by Counsellors)</h3>
              <Bar options={options} data={dataCoun} />
            </div>
          </Col>
        )}
        {dashboardData && (
          <Col span={6}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <h3>Enquiry (by Rating)</h3>
              <Bar options={options} data={dataRating} />
            </div>
          </Col>
        )}
        {dashboardData && (
          <Col span={6}>
            <div
              className="mx-1 bg-primary-white-blueish border-primary-gray-02 p-4"
              style={{ height: "400px", paddingBottom: "70px" }}
            >
              <h3>Enquiry (by Status)</h3>
              <Bar options={options} data={dataStatus} />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
export default CounsellorDashboardPage;
