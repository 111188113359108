export const CenterCityFilterForm = [
  {
    name: "city_code",
    label: "",
    placeholder: "City Code Search",
    type: "Input",
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    name: "city_name",
    label: "",
    placeholder: "City Name Search",
    type: "Input",
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    name: "state",
    label: "",
    placeholder: "State Search",
    type: "Input",
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];

export const CenterFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "city_name",
    schemaKey: "center-city",
    name: "center_city_ref",
    label: "City Name",
    placeholder: "City Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "center",
    name: "center_ref",
    label: "Center Name",
    placeholder: "Center Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const CourseFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "course_name",
    schemaKey: "course",
    name: "_id",
    label: "Course Name",
    placeholder: "Course Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const BatchTypeFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_type_name",
    schemaKey: "batch-type",
    name: "_id",
    label: "Batch Name",
    placeholder: "Batch Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const ExamFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "exam_name",
    schemaKey: "exam",
    name: "_id",
    label: "Exam Name",
    placeholder: "Exam Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const StreamFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "stream_name",
    schemaKey: "stream",
    name: "_id",
    label: "Stream Name",
    placeholder: "Stream Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const SessionFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "session_name",
    schemaKey: "session",
    name: "session_ref",
    label: "Session Name",
    placeholder: "Session Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const BatchCodeFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_code",
    schemaKey: "batch-code",
    name: "batch_ref",
    label: "Batch Code Name",
    placeholder: "Batch Code Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const BatchTimingFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "batch_time_name",
    schemaKey: "batch-timings",
    name: "batch_time_ref",
    label: "Batch Time Name",
    placeholder: "Batch Time Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const VenueFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "venue_name",
    schemaKey: "venue",
    name: "venue_ref",
    label: "Venue Name",
    placeholder: "Venue Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const CompanyFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "state",
    schemaKey: "company",
    name: "company_ref",
    label: "State Name",
    placeholder: "State Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "company_name",
    schemaKey: "company",
    name: "company_ref",
    label: "Company Name",
    placeholder: "Company Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const BankFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "bank_name",
    schemaKey: "bank",
    name: "bank_ref",
    label: "Bank Name",
    placeholder: "Bank Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const BankBranchFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "bank_name",
    schemaKey: "bank_branch",
    name: "bank_branch_ref",
    label: "Bank Branch Name",
    placeholder: "Bank Branch Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "branch_name",
    schemaKey: "bank_branch",
    name: "bank_branch_ref",
    label: "Branch Name",
    placeholder: "Branch Name Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const ReceiptStaticDataFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "session",
    schemaKey: "receipt_static_data",
    name: "receipt_static_data_ref",
    label: "Session",
    placeholder: "Session Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "receipt_static_data",
    name: "receipt_static_data_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "course_name",
    schemaKey: "receipt_static_data",
    name: "receipt_static_data_ref",
    label: "Course Name",
    placeholder: "Course Name",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const BankDepositDataFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "bank_deposit_data",
    name: "bank_deposit_data_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "company_name",
    schemaKey: "bank_deposit_data",
    name: "bank_deposit_data_ref",
    label: "Comapny Name",
    placeholder: "Comapny Name",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "account_no",
    schemaKey: "bank_deposit_data",
    name: "bank_deposit_data_ref",
    label: "Account Number",
    placeholder: "Account Number",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
export const ICardStaticDataFilterForm = [
  {
    api: true,
    valKey: "_id",
    labelKey: "center_name",
    schemaKey: "i_card_static_data",
    name: "i_card_static_data_ref",
    label: "Center Name",
    placeholder: "Center Name",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
  {
    api: true,
    valKey: "_id",
    labelKey: "session",
    schemaKey: "i_card_static_data",
    name: "i_card_static_data_ref",
    label: "Session",
    placeholder: "Session Search",
    type: "Select",
    options: [],
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];
