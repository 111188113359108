import moment from "moment";

export const CenterCitySchema = [
  {
    key: "sort",
  },
  {
    title: "City Code",
    dataIndex: "city_code",
    sorter: true,
    key: "city_code",
  },
  {
    title: "City Name",
    dataIndex: "city_name",
    className: "capitalize",
    sorter: true,
    key: "city_name",
  },
  {
    title: "State",
    dataIndex: "state",
    className: "capitalize",
    sorter: true,
    key: "state",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const CenterSchema = [
  {
    key: "sort",
  },
  {
    title: "Center Code",
    dataIndex: "center_code",
    key: "center_code",
  },
  {
    title: "Center Name",
    dataIndex: "center_name",
    key: "center_name",
  },
  {
    title: "Center City",
    dataIndex: ["center_city_ref", "city_name"],
    className: "capitalize",
    key: "center_city",
  },
  {
    title: "Center Address",
    dataIndex: "center_address",
    key: "center_address",
  },
  {
    title: "Contact Person",
    dataIndex: "center_person",
    key: "center_person",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Mobile Number",
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: "Email ID",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "SS Facility",
    dataIndex: "ss_facility",
    render: (a: any) => {
      return a ? "Yes" : "No";
    },
    key: "ss_facility",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const BatchTypeSchema = [
  {
    key: "sort",
  },
  {
    title: "Batch Code",
    dataIndex: "batch_type_code",
    sorter: true,
    key: "batch_type_code",
  },
  {
    title: "Batch Name",
    dataIndex: "batch_type_name",
    className: "capitalize",
    sorter: true,
    key: "batch_type_name",
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
    key: "description",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const CourseSchema = [
  {
    key: "sort",
  },

  {
    title: "Course Code",
    dataIndex: "course_code",
    sorter: true,
    key: "course_code",
  },
  {
    title: "Course Name",
    dataIndex: "course_name",
    sorter: true,
    key: "course_name",
  },
  {
    title: "Course Details",
    dataIndex: "course_details",
    sorter: true,
    key: "course_details",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const ExamSchema = [
  {
    key: "sort",
  },
  {
    title: "Exam Code",
    dataIndex: "exam_code",
    sorter: true,
    key: "exam_code",
  },
  {
    title: "Exam Name",
    dataIndex: "exam_name",
    sorter: true,
    key: "exam_name",
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
    key: "description",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];
export const StreamSchema = [
  {
    key: "sort",
  },
  {
    title: "Stream Code",
    dataIndex: "stream_code",
    sorter: true,
    key: "stream_code",
  },
  {
    title: "Stream Name",
    dataIndex: "stream_name",
    sorter: true,
    key: "stream_name",
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
    key: "description",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const SessionSchema = [
  {
    key: "sort",
  },
  {
    title: "Session",
    dataIndex: "session",
    sorter: true,
    key: "session",
  },
  {
    title: "Session Name",
    dataIndex: "session",
    sorter: true,
    key: "session_name",
    render: (a: any) => {
      const data = a.split("-");
      return "Apr " + data[0] + "-Mar " + data[1];
    },
  },
  {
    title: "Start Date",
    dataIndex: "session",
    sorter: true,
    key: "start_date",
    render: (a: any) => {
      const data = a.split("-");
      return "Apr 01, " + data[0];
    },
  },
  {
    title: "End Date",
    dataIndex: "session",
    sorter: true,
    key: "end_date",
    render: (a: any) => {
      const data = a.split("-");
      return "Mar 31, " + data[1];
    },
  },
  {
    title: "Reg Number Prefix",
    dataIndex: "reg_number_prefix",
    sorter: true,
    key: "reg_number_prefix",
  },
  {
    title: "Reg Number Suffix",
    dataIndex: "reg_number_suffix",
    sorter: true,
    key: "reg_number_suffix",
  },
  {
    title: "SS_Receipt_Prefix",
    dataIndex: "ss_receipt_p",
    sorter: true,
    key: "ss_receipt_p",
  },
  {
    title: "SS_Receipt_Suffix",
    dataIndex: "ss_receipt_s",
    sorter: true,
    key: "ss_receipt_s",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const BatchCodeSchema = [
  {
    key: "sort",
  },
  {
    title: "Batch Code",
    dataIndex: "batch_code",
    sorter: true,
    key: "batch_code",
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
    key: "description",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const BatchTimingSchema = [
  {
    key: "sort",
  },
  {
    title: "Batch Time",
    key: "batch_time",
    sorter: true,
    render: (a: any) => {
      return a?.batch_time_from + "-" + a?.batch_time_to
    }
  },
  {
    title: "Batch Time Description",
    dataIndex: "batch_time_description",
    sorter: true,
    key: "batch_time_description",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const VenueSchema = [
  {
    key: "sort",
  },
  {
    title: "Venue Code",
    dataIndex: "venue_code",
    sorter: true,
    key: "venue_code",
  },
  {
    title: "Venue Name",
    dataIndex: "venue_name",
    sorter: true,
    key: "venue_name",
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
    key: "description",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];

export const CompanySchema = [
  {
    key: "sort",
  },
  {
    title: "Company Code",
    dataIndex: "company_code",
    sorter: true,
    key: "company_code",
  },
  {
    title: "Company Name",
    dataIndex: "company_name",
    sorter: true,
    key: "company_name",
  },
  {
    title: "GSTIN",
    dataIndex: "gstin",
    sorter: true,
    key: "gstin",
  },
  {
    title: "HSN And SAC",
    dataIndex: "hsn_and_sac",
    sorter: true,
    key: "hsn_and_sac",
  },
  {
    title: "State",
    dataIndex: "state",
    sorter: true,
    key: "state",
  },
  {
    title: "Description",
    dataIndex: "description",
    sorter: true,
    key: "description",
  },
  {
    title: "Service Tax Number",
    dataIndex: "service_tax_number",
    sorter: true,
    key: "service_tax_number",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];
export const BankSchema = [
  {
    key: "sort",
  },
  {
    title: "Bank Code",
    dataIndex: "bank_code",
    sorter: true,
    key: "bank_code",
  },
  {
    title: "Bank Name",
    dataIndex: "bank_name",
    sorter: true,
    key: "bank_name",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];
export const ReceiptStaticDataSchema = [
  {
    key: "sort",
  },
  {
    title: "Session Name",
    dataIndex: "session_name",
    sorter: true,
    key: "session_name",
  },
  {
    title: "Center Name",
    dataIndex: "center_name",
    sorter: true,
    key: "center_name",
  },
  {
    title: "Course Name",
    dataIndex: "course_name",
    sorter: true,
    key: "course_name",
  },
  {
    title: "Service Tax Number",
    dataIndex: "service_tax_number",
    sorter: true,
    key: "service_tax_number",
  },
  {
    title: "Note Data",
    dataIndex: "note_data",
    sorter: true,
    key: "note_data",
  },
  {
    title: "Terms and Condition",
    dataIndex: "terms and condition",
    sorter: true,
    key: "terms and condition",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: any) => {
      return text === true ? "Active" : "Inactive";
    },
    sorter: true,
    key: "status",
  },
  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];


export const CourseRelationshipSchema = [{
  title: 'Center name',
  dataIndex: ['center_ref', 'center_name'],
  key: 'name',
},
{
  title: 'Course',
  dataIndex: ['course_ref', 'course_name'],
  key: 'course_name',
},
{
  title: 'Ad/Tu Company',
  dataIndex: ['adm_company_ref', 'company_name'],
  key: 'company_name',
}, {
  title: 'Ad/Tu CGST',
  dataIndex: 'at_tax_cgst',
  key: 'company_name',
}, {
  title: 'Ad/Tu SGST',
  dataIndex: 'at_tax_sgst',
  key: 'company_name',
}, {
  title: 'Ad/Tu IGST',
  dataIndex: 'at_tax_igst',
  key: 'company_name',
}, {
  title: 'Book Company',
  dataIndex: ['book_company_ref', 'company_name'],
  key: 'company_name',
}, {
  title: 'CGST',
  dataIndex: 'bk_tax_cgst',
  key: 'company_name',
}, {
  title: 'SGST',
  dataIndex: 'bk_tax_sgst',
  key: 'company_name',
}, {
  title: 'IGST',
  dataIndex: 'bk_tax_igst',
  key: 'company_name',
}, {
  title: 'Updated Date',
  dataIndex: ['updated_at'],
  key: 'updated_at',
  render: (key: any) => moment(key).format("MM/DD/YYYY")
}, {
  title: 'Modified By',
  dataIndex: [''],
  key: 'company_name',
}, {
  title: 'Status',
  dataIndex: [''],
  key: 'company_name',
}]


export const BatchTypeRelationshipSchema = [
  {
    title: 'Center Name',
    dataIndex: ['center_id', 'center_name'],
    key: 'center_name',
  },
  {
    title: 'Course Name',
    dataIndex: ['course_id', 'course_name'],
    key: 'course_name'
  },
  {
    title: 'Batch Type Name',
    dataIndex: ['batch_type_id', 'batch_type_name'],
    key: 'batch_type_name'
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  }
]


export const ExamTypeRelationshipSchema = [
  {
    title: 'Center Name',
    dataIndex: ['center_id', 'center_name'],
    key: 'center_name',
  },
  {
    title: 'Course Name',
    dataIndex: ['course_id', 'course_name'],
    key: 'course_name'
  },
  {
    title: 'Batch Type Name',
    dataIndex: ['batch_type_id', 'batch_type_name'],
    key: 'batch_type_name'
  },
  {
    title: 'Exam Name',
    dataIndex: ['exam_id', 'exam_name'],
    key: 'exam_name'
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  }
]


export const StreamRelationshipSchema = [
  {
    title: 'Center Name',
    dataIndex: ['center_id', 'center_name'],
    key: 'center_name',
  },
  {
    title: 'Course Name',
    dataIndex: ['course_id', 'course_name'],
    key: 'course_name'
  },
  {
    title: 'Batch Type Name',
    dataIndex: ['batch_type_id', 'batch_type_name'],
    key: 'batch_type_name'
  },
  {
    title: 'Exam Name',
    dataIndex: ['exam_id', 'exam_name'],
    key: 'exam_name'
  },
  {
    title: 'Stream Name',
    dataIndex: ['stream_id', 'stream_name'],
    key: 'stream_name'
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  }
]


export const VenueRelationshipSchema = [
  {
    title: 'Center Name',
    dataIndex: ['center_id', 'center_name'],
    key: 'center_name',
  },
  {
    title: 'Venue Name',
    dataIndex: ['venue_id', 'venue_name'],
    key: 'venue_name'
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  }
]


export const BatchSchema = [
  {
    title: 'Session',
    dataIndex: ['session_id', 'session'],
    key: 'session',
  },
  {
    title: 'Batch Code',
    dataIndex: 'batch_code_id',
    key: 'batch_code',
    render: (a: any) => {
      return a?.description + " (" + a?.batch_code + ")"
    }
  },
  {
    title: "Batch Time",
    key: "batch_time",
    dataIndex: 'batch_time_id',
    sorter: true,
    render: (a: any) => {
      return a?.batch_time_from + "-" + a?.batch_time_to
    }
  },
  {
    title: 'Center Name',
    dataIndex: ['center_id', 'center_name'],
    key: 'center_name',
  },
  {
    title: 'Course Name',
    dataIndex: ['course_id', 'course_name'],
    key: 'course_name'
  },
  {
    title: 'Batch Type Name',
    dataIndex: ['batch_type_id', 'batch_type_name'],
    key: 'batch_type_name'
  },
  {
    title: 'Exam Name',
    dataIndex: ['exam_id', 'exam_name'],
    key: 'exam_name'
  },
  {
    title: 'Stream Name',
    dataIndex: ['stream_id', 'stream_name'],
    key: 'stream_name'
  },
  {
    title: 'Venue Name',
    dataIndex: ['venue_id', 'venue_name'],
    key: 'venue_name'
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  }
]


export const FeeSchema = [
  {
    title: 'Session',
    dataIndex: ['session_id', 'session'],
    key: 'session',
  },
  {
    title: 'Center Name',
    dataIndex: ['center_id', 'center_name'],
    key: 'center_name',
  },
  {
    title: 'Course Name',
    dataIndex: ['course_id', 'course_name'],
    key: 'course_name'
  },
  {
    title: 'Batch Type Name',
    dataIndex: ['batch_type_id', 'batch_type_name'],
    key: 'batch_type_name'
  },
  {
    title: 'Exam Name',
    dataIndex: ['exam_id', 'exam_name'],
    key: 'exam_name'
  },
  {
    title: 'Stream Name',
    dataIndex: ['stream_id', 'stream_name'],
    key: 'stream_name'
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  }
]



export const BankDepositSchema = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
},
{
  title: 'Age',
  dataIndex: 'age',
  key: 'age',
},
{
  title: 'Address',
  dataIndex: 'address',
  key: 'address',
}]


export const ICardStaticDataSchema = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
},
{
  title: 'Age',
  dataIndex: 'age',
  key: 'age',
},
{
  title: 'Address',
  dataIndex: 'address',
  key: 'address',
}]