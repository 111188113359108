import "../../assets/styles/resetpassword.less";
import LoginLayout from "../../shared/layouts/login.layout";

function ResetPasswordPage() {
  // const [searchParams] = useSearchParams();
  // const token = searchParams.get("token") || "";

  return <LoginLayout>{/* <ResetPasswordForm token={token} /> */}</LoginLayout>;
}
export default ResetPasswordPage;

// interface Props {
//   token: string;
// }
// export const ResetPasswordForm: React.FC<Props> = ({ token = "" }: Props) => {
//   const { t, i18n } = useTranslation();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   i18n.loadNamespaces("errors");
//   const [resetDone, setResetDone] = useState(false);
//   const [validToken, setValidToken] = useState(token !== "" ? true : false);

//   const [errorMessage, setErrorMessage] = useState<string>(
//     t("LOGIN.RESETPASSWORDLINKINVALID")
//   );

//   useEffect(() => {
//     dispatch(verifiyResetPassword({ token: token })).then((val: any) => {
//       if (val?.payload?.status === 200) {
//         if (val?.payload?.data?.response?.length > 0) {
//           if (val?.payload?.data?.response[0]?.active === false) {
//             setErrorMessage(
//               t(val?.payload?.data?.response[0].code, {
//                 ns: "errors",
//                 defaultValue: val?.payload?.data?.response[0].message,
//               })
//             );
//             setValidToken(false);
//           }
//         }
//       }
//     });
//   }, [dispatch, token, t]);

//   const onFinish = (values: any) => {
//     const data = {
//       token: token,
//       body: { password: values?.password },
//     };
//     dispatch(requestResetPassword(data)).then((val: any) => {
//       if (val?.payload?.status === 200) {
//         setResetDone(true);
//       }
//     });
//   };
//   return (
//     <>
//       {!validToken && (
//         <Row>
//           <Col
//             xs={{ span: 22, offset: 1 }}
//             md={{ span: 20, offset: 2 }}
//             lg={{ span: 18, offset: 3 }}
//             xl={{ span: 12, offset: 6 }}
//           >
//             <h2 className="text-center font-size-28 weight-300">
//               {errorMessage}
//             </h2>
//             {/* <h5 className="text-center">{errorMessage}</h5> */}
//             <p className="text-center mt-5">
//               <Button
//                 className="btn-cancel"
//                 htmlType="submit"
//                 size="large"
//                 style={{ width: "150px" }}
//                 onClick={() => navigate(ROUTE_LOGIN)}
//               >
//                 {t("COMMON.ACTIONS.GOTOLOGIN")}
//               </Button>
//             </p>
//           </Col>
//         </Row>
//       )}
//       {validToken && !resetDone && (
//         <Row>
//           <Col
//             xs={{ span: 22, offset: 1 }}
//             md={{ span: 20, offset: 2 }}
//             lg={{ span: 18, offset: 3 }}
//             xl={{ span: 12, offset: 6 }}
//           >
//             <Form
//               name="normal_login"
//               className="login-form"
//               layout="vertical"
//               onFinish={onFinish}
//             >
//               <h2 className="font-size-28 weight-300">
//                 {t("LOGIN.RESETPASSWORD")}{" "}
//               </h2>
//               <Form.Item
//                 name="password"
//                 label={t("COMMON.LABELS.PASSWORD")}
//                 rules={[
//                   {
//                     required: true,
//                     message: t("LOGIN.MESSAGES.PASSWORDREQUIRED"),
//                   },
//                   {
//                     validator: (_, value) =>
//                       passwordValidator(value)
//                         ? Promise.resolve()
//                         : Promise.reject(
//                             new Error(t("LOGIN.MESSAGES.PASSWORDVALIDATION"))
//                           ),
//                   },
//                 ]}
//                 hasFeedback
//               >
//                 <Input.Password
//                   size="large"
//                   placeholder={t("COMMON.PLACEHOLDERS.PASSWORD")}
//                 />
//               </Form.Item>
//               <Form.Item
//                 name="confirm-password"
//                 label={t("LOGIN.CONFIRMPASSWORD")}
//                 rules={[
//                   {
//                     required: true,
//                     message: t("LOGIN.MESSAGES.CONFIRMPASSWORDREQUIRED"),
//                   },
//                   ({ getFieldValue }) => ({
//                     validator(_, value) {
//                       if (!value || getFieldValue("password") === value) {
//                         return Promise.resolve();
//                       }
//                       return Promise.reject(
//                         new Error(t("LOGIN.RESETPASSWORDPASSWORDSDONTMATCH"))
//                       );
//                     },
//                   }),
//                 ]}
//                 hasFeedback
//               >
//                 <Input.Password
//                   size="large"
//                   placeholder={t("COMMON.PLACEHOLDERS.CONFIRM_PASSWORD")}
//                 />
//               </Form.Item>
//               <Form.Item>
//                 <div style={{ textAlign: "right" }}>
//                   <Space>
//                     <Button
//                       className="btn-cancel"
//                       htmlType="submit"
//                       size="large"
//                       onClick={() => navigate(ROUTE_LOGIN)}
//                     >
//                       {t("COMMON.ACTIONS.CANCEL")}
//                     </Button>
//                     <Button
//                       type="primary"
//                       size="large"
//                       className={" mt20"}
//                       htmlType="submit"
//                     >
//                       {t("COMMON.ACTIONS.SAVE")}
//                     </Button>
//                   </Space>
//                 </div>
//               </Form.Item>
//             </Form>
//           </Col>
//         </Row>
//       )}
//       {validToken && resetDone && (
//         <Row>
//           <Col
//             xs={{ span: 22, offset: 1 }}
//             md={{ span: 20, offset: 2 }}
//             lg={{ span: 18, offset: 3 }}
//             xl={{ span: 12, offset: 6 }}
//           >
//             <h2 className="text-center font-size-28 weight-300">
//               {t("LOGIN.RESETPASSWORDSUCCESS")}
//             </h2>
//             <h5 className="text-center">
//               {t("LOGIN.RESETPASSWORDSUCCESSMESSAGE")}
//             </h5>
//             <p className="text-center mt-5">
//               <Button
//                 className="btn-cancel"
//                 htmlType="submit"
//                 size="large"
//                 style={{ width: "150px" }}
//                 onClick={() => navigate(ROUTE_LOGIN)}
//               >
//                 {t("COMMON.ACTIONS.GOTOLOGIN")}
//               </Button>
//             </p>
//           </Col>
//         </Row>
//       )}
//     </>
//   );
// };
