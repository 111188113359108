import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";

const LoginLayout: React.FC<any> = ({ loginForm }: any) => {
  return (
    <>
      <Layout className="h-100 bg-login">
        <Layout className="">
          <Content>{loginForm}</Content>
        </Layout>
        {/* <Footer className="">
          <FooterComponent></FooterComponent>
        </Footer> */}
      </Layout>
    </>
  );
};
export default LoginLayout;
